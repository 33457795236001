import {
  GET_ENTRADAS_LIST_SUCCESS,
  GET_ENTRADAS_LIST_FAIL,
  GET_ENTRADAS_SUCCESS,
  GET_ENTRADAS_FAIL,
  GET_ENTRADAS_SEARCH_SUCCESS,
  GET_ENTRADAS_SEARCH_FAIL,
  GET_PRODUCTOS_LIST_SUCCESS,
  GET_PRODUCTOS_LIST_FAIL,
  GET_PRODUCTO_SUCCESS,
  GET_PRODUCTO_FAIL,
  GET_REPORTE_MENSUAL_SUCCESS,
  GET_REPORTE_MENSUAL_FAIL,
  GET_SALIDAS_LIST_SUCCESS,
  GET_SALIDAS_LIST_FAIL,
  GET_SALIDAS_SUCCESS,
  GET_SALIDAS_FAIL,
  GET_SALIDAS_SEARCH_SUCCESS,
  GET_SALIDAS_SEARCH_FAIL,
  GET_REFERENCIA_LIST_SUCCESS,
  GET_REFERENCIA_LIST_FAIL,
  GET_REFERENCIA_SUCCESS,
  GET_REFERENCIA_FAIL,
  GET_REFERENCIA_SEARCH_SUCCESS,
  GET_REFERENCIA_SEARCH_FAIL,
  GET_PRODUCCION_LIST_SUCCESS,
  GET_PRODUCCION_LIST_FAIL,
  GET_PRODUCCION_SUCCESS,
  GET_PRODUCCION_FAIL,
  GET_PRODUCCION_SEARCH_SUCCESS,
  GET_PRODUCCION_SEARCH_FAIL,
  GET_INVENTARIO_LIST_SUCCESS,
  GET_INVENTARIO_LIST_FAIL,
  GET_INVENTARIO_SUCCESS,
  GET_INVENTARIO_FAIL,
  GET_INVENTARIO_SEARCH_SUCCESS,
  GET_INVENTARIO_SEARCH_FAIL,
  GET_INVENTARIO_FULL_LIST_SUCCESS,
  GET_INVENTARIO_FULL_LIST_FAIL,
  GET_INVENTARIO_FULL_SUCCESS,
  GET_INVENTARIO_FULL_FAIL,
  GET_INVENTARIO_FULL_SEARCH_SUCCESS,
  GET_INVENTARIO_FULL_SEARCH_FAIL,
  GET_SALDO_PLANTA_LIST_SUCCESS,
  GET_SALDO_PLANTA_LIST_FAIL,
  GET_SALDO_PLANTA_SUCCESS,
  GET_SALDO_PLANTA_FAIL,
  GET_SALDO_PLANTA_SEARCH_SUCCESS,
  GET_SALDO_PLANTA_SEARCH_FAIL,
  GET_SALIDA_INSUMOS_LIST_SUCCESS,
  GET_SALIDA_INSUMOS_LIST_FAIL,
  GET_SALIDA_INSUMOS_SUCCESS,
  GET_SALIDA_INSUMOS_FAIL,
  GET_SALIDA_INSUMOS_SEARCH_SUCCESS,
  GET_SALIDA_INSUMOS_SEARCH_FAIL,
  GET_SALIDA_INSUMOS_REPORTE_MENSUAL_SUCCESS,
  GET_SALIDA_INSUMOS_REPORTE_MENSUAL_FAIL,
  GET_COMBINACION_MEZCLAS_LIST_SUCCESS,
  GET_COMBINACION_MEZCLAS_LIST_FAIL,
  GET_COMBINACION_MEZCLAS_SUCCESS,
  GET_COMBINACION_MEZCLAS_FAIL,
  GET_COMBINACION_MEZCLAS_SEARCH_SUCCESS,
  GET_COMBINACION_MEZCLAS_SEARCH_FAIL,
} from "./types";
import axios from "axios";

// Entradas
export const get_entrada_list =
  (planta = null) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    let url = `${process.env.REACT_APP_API_URL}/entradas/list/`;
    let params = new URLSearchParams();

    if (planta !== null && planta !== "") {
      params.append("planta", planta);
    }

    url += `?${params.toString()}`;

    try {
      const res = await axios.get(url, config);
      if (res.status === 200) {
        dispatch({
          type: GET_ENTRADAS_LIST_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_ENTRADAS_LIST_FAIL,
        });
      }
    } catch {
      dispatch({
        type: GET_ENTRADAS_LIST_FAIL,
      });
    }
  };

export const get_entrada = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/entradas/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_ENTRADAS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_ENTRADAS_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ENTRADAS_FAIL,
    });
  }
};

export const get_entrada_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/entradas/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_ENTRADAS_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_ENTRADAS_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_ENTRADAS_LIST_FAIL,
    });
  }
};

export const search_entrada = (search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/entradas/search/${search_term}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_ENTRADAS_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_ENTRADAS_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_ENTRADAS_SEARCH_FAIL,
    });
  }
};

export const search_entrada_page = (p,search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/entradas/search/${search_term}?p=${p}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_ENTRADAS_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_ENTRADAS_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_ENTRADAS_SEARCH_FAIL,
    });
  }
};

export const get_productos_list = () => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/productos/list/`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PRODUCTOS_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PRODUCTOS_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_PRODUCTOS_LIST_FAIL,
    });
  }
};

export const get_productos_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/productos/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PRODUCTOS_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PRODUCTOS_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_PRODUCTOS_LIST_FAIL,
    });
  }
};

export const get_producto = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/productos/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_PRODUCTO_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PRODUCTO_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PRODUCTO_FAIL,
    });
  }
};

export const get_reporte_mensual = (planta, mes, año) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/reporte-mensual/?planta=${planta}&mes=${mes}&año=${año}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_REPORTE_MENSUAL_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_REPORTE_MENSUAL_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_REPORTE_MENSUAL_FAIL,
    });
  }
};

// Salidas

export const get_salida_list =
  (tipo_salida = null) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    let url = `${process.env.REACT_APP_API_URL}/salidas/list/`;
    let params = new URLSearchParams();

    if (tipo_salida !== null && tipo_salida !== "") {
      params.append("tipo_salida", tipo_salida);
    }

    url += `?${params.toString()}`;
    try {
      const res = await axios.get(url, config);

      if (res.status === 200) {
        dispatch({
          type: GET_SALIDAS_LIST_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_SALIDAS_LIST_FAIL,
        });
      }
    } catch {
      dispatch({
        type: GET_SALIDAS_LIST_FAIL,
      });
    }
  };

export const get_salida = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/salidas/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_SALIDAS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALIDAS_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SALIDAS_FAIL,
    });
  }
};

export const get_salida_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/salidas/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_SALIDAS_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALIDAS_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALIDAS_LIST_FAIL,
    });
  }
};

export const search_salida = (search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/salidas/search/${search_term}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_SALIDAS_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALIDAS_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALIDAS_SEARCH_FAIL,
    });
  }
};

export const search_salida_page = (p,search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/salidas/search/${search_term}?p=${p}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_SALIDAS_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALIDAS_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALIDAS_SEARCH_FAIL,
    });
  }
};

// Salida insumos

export const get_salida_insumos_list =
  (planta = null) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    let url = `${process.env.REACT_APP_API_URL}/salidas-insumos/list/`;
    let params = new URLSearchParams();
    if (planta !== null && planta !== "") {
      params.append("planta", planta);
    }

    url += `?${params.toString()}`;

    try {
      const res = await axios.get(url, config);
      if (res.status === 200) {
        dispatch({
          type: GET_SALIDA_INSUMOS_LIST_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_SALIDA_INSUMOS_LIST_FAIL,
        });
      }
    } catch {
      dispatch({
        type: GET_SALIDA_INSUMOS_LIST_FAIL,
      });
    }
  };

export const get_salida_insumos_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/salidas-insumos/list/?p=${p}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_SALIDA_INSUMOS_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALIDA_INSUMOS_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALIDA_INSUMOS_LIST_FAIL,
    });
  }
};

export const get_salida_insumos = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/salidas-insumos/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_SALIDA_INSUMOS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALIDA_INSUMOS_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALIDA_INSUMOS_FAIL,
    });
  }
};

export const get_salida_insumos_search = (search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/salidas-insumos/search/${search_term}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_SALIDA_INSUMOS_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALIDA_INSUMOS_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALIDA_INSUMOS_SEARCH_FAIL,
    });
  }
};

export const get_salida_insumos_search_page = (p,search_term) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/salidas-insumos/search/${search_term}?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_SALIDA_INSUMOS_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALIDA_INSUMOS_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALIDA_INSUMOS_SEARCH_FAIL,
    });
  }
};

export const get_salida_insumos_reporte_mensual =
  (planta, mes, año) => async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/reporte-mensual-salida-insumo/?planta=${planta}&mes=${mes}&año=${año}`,
        config
      );
      if (res.status === 200) {
        dispatch({
          type: GET_SALIDA_INSUMOS_REPORTE_MENSUAL_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_SALIDA_INSUMOS_REPORTE_MENSUAL_FAIL,
        });
      }
    } catch {
      dispatch({
        type: GET_SALIDA_INSUMOS_REPORTE_MENSUAL_FAIL,
      });
    }
  };

// referencia
export const get_referencia_list =
  (tipo = null,tipo_produccion=null) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    let url = `${process.env.REACT_APP_API_URL}/referencias/list/`;
    let params = new URLSearchParams();

    if (tipo !== null && tipo !== "") {
      params.append("tipo", tipo);
    }
    if (tipo_produccion !== null && tipo_produccion !== "") {
      params.append("tipo_produccion", tipo_produccion);
    }
    url += `?${params.toString()}`;
    try {
      const res = await axios.get(url, config);

      if (res.status === 200) {
        dispatch({
          type: GET_REFERENCIA_LIST_SUCCESS,
          payload: res.data,
          meta: tipo,
        });
      } else {
        dispatch({
          type: GET_REFERENCIA_LIST_FAIL,
        });
      }
    } catch {
      dispatch({
        type: GET_REFERENCIA_LIST_FAIL,
      });
    }
  };

export const get_referencia_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/referencias/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_REFERENCIA_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_REFERENCIA_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_REFERENCIA_LIST_FAIL,
    });
  }
};

export const get_referencia = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/referencia/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_REFERENCIA_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_REFERENCIA_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_REFERENCIA_FAIL,
    });
  }
};

export const get_referencia_search = (search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/referencias/search/${search}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_REFERENCIA_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_REFERENCIA_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_REFERENCIA_SEARCH_FAIL,
    });
  }
};

export const get_referencia_search_page = (p,search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/referencias/search/${search}?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_REFERENCIA_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_REFERENCIA_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_REFERENCIA_SEARCH_FAIL,
    });
  }
};

// Produccion

export const get_produccion_list = (turno = null) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  let url = `${process.env.REACT_APP_API_URL}/produccion/list/`;
  let params = new URLSearchParams();
  if (turno !== null && turno !== "") {
    params.append("turno", turno);
  }

  url += `?${params.toString()}`;
  
  try {
    const res = await axios.get(
      url,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PRODUCCION_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PRODUCCION_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_PRODUCCION_LIST_FAIL,
    });
  }
};

export const get_produccion_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/produccion/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PRODUCCION_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PRODUCCION_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_PRODUCCION_LIST_FAIL,
    });
  }
};

export const get_produccion = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/produccion/${id}/`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PRODUCCION_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PRODUCCION_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_PRODUCCION_FAIL,
    });
  }
};

export const get_produccion_search = (search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/produccion/search/${search}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PRODUCCION_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PRODUCCION_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_PRODUCCION_SEARCH_FAIL,
    });
  }
};

export const get_produccion_search_page = (p,search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/produccion/search/${search}?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PRODUCCION_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PRODUCCION_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_PRODUCCION_SEARCH_FAIL,
    });
  }
};
// Inventario

export const get_inventario_list = (full=null,producto=null) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}/inventario/list/`;
  let params = new URLSearchParams();
  if (producto !== null && producto !== "") {
    params.append("producto", producto);
  }
  if (full !== null && full !== "") {
    params.append("full", full);
  }
  url += `?${params.toString()}`;
  try {
    const res = await axios.get(
      url,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_INVENTARIO_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_INVENTARIO_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_INVENTARIO_LIST_FAIL,
    });
  }
};

export const get_inventario_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/inventario/list/?p=${p}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_INVENTARIO_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_INVENTARIO_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_INVENTARIO_LIST_FAIL,
    });
  }
};

export const get_inventario = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/inventario/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_INVENTARIO_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_INVENTARIO_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_INVENTARIO_FAIL,
    });
  }
};

export const get_inventario_search = (search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/inventario/search/${search}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_INVENTARIO_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_INVENTARIO_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_INVENTARIO_SEARCH_FAIL,
    });
  }
};

export const get_inventario_search_page = (p,search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/inventario/search/${search}?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_INVENTARIO_SEARCH_SUCCESS,
        payload: res.data,
      });
    }
    else {
      dispatch({
        type: GET_INVENTARIO_SEARCH_FAIL,
      });
    }
  }
  catch {
    dispatch({
      type: GET_INVENTARIO_SEARCH_FAIL,
    });
  }
}

export const get_inventario_full_list = (fecha=null) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}/inventario-full/list/`;
  let params = new URLSearchParams();

  if (fecha !== null && fecha !== "") {
    params.append("fecha", fecha);
  }
  url += `?${params.toString()}`;
  try {
    const res = await axios.get(
      url,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_INVENTARIO_FULL_LIST_SUCCESS,
        payload: res.data,
      });
    }
    else {
      dispatch({
        type: GET_INVENTARIO_FULL_LIST_FAIL,
      });
    }
  }
  catch {
    dispatch({
      type: GET_INVENTARIO_FULL_LIST_FAIL,
    });
  }
}

export const get_inventario_full_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/inventario-full/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_INVENTARIO_FULL_LIST_SUCCESS,
        payload: res.data,
      });
    }
    else {
      dispatch({
        type: GET_INVENTARIO_FULL_LIST_FAIL,
      });
    }
  }
  catch {
    dispatch({
      type: GET_INVENTARIO_FULL_LIST_FAIL,
    });
  }
}

export const get_inventario_full = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/inventario-full/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_INVENTARIO_FULL_SUCCESS,
        payload: res.data,
      });
    }
    else {
      dispatch({
        type: GET_INVENTARIO_FULL_FAIL,
      });
    }
  }
  catch {
    dispatch({
      type: GET_INVENTARIO_FULL_FAIL,
    });
  }
}

export const get_inventario_full_search = (search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/inventario-full/search/${search}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_INVENTARIO_FULL_SEARCH_SUCCESS,
        payload: res.data,
      });
    }
    else {
      dispatch({
        type: GET_INVENTARIO_FULL_SEARCH_FAIL,
      });
    }
  }
  catch {
    dispatch({
      type: GET_INVENTARIO_FULL_SEARCH_FAIL,
    });
  }
}

export const get_inventario_full_search_page = (p,search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };
  try {
    
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/inventario-full/search/${search}?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_INVENTARIO_FULL_SEARCH_SUCCESS,
        payload: res.data,
      });
    }
    else {
      dispatch({
        type: GET_INVENTARIO_FULL_SEARCH_FAIL,
      });
    }
  }
  catch {
    dispatch({
      type: GET_INVENTARIO_FULL_SEARCH_FAIL,
    });
  }
}


// Saldo Planta

export const get_saldo_planta_list = () => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/saldo-planta/list/`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_SALDO_PLANTA_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALDO_PLANTA_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALDO_PLANTA_LIST_FAIL,
    });
  }
};

export const get_saldo_planta_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/saldo-planta/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_SALDO_PLANTA_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALDO_PLANTA_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALDO_PLANTA_LIST_FAIL,
    });
  }
};

export const get_saldo_planta = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/saldo-planta/${id}/`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_SALDO_PLANTA_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALDO_PLANTA_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALDO_PLANTA_FAIL,
    });
  }
};

export const get_saldo_planta_search = (search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/saldo-planta/search/${search}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_SALDO_PLANTA_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SALDO_PLANTA_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_SALDO_PLANTA_SEARCH_FAIL,
    });
  }
};


// Combinacion de Mezclas

export const get_combinacion_mezclas_list = (tipoaceite=null) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}/combinacion-mezclas/list/`;
  let params = new URLSearchParams();
  if (tipoaceite !== null && tipoaceite !== "") {
    params.append("tipoaceite", tipoaceite);
  }
  url += `?${params.toString()}`;

  try {
    const res = await axios.get(
      url,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_COMBINACION_MEZCLAS_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_COMBINACION_MEZCLAS_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_COMBINACION_MEZCLAS_LIST_FAIL,
    });
  }
}

export const get_combinacion_mezclas_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/combinacion-mezclas/list/?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_COMBINACION_MEZCLAS_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_COMBINACION_MEZCLAS_LIST_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_COMBINACION_MEZCLAS_LIST_FAIL,
    });
  }
}

export const get_combinacion_mezclas = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/combinacion-mezclas/${id}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_COMBINACION_MEZCLAS_SUCCESS,
        payload: res.data,
      });
    }
    else {
      dispatch({
        type: GET_COMBINACION_MEZCLAS_FAIL,
      });
    }
  }
  catch {
    dispatch({
      type: GET_COMBINACION_MEZCLAS_FAIL,
    });
  }
}

export const get_combinacion_mezclas_search = (search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/combinacion-mezclas/search/${search}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_COMBINACION_MEZCLAS_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_COMBINACION_MEZCLAS_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_COMBINACION_MEZCLAS_SEARCH_FAIL,
    });
  }
}

export const get_combinacion_mezclas_search_page = (p,search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },

  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/combinacion-mezclas/search/${search}?p=${p}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_COMBINACION_MEZCLAS_SEARCH_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_COMBINACION_MEZCLAS_SEARCH_FAIL,
      });
    }
  } catch {
    dispatch({
      type: GET_COMBINACION_MEZCLAS_SEARCH_FAIL,
    });
  }
}
