import React from "react";

function SaldoPlantaCardHorizontal({ data, index }) {
  return (
    <tr className="border-b">
      <td className="p-2 dark:text-white">{index + 1}</td>
      <td className="p-2">{new Date(data.created_at).toLocaleDateString()}</td>
      <td className="p-2 dark:text-white">{data.referencia.codigo}</td>
      <td className="p-2 dark:text-white">{data.referencia.etiqueta}</td>
      <td className="p-2 dark:text-white">{data.saldo}</td>
      <td className="p-2 dark:text-white">{data.lote}</td>
      <td className="p-2 dark:text-white">{data.fecha_vencimiento}</td>
      <td className="p-2 dark:text-white">
        <div className="flex text-xs">
          <span className="font-bold">Caja: </span>
          <span className="flex ml-1">{data.referencia.caja}</span>
        </div>
        <div className="flex text-xs">
          <span className="font-bold">Envase: </span>
          <span className="flex ml-1">{data.referencia.envase}</span>
        </div>
        <div className="flex text-xs">
          <span className="font-bold">Aceite: </span>
          <span className="flex ml-1">{data.referencia.tipo_aceite}</span>
        </div>
        <div className="flex text-xs">
          <span className="font-bold">Peso: </span>
          <span className="flex ml-1">{data.referencia.peso}</span>
        </div>
        <div className="flex text-xs">
          <span className="font-bold">Tapa: </span>
          <span className="flex ml-1">{data.referencia.tapa}</span>
        </div>
      </td>
    </tr>
  );
}

export default SaldoPlantaCardHorizontal;
