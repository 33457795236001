import React from "react";
import DashboardLayout from "../hocs/layout/DashboardLayout";
import { useSelector } from "react-redux";
import P03 from "../components/P03/Dashboard"; // Envasado Cucuta


// Define plant information with unique identifiers for each role
const plantAccess = {
  "COORDINADOR_PLANTA_ENVASADO": [{ component: P03, name: "Planta Envasado", location: "Cúcuta" }],
  "COORDINADOR_PRODUCCION_ENVASADO": [{ component: P03, name: "Planta Envasado", location: "Cúcuta" }],

};

// Roles with access to all plants
const allAccessRoles = ["GERENTE_GENERAL", "REVISOR_FISCAL"];

function Dashboard() {
  const user = useSelector((state) => state.auth.user);

  const hasAllAccess = user && allAccessRoles.includes(user.role);

  const plantsToDisplay = hasAllAccess
    ? Object.values(plantAccess).flat() 
    : plantAccess[user?.role] || [];
  const uniquePlants = Array.from(
    new Map(plantsToDisplay.map((plant) => [plant.name, plant])).values()
  );

  return (
    <DashboardLayout>
      {uniquePlants.length > 0 ? (
        uniquePlants.map(({ component: PlantComponent, name, location }, index) => (
          <div key={index}>
            <h3 className="text-2xl font-medium leading-6 text-gray-900 dark:text-white">
              {name} - {location}
            </h3>
            <PlantComponent />
          </div>
        ))
      ) : (
        <p>No tiene acceso a ninguna planta</p>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
