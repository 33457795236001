import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import Toast from "../../components/Alert";
import { get_modificaciones } from "../../redux/actions/modificaciones/modificaciones";
import axios from "axios";
import { CircleLoader } from "react-spinners";
function ModificacionesEdit({ get_modificaciones, modificacion }) {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const uid = params.uid; 

  const [formData, setFormData] = useState({
    fecha: "",
    tipo: "",
    producto: "",
    cantidad: "",
    descripcion: "",
    firma: "", // Campo para almacenar la firma
    status: false,
  });
  const { fecha, tipo, producto, cantidad, descripcion } = formData;
  const signatureCanvas = useRef(null);
  const [imageData, setImageData] = useState(""); // Estado para la firma

  useEffect(() => {

    get_modificaciones(uid);
  }, [uid, get_modificaciones]);

  useEffect(() => {
    if (modificacion) {
      setFormData({
        fecha: modificacion.fecha || "",
        tipo: modificacion.tipo || "",
        producto: modificacion.producto || "",
        cantidad: modificacion.cantidad || "",
        descripcion: modificacion.descripcion || "",
        firma: modificacion.firma || "",
        status: modificacion.status || false,
        descripcion_status: modificacion.descripcion_status || "",
      });
      // Si la firma ya existe, dibujarla en el canvas
      if (modificacion.firma && signatureCanvas.current) {
        signatureCanvas.current.fromDataURL(modificacion.firma);
      }
    }
  }, [modificacion]);
  console.log(modificacion);
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateForm = () => {
    // Ejemplo básico de validación
    if (
      !formData.fecha ||
      !formData.tipo ||
      !formData.producto ||
      !formData.cantidad
    ) {
      return false;
    }
    return true;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(key, value);
    }

    try {
      // Realizar la petición para actualizar la modificación
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/modificaciones/update/${uid}/`,
        formDataToSend,
        config
      );
      if (res.status === 200) {
        Toast({
          tipo: "success",
          mensaje: "Modificacion actualizada correctamente!",
        });
        navigate("/modificaciones");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      Toast({
        tipo: "error",
        mensaje: "Ocurrió un error al actualizar la modificación.",
      });
    }
  };

  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Editar Modificación.
        </h1>

        <form onSubmit={onSubmit} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Información de la modificación.
          </div>

          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="fecha" className="label">
                <span className="label-text dark:text-white">Fecha</span>
              </label>
              <input
                type="date"
                name="fecha"
                value={fecha}
                onChange={onChange}
                required
                disabled={user && user.role === "COORDINADOR_INVENTARIOS"}
                max={new Date().toISOString().split("T")[0]}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="tipo" className="label">
                <span className="label-text dark:text-white">Tipo</span>
              </label>
              <select
                name="tipo"
                value={tipo}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Selecciona un tipo</option>
                <option value="ENTRADAS">ENTRADAS</option>
                <option value="SALIDAS">SALIDAS</option>
                <option value="PRODUCCION">PRODUCCION</option>
                <option value="MEZCLA">MEZCLA</option>
              </select>
            </div>

            <div className="form-control">
              <label htmlFor="producto" className="label">
                <span className="label-text dark:text-white">Producto</span>
              </label>
              <input
                type="text"
                name="producto"
                value={producto}
                onChange={onChange}
                required
                disabled={user && user.role === "COORDINADOR_INVENTARIOS"}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="cantidad" className="label">
                <span className="label-text dark:text-white">Cantidad</span>
              </label>
              <input
                type="number"
                name="cantidad"
                value={cantidad}
                onChange={onChange}
                required
                disabled={user && user.role === "COORDINADOR_INVENTARIOS"}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="descripcion" className="label">
                <span className="label-text dark:text-white">Descripción</span>
              </label>
              <textarea
                name="descripcion"
                value={descripcion}
                onChange={onChange}
                required
                disabled={user && user.role === "COORDINADOR_INVENTARIOS"}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            
            {user && user.role === "COORDINADOR_INVENTARIOS" && (
              <>
                <div className="form-control">
                  <label htmlFor="status" className="label">
                    <span className="label-text dark:text-white">Estado</span>
                  </label>
                  <select
                    name="status"
                    value={formData.status}
                    onChange={onChange}
                    required
                    className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  >
                    <option value={true}>Realizado</option>
                    <option value={false}>Pendiente</option>
                  </select>
                </div>
                <div className="form-control">
                  <label htmlFor="descripcion_status" className="label">
                    <span className="label-text dark:text-white">
                      Descripción de la modificación
                    </span>
                  </label>
                  <textarea
                    name="descripcion_status"
                    value={formData.descripcion_status}
                    onChange={onChange}
                    required
                    className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                </div>
              </>
            )}
          </div>

          <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white dark:bg-red-700">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white dark:bg-red-700"
              >
                Actualizar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  modificacion: state.modificaciones.modificacion,
});

export default connect(mapStateToProps, { get_modificaciones })(
  ModificacionesEdit
);
