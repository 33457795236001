import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import axios from "axios";
import Toast from "../../components/Alert";
import { useEffect, useState } from "react";
import { get_proveedores } from "../../redux/actions/proveedores/proveedores";
import { connect } from "react-redux";
import { CircleLoader } from "react-spinners";

function ProveedoresForm({ get_proveedores, proveedor }) {
  const params = useParams();
  const id = params.uid; 
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nombre: "",
    nit: "",
    direccion: "",
    encargado: "",
    telefono: "",
    email: "",
    observaciones: "",
  });

  const { nombre, nit, direccion, encargado, telefono, email, observaciones } =
    formData;
  useEffect(() => {
    if (id) {
      get_proveedores(id); 
    }
  }, [id, get_proveedores]);

  useEffect(() => {
    if (proveedor) {
      setFormData({
        nombre: proveedor.nombre || "",
        nit: proveedor.nit || "",
        direccion: proveedor.direccion || "",
        encargado: proveedor.encargado || "",
        telefono: proveedor.telefono || "",
        email: proveedor.email || "",
        observaciones: proveedor.observaciones || "",
      });
    }
  }, [proveedor]);

  const validateForm = () => {
    return nombre && nit && direccion && encargado && telefono && email;
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Toast({
        tipo: "error",
        mensaje: "Complete todos los campos obligatorios.",
      });
      return;
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(key, value);
    }

    try {
      let res;
      if (id) {
        res = await axios.put(
          `${process.env.REACT_APP_API_URL}/proveedores/update/${id}/`,
          formDataToSend,
          config
        );
        if (res.status === 200) {
          Toast({
            tipo: "success",
            mensaje: "Proveedor actualizado correctamente!",
          });
          navigate("/proveedores");
        }
      } else {
        res = await axios.post(
          `${process.env.REACT_APP_API_URL}/proveedores/create`,
          formDataToSend,
          config
        );
        if (res.status === 201) {
          Toast({
            tipo: "success",
            mensaje: "Proveedor registrado correctamente!",
          });
          navigate("/proveedores");
        }
      }
    } catch (error) {
      Toast({
        tipo: "error",
        mensaje: "Error al registrar o actualizar el proveedor!",
      });
    }
  };

  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          {id ? "Editar Proveedor" : "Registro de Proveedor"}
        </h1>
        <form onSubmit={onSubmit} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Información básica.
          </div>
          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="nombre" className="label">
                <span className="label-text dark:text-white">Razón Social</span>
              </label>
              <input
                type="text"
                name="nombre"
                value={nombre}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="form-control">
              <label htmlFor="nit" className="label">
                <span className="label-text dark:text-white">NIT</span>
              </label>
              <input
                type="text"
                name="nit"
                value={nit}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="direccion" className="label">
                <span className="label-text dark:text-white">Dirección</span>
              </label>
              <input
                type="text"
                name="direccion"
                value={direccion}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="encargado" className="label">
                <span className="label-text dark:text-white">Encargado</span>
              </label>
              <input
                type="text"
                name="encargado"
                value={encargado}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="telefono" className="label">
                <span className="label-text dark:text-white">Teléfono</span>
              </label>
              <input
                type="text"
                name="telefono"
                value={telefono}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="email" className="label">
                <span className="label-text dark:text-white">Email</span>
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="observaciones" className="label">
                <span className="label-text dark:text-white">
                  Observaciones
                </span>
              </label>
              <textarea
                name="observaciones"
                value={observaciones}
                onChange={onChange}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
          </div>
          <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3 mt-5">
            <button
              type="submit"
              className="btn border bg-[#DC2917] text-white dark:bg-red-700"
            >
              {id ? "Actualizar" : "Enviar"}
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  proveedor: state.proveedores.proveedor,
});

export default connect(mapStateToProps, { get_proveedores })(ProveedoresForm);
