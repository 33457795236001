import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ProveedoresCardHorizontal({ data, index }) {
  const user = useSelector((state) => state.auth.user);
  return (
    <tr className="bg-white border-b dark:bg-gray-800">
      <td className="p-2 dark:text-white"></td>
      <td className="p-2 dark:text-white">{data.nombre}</td>
      <td className="p-2 dark:text-white">{data.nit}</td>
      <td className="p-2 dark:text-white">{data.direccion}</td>
      <td className="p-2 dark:text-white">{data.encargado}</td>
      <td className="p-2 dark:text-white">{data.telefono}</td>

      <td className="p-2 dark:text-white">
        <div className="flex items-center gap-2">
          {
            user && user.role === "COORDINADOR_PLANTA_ENVASADO" ||
            user && user.role === "COORDINADOR_PLANTA_SOPLADO" ? (
              <div className="flex tooltip" data-tip="Editar">
            <Link
              to={`/proveedor/editar/${data.id}`}
              className="p-1 text-white bg-yellow-500 rounded-full"
            >
              <IconPencil />
            </Link>
          </div> ) : null
          }
          <div className="flex tooltip" data-tip="Ver Pdf">
            <Link
              to={`/proveedor/ver/${data.id}`}
              className="p-1 text-white bg-blue-500 rounded-full"
            >
              <IconEye />
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default ProveedoresCardHorizontal;
