import { Link, useParams } from "react-router-dom";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { get_referencia_search,get_referencia_search_page } from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ReferenciasListSearch from "../../../components/P03/referencias/ReferenciasListSearch";
function SearchReferenciasP03({ get_referencia_search, get_referencia_search_page,referencia,count }) {
  const params = useParams();
  const term_params = params.term;

  useEffect(() => {
    get_referencia_search(term_params);
  }, [term_params]);


  const [term, setTerm] = useState("");
  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => (window.location.href = "/referencias-p03/" + term), 0.2);
    setTerm("");
  };
  return (
    <DashboardLayout>
      <div className="border-b border-gray-200 py-5 sm:px-6">
        <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-2xl font-medium leading-6 text-gray-900">
              Lista de referencias Planta 03.
            </h3>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
            <div className="flex justify-between gap-4">
              <Link to="/referencias-p03/form">
                <button className="bg-[#DC2917] text-white px-4 py-1.5 rounded hover:bg-red-800">
                  Agregar Referencia
                </button>
              </Link>
            </div>
            <div className="">
              <div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
                <form onSubmit={(e) => onSubmit(e)} className="w-full">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <button
                      type="submit"
                      className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </button>
                    <input
                      id="search"
                      name="search"
                      required
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Buscar"
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>

      {referencia && referencia && (
        <ReferenciasListSearch 
        referencia={referencia} 
        get_referencia_search_page={get_referencia_search_page}
        count={count}
        search_term={term_params}
        />
      )}
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  referencia: state.referencia.referencia_filtrada,
  count : state.referencia.count
});

export default connect(mapStateToProps, { get_referencia_search, get_referencia_search_page })(
  SearchReferenciasP03
);
