import SetPagination from "../../pagination/SetPagination";
import MezclasCardHorizontal from "./MezclasCardHorizontal";

function MezclasList({ mezclas, get_combinacion_mezclas_list_page, count,refreshData , user}) {
  return (
    <div className="overflow-hidden">
      <div className="relative overflow-x-auto">
      <table
          className="table text-gray-500 w-full"
          style={{ tableLayout: "auto" }}
        >
          <thead className="text-xs text-gray-700 uppercase ">
            <tr>
              <th scope="col" className="p-2 dark:text-white ">
                
              </th>
              <th scope="col" className="p-2 dark:text-white ">
                Mezcla
              </th>
              <th scope="col" className="p-2 dark:text-white ">
                % Palma
              </th>
              <th scope="col" className="p-2 dark:text-white ">
                % Soya
              </th>
              <th scope="col" className="p-2 dark:text-white ">
                Cantidad
              </th>
              <th scope="col" className="p-2 dark:text-white ">
                Tanque
              </th>
              <th scope="col" className="p-2 dark:text-white ">
                Aprobada
              </th>
              <th scope="col" className="p-2 dark:text-white ">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {mezclas && mezclas.length > 0 ? (
              mezclas.map((mezcla, index) => (
                <MezclasCardHorizontal data={mezcla} key={index} index={index} refreshData={refreshData} user={user}/>
              ))
            ) : (
              <tr>
                <td colSpan="9" className=" p-4">
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_combinacion_mezclas_list_page}
        list={mezclas}
        count={count}
        type={"large"}
      />
    </div>
  );
}

export default MezclasList;
