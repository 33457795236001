import {
  GET_AVERIAS_LIST_SUCCESS,
  GET_AVERIAS_LIST_FAIL,
  GET_AVERIAS_SUCCESS,
  GET_AVERIAS_FAIL,
} from "../actions/averias/types"

const initialState = {
  averias: "",
  averias_list: "",
  count: null,
  next: null,
  previous: null,
};

export default function averias(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_AVERIAS_LIST_SUCCESS:
      return {
        ...state,
        averias_list: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_AVERIAS_LIST_FAIL:
      return {
        ...state,
        averias_list: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_AVERIAS_SUCCESS:
      return {
        ...state,
        averias: payload.averias,
      };
    case GET_AVERIAS_FAIL:
      return {
        ...state,
        averias: null,
      };
    default:
      return state;
  }
}

