import SetPagination from "../../pagination/SetPagination";
import SalidasCardHorizontal from "./SalidasCardHorizontal";

function SalidasList({
  salidas,
  get_salida_list,
  get_salida_list_page,
  count,
  refreshData,
  user
}) {
  return (
    <div className="overflow-hidden">
      <div className="relative overflow-x-auto ">
        <table className="table text-gray-500 dark:text-white">
          <thead className="text-xs text-gray-700 dark:text-white uppercase">
            <tr>
              <th scope="col" className="p-2"></th>
              <th scope="col" className="p-2">
                Fecha
              </th>
              <th scope="col" className="p-2">
                Remision
              </th>
              <th scope="col" className="p-2">
                Cliente
              </th>
              <th scope="col" className="p-2">
                Conductor/Placa
              </th>
              <th scope="col" className="p-2">
                Referencias
              </th>
              <th scope="col" className="p-2">
                Lote/Salida
              </th>
              <th scope="col" className="p-2">
                Observaciones
              </th>
              <th scope="col" className="p-2">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>

            {salidas && salidas.length > 0 ? (
              salidas.map((salida, index) => (
                <SalidasCardHorizontal
                  data={salida}
                  key={index}
                  index={index}
                  refreshData={refreshData}
                  user={user}
                />
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center p-4">
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_salida_list_page}
        list={salidas}
        count={count}
        type={"large"}
      />
    </div>
  );
}
export default SalidasList;
