// src/firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBNjaoe2xy9A21C0dGsVQ78MlSuWz30iWU",
  authDomain: "empanorte-35f83.firebaseapp.com",
  projectId: "empanorte-35f83",
  storageBucket: "empanorte-35f83.appspot.com",
  messagingSenderId: "684509096725",
  appId: "1:684509096725:web:5cad121c2cbfdc9aebd71c",
  measurementId: "G-YTH0V14ZM5"
};



const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);