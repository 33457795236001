import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { get_combinacion_mezclas } from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import Toast from "../../../components/Alert";
import { CircleLoader } from "react-spinners";
import axios from "axios";

function MezclaEditP03({ get_combinacion_mezclas, mezcla }) {
  const params = useParams();
  const id = params.uid;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    mezcla: "",
    lote_palma: "",
    porcentaje_palma: "",
    fecha_vencimiento_palma: "",
    lote_soya: "",
    porcentaje_soya: "",
    fecha_vencimiento_soya: "",
    cantidad: "",
    lote_mezcla: "",
    fecha_vencimiento_mezcla: "",
    tanque : "",
  });

  useEffect(() => {
    get_combinacion_mezclas(id);
  }, [id, get_combinacion_mezclas]);

  useEffect(() => {
    if (mezcla) {
      setFormData({
        mezcla: mezcla.mezcla || "",
        lote_palma: mezcla.lote_palma || "",
        porcentaje_palma: mezcla.porcentaje_palma || "",
        fecha_vencimiento_palma: mezcla.fecha_vencimiento_palma || "",
        lote_soya: mezcla.lote_soya || "",
        porcentaje_soya: mezcla.porcentaje_soya || "",
        fecha_vencimiento_soya: mezcla.fecha_vencimiento_soya || "",
        cantidad: mezcla.cantidad || "",
        lote_mezcla: mezcla.lote_mezcla || "",
        fecha_vencimiento_mezcla: mezcla.fecha_vencimiento_mezcla || "",
        tanque: mezcla.tanque || "",
      });
    }
  }, [mezcla]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "porcentaje_palma" || name === "porcentaje_soya") {
      const totalPorcentaje =
        parseFloat(name === "porcentaje_palma" ? value : formData.porcentaje_palma || 0) +
        parseFloat(name === "porcentaje_soya" ? value : formData.porcentaje_soya || 0);

      if (totalPorcentaje > 100) {
        Toast({
          tipo: "error",
          mensaje: "La suma de los porcentajes no debe superar el 100%.",
        });
      }
    }
  };

  const validateForm = () => {
    const totalPorcentaje = parseFloat(formData.porcentaje_palma) + parseFloat(formData.porcentaje_soya);

    if (totalPorcentaje !== 100) {
      Toast({
        tipo: "error",
        mensaje: "La suma de los porcentajes de Palma y Soya debe ser 100%.",
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/combinacion-mezclas/update/${id}`,
        formData,
        config
      );

      if (res.status === 200) {
        Toast({
          tipo: "success",
          mensaje: "Mezcla actualizada con éxito.",
        });
        navigate("/combinaciones-mezclas-p03");
      }
    } catch (error) {
      Toast({
        tipo: "error",
        mensaje: "Error al actualizar la mezcla.",
      });
    } finally {
      setLoading(false);
    }
  };
  const today = new Date().toISOString().split("T")[0];

  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Editar Mezcla.
        </h1>

        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Información de la Mezcla.
          </div>

          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="mezcla" className="label">
                <span className="label-text dark:text-white">
                  Tipo de Mezcla
                </span>
              </label>
              <select
                name="mezcla"
                value={formData.mezcla}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Seleccione una opción</option>
                <option value="Mezcla 70/30 MC">Mezcla 70/30 MC</option>
                <option value="Mezcla 60/40 MB">Mezcla 60/40 MB</option>
                <option value="Mezcla 95/05 MA">Mezcla 95/05 MA</option>
              </select>
            </div>
          
            <div className="form-control">
              <label htmlFor="lote_palma" className="label">
                <span className="label-text dark:text-white">Lote de Palma</span>
              </label>
              <input
                type="text"
                name="lote_palma"
                value={formData.lote_palma}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="form-control">
              <label htmlFor="porcentaje_palma" className="label">
                <span className="label-text dark:text-white">
                  Porcentaje de Palma
                </span>
              </label>
              <input
                type="number"
                name="porcentaje_palma"
                value={formData.porcentaje_palma}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="form-control">
              <label htmlFor="fecha_vencimiento_palma" className="label">
                <span className="label-text dark:text-white">
                  Fecha de Vencimiento de Palma
                </span>
              </label>
              <input
                type="date"
                name="fecha_vencimiento_palma"
                value={formData.fecha_vencimiento_palma}
                onChange={(e) => onChange(e)}
                required
                min={today}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="form-control">
              <label htmlFor="lote_soya" className="label">
                <span className="label-text dark:text-white">Lote de Soya</span>
              </label>
              <input
                type="text"
                name="lote_soya"
                value={formData.lote_soya}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="form-control">
              <label htmlFor="porcentaje_soya" className="label">
                <span className="label-text dark:text-white">
                  Porcentaje de Soya
                </span>
              </label>
              <input
                type="number"
                name="porcentaje_soya"
                value={formData.porcentaje_soya}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="form-control">
              <label htmlFor="fecha_vencimiento_soya" className="label">
                <span className="label-text dark:text-white">
                  Fecha de Vencimiento de Soya
                </span>
              </label>
              <input
                type="date"
                name="fecha_vencimiento_soya"
                value={formData.fecha_vencimiento_soya}
                onChange={(e) => onChange(e)}
                required
                min={today}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="form-control">
              <label htmlFor="cantidad" className="label">
                <span className="label-text dark:text-white">Cantidad</span>
              </label>
              <input
                type="number"
                name="cantidad"
                value={formData.cantidad}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="form-control">
              <label htmlFor="lote_mezcla" className="label">
                <span className="label-text dark:text-white">Lote de Mezcla</span>
              </label>
              <input
                type="text"
                name="lote_mezcla"
                value={formData.lote_mezcla}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="form-control">
              <label htmlFor="fecha_vencimiento_mezcla" className="label">
                <span className="label-text dark:text-white">
                  Fecha de Vencimiento de Mezcla
                </span>
              </label>
              <input
                type="date"
                name="fecha_vencimiento_mezcla"
                value={formData.fecha_vencimiento_mezcla}
                onChange={(e) => onChange(e)}
                required
                min={today}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="form-control">
              <label htmlFor="tanque" className="label">
                <span className="label-text dark:text-white">Tanque</span>
              </label>
              <select
                name="tanque"
                value={formData.tanque}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Seleccione una opción</option>
                <option value="Tanque 1">Tanque 1</option>
                <option value="Tanque 2">Tanque 2</option>
                <option value="Tanque 3">Tanque 3</option>
                <option value="Tanque 4">Tanque 4</option>
                <option value="Tanque 5">Tanque 5</option>
                <option value="Tanque 6">Tanque 6</option>
                <option value="Tanque 7">Tanque 7</option>
                <option value="Tanque 8">Tanque 8</option>
                <option value="Tanque 9">Tanque 9</option>
                <option value="Tanque 10">Tanque 10</option>
              </select>
            </div>
          </div>

          <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white dark:bg-red-700">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white dark:bg-red-700"
              >
                Guardar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  mezcla: state.combinacionMezclas.combinacion_mezcla,
});

export default connect(mapStateToProps, { get_combinacion_mezclas })(
  MezclaEditP03
);