import SetPagination from "../pagination/SetPagination";
import ProveedoresCardHorizontal from "./ProveedoresCardHorizontal";

function ProveedoresList({
  proveedores,
  get_proveedores_list,
  get_proveedores_list_page,
  count,
  refreshData,
}) {
  return (
    <div className="overflow-hidden bg-white dark:bg-gray-800">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white"></th>
              <th scope="col" className="p-2 dark:text-white">Razon Social</th>
              <th scope="col" className="p-2 dark:text-white">Nit</th>
              <th scope="col" className="p-2 dark:text-white">Direccion</th>
              <th scope="col" className="p-2 dark:text-white">Encargado</th>
              <th scope="col" className="p-2 dark:text-white">Telefono</th>
              <th scope="col" className="p-2 dark:text-white">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              proveedores && proveedores.length > 0 ? (
                proveedores.map((proveedor, index) => (
                  <ProveedoresCardHorizontal
                    data={proveedor}
                    key={index}
                    index={index}
                    refreshData={refreshData}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center p-4">
                    No hay registros disponibles.
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_proveedores_list_page}
        list={get_proveedores_list}
        count={count}
        type={"medium"}
      />
    </div>
  );
}

export default ProveedoresList;
