import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import {
  get_salida,
  get_referencia_list,
  get_productos_list,
} from "../../../redux/actions/P03/p03";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";
import axios from "axios";
import Toast from "../../../components/Alert";
import { IconEye, IconTrash } from "@tabler/icons-react";

function SalidasP03Edit({
  get_salida,
  get_referencia_list,
  salida,
  refer,
  get_productos_list,
  productos,
}) {
  const params = useParams();
  const id = params.uid;
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const navigate = useNavigate();
  const dropdownRef = useRef([]);
  const [productoQuery, setProductoQuery] = useState("");
  const [productoDropdownOpen, setProductoDropdownOpen] = useState([]);

  const productoDropdownRefs = useRef([]);
  const [producto, setProducto] = useState({
    producto: "",
    cantidad: "",
  });

  const [formData, setFormData] = useState({
    fecha: "",
    turno: "",
    remision: "",
    cliente: "",
    conductor: "",
    placa: "",
    lote: "",
    observaciones: "",
    tipo_salida: "",
    salidareferencia_set: [],
    averiasproducto_set: [],
  });

  useEffect(() => {
    if (salida) {
      // Agrupar referencias por ID
      const groupedRefs = salida.salidareferencia_set.reduce((acc, ref) => {
        const refId = ref.referencia.id;
        if (!acc[refId]) {
          acc[refId] = {
            referencia: refId,
            codigo: ref.referencia.codigo,
            etiqueta: ref.referencia.etiqueta,
            lotes: [],
          };
        }
        acc[refId].lotes.push({
          cantidad: ref.cantidad,
          lote: ref.lote,
          fecha_vencimiento: ref.fecha_vencimiento,
        });
        return acc;
      }, {});

      setFormData({
        ...formData,
        fecha: salida.fecha,
        turno: salida.turno,
        remision: salida.remision,
        cliente: salida.cliente,
        conductor: salida.conductor,
        placa: salida.placa,
        lote: salida.lote,
        observaciones: salida.observaciones,
        tipo_salida: salida.tipo_salida,
        salidareferencia_set: Object.values(groupedRefs),
        averiasproducto_set: salida.averiasproducto_set || [],
      });
    }
  }, [salida]);
  useEffect(() => {
    get_salida(id);
    if (formData.tipo_salida) {
      get_referencia_list("min", formData.tipo_salida);
    }
    get_productos_list();
  }, [
    id,
    get_salida,
    get_referencia_list,
    get_productos_list,
    formData.tipo_salida,
  ]);

  const [referencia, setReferencia] = useState({
    referencia: "",
    cantidad: "",
  });

  const handleDropdownToggle = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = !newDropdownOpen[index];
      return newDropdownOpen;
    });
  };

  const handleDropdownClose = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = false;
      return newDropdownOpen;
    });
  };

  const addReferencia = () => {
    setFormData({
      ...formData,
      salidareferencia_set: [
        ...formData.salidareferencia_set,
        {
          referencia: referencia.referencia,
          cantidad: referencia.cantidad,
          lote: referencia.lote,
          fecha_vencimiento: referencia.fecha_vencimiento,
        },
      ],
    });
    setReferencia({
      referencia: "",
      cantidad: "",
      lote: "",
      fecha_vencimiento: "",
    });
    setQuery("");
  };

  const handleReferenciaSelect = (index, refItem, lote, fechaVencimiento) => {
    const newRefs = [...formData.salidareferencia_set];
    newRefs[index] = {
      referencia: refItem.id,
      lote: lote,
      fecha_vencimiento: fechaVencimiento,
      cantidad: refItem.cantidad,
    };
    setFormData({ ...formData, salidareferencia_set: newRefs });
    handleDropdownClose(index);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/salidas/update/${id}/`,
        formData,
        config
      );

      if (res.status === 200) {
        Toast({
          tipo: "success",
          mensaje: "¡Salida actualizada correctamente!",
        });
        navigate("/salidas-p03");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error.includes(
          "Una o más referencias ya están asociadas con esta salida envasado."
        )
      ) {
        Toast({
          tipo: "error",
          mensaje:
            "Una o más referencias ya están asociadas con esta salida envasado.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRef.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          handleDropdownClose(index);
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutsideProducto = (event) => {
      // Iteramos sobre cada referencia de dropdown de producto
      productoDropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target)) {
          // Cierra el dropdown solo si se hace clic fuera
          handleProductoDropdownClose(index);
        }
      });
    };

    // Añadimos el listener para detectar clics fuera
    document.addEventListener("mousedown", handleClickOutsideProducto);
    return () => {
      // Limpiamos el listener al desmontar
      document.removeEventListener("mousedown", handleClickOutsideProducto);
    };
  }, []);

  const selectedReferencias = formData.salidareferencia_set.map(
    (ref) => ref.referencia
  );

  const filteredReferencias = refer
    ? query === ""
      ? refer.filter((refItem) => !selectedReferencias.includes(refItem.id))
      : refer.filter(
          (refItem) =>
            !selectedReferencias.includes(refItem.id) &&
            `${refItem.codigo} - ${refItem.etiqueta}`
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
    : [];

  const addProducto = () => {
    setFormData({
      ...formData,
      averiasproducto_set: [...formData.averiasproducto_set, producto],
    });
    setProducto({
      producto: "",
      cantidad: "",
    });
    setProductoQuery("");
  };
  const handleProductoDropdownToggle = (index) => {
    setProductoDropdownOpen((prevState) => {
      const newProductoDropdownOpen = [...prevState];
      newProductoDropdownOpen[index] = !newProductoDropdownOpen[index];
      return newProductoDropdownOpen;
    });
  };
  const handleProductoSelect = (index, prodItem) => {
    const newProds = [...formData.averiasproducto_set];
    newProds[index].producto = prodItem.id;
    setFormData({ ...formData, averiasproducto_set: newProds });
    handleProductoDropdownClose(index);
  };

  const handleProductoDropdownClose = (index) => {
    setProductoDropdownOpen((prevState) => {
      const newProductoDropdownOpen = [...prevState];
      newProductoDropdownOpen[index] = false;
      return newProductoDropdownOpen;
    });
  };

  const handleClickOutsideProducto = (event) => {
    productoDropdownRefs.current.forEach((ref, index) => {
      if (ref && !ref.contains(event.target)) {
        handleProductoDropdownClose(index);
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideProducto);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideProducto);
    };
  }, []);

  if (!refer) {
    return <CircleLoader loading={true} size={50} color="#DC2917" />;
  }
  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Editar Salida.
        </h1>

        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Información de la Salida.
          </div>

          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label
                htmlFor="fecha"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text">Fecha</span>
              </label>
              <input
                type="date"
                name="fecha"
                value={formData.fecha}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                disabled
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="turno"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text">Turno</span>
              </label>
              <select
                name="turno"
                value={formData.turno}
                onChange={onChange}
                required
                className="select select-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="A">Día</option>
                <option value="B">Noche</option>
              </select>
            </div>

            <div className="form-control">
              <label
                htmlFor="remision"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text">Remisión</span>
              </label>
              <input
                type="text"
                name="remision"
                value={formData.remision}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="cliente"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text">Cliente</span>
              </label>
              <input
                type="text"
                name="cliente"
                value={formData.cliente}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="conductor"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text">Conductor</span>
              </label>
              <input
                type="text"
                name="conductor"
                value={formData.conductor}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="placa"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text">Placa</span>
              </label>
              <input
                type="text"
                name="placa"
                value={formData.placa}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="lote"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text">Lote</span>
              </label>
              <input
                type="text"
                name="lote"
                value={formData.lote}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label
                htmlFor="observaciones"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text">Observaciones</span>
              </label>
              <textarea
                name="observaciones"
                value={formData.observaciones}
                onChange={onChange}
                className="textarea textarea-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              ></textarea>
            </div>

            <div className="form-control">
              <label
                htmlFor="tipo_salida"
                className="label text-gray-900 dark:text-gray-300"
              >
                <span className="label-text">Tipo de Salida</span>
              </label>
              <select
                name="tipo_salida"
                value={formData.tipo_salida}
                onChange={onChange}
                required
                className="select select-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="FK">FK</option>
                <option value="EQ">EQ</option>
              </select>
            </div>
          </div>

          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Referencias
          </div>

          <div className="border border-gray-300 dark:border-gray-600 rounded-lg p-3">
            {formData.salidareferencia_set.map((ref, index) => {
              const referenciaSeleccionada = refer.find(
                (r) => r.id === ref.referencia
              );

              return (
                <div key={index} className="mb-3">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    <div className="form-control">
                      <label
                        htmlFor="referencia"
                        className="label text-gray-900 dark:text-gray-300"
                      >
                        <span className="label-text">Referencia</span>
                      </label>
                      <div
                        className="relative"
                        ref={(el) => (dropdownRef.current[index] = el)}
                      >
                        <button
                          type="button"
                          onClick={() => handleDropdownToggle(index)}
                          className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <span className="mr-2">
                            {ref.referencia && refer
                              ? `${referenciaSeleccionada?.codigo} - ${referenciaSeleccionada?.etiqueta}`
                              : "Seleccionar Referencia"}
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 ml-2 -mr-1"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6.293 9.293a1 1 011.414 0L10 11.586l2.293-2.293a1 1 111.414 1.414l-3 3a1 1 01-1.414 0l-3-3a1 1 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        {dropdownOpen[index] && (
                          <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                            <input
                              id="search-input"
                              className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                              type="text"
                              placeholder="Buscar referencias"
                              value={query}
                              onChange={(e) => setQuery(e.target.value)}
                            />
                            <div className="max-h-60 overflow-y-auto">
                              {filteredReferencias.length === 0 ? (
                                <div className="px-4 py-2 text-gray-700 dark:text-gray-300">
                                  No se encontraron referencias
                                </div>
                              ) : (
                                filteredReferencias
                                  .slice(0, 10)
                                  .map((refItem) => (
                                    <div
                                      key={refItem.id}
                                      onClick={() =>
                                        handleReferenciaSelect(index, refItem)
                                      }
                                      className="cursor-pointer px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800"
                                    >
                                      {`${refItem.codigo} - ${refItem.etiqueta}`}
                                    </div>
                                  ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {referenciaSeleccionada?.saldo_planta &&
                    referenciaSeleccionada.saldo_planta.length > 0 ? (
                      referenciaSeleccionada.saldo_planta.map(
                        (saldo, saldoIndex) => (
                          <div key={saldoIndex} className="form-control">
                            <label
                              htmlFor="lote"
                              className="label text-gray-900 dark:text-gray-300"
                            >
                              <span className="label-text">
                                Lote: {saldo.lote}, Vencimiento:{" "}
                                {saldo.fecha_vencimiento}
                              </span>
                            </label>
                            {/* Asegúrate de que el campo "lotes" existe antes de acceder */}
                            <input
                              type="number"
                              name={`cantidad-${index}-${saldoIndex}`}
                              value={
                                formData.salidareferencia_set[index]?.lotes?.[
                                  saldoIndex
                                ]?.cantidad === 0
                                  ? ""
                                  : formData.salidareferencia_set[index]
                                      ?.lotes?.[saldoIndex]?.cantidad
                              }
                              onChange={(e) => {
                                const newRefs = [
                                  ...formData.salidareferencia_set,
                                ];

                                // Si no existe "lotes" para esta referencia, inicialízalo como un array vacío
                                if (!newRefs[index].lotes) {
                                  newRefs[index].lotes = [];
                                }

                                const cantidadIngresada =
                                  e.target.value === ""
                                    ? ""
                                    : parseInt(e.target.value, 10);
                                const saldoDisponible = saldo.saldo || 0;

                                if (e.target.value === "") {
                                  // Permitir eliminar el valor dejando el campo vacío
                                  newRefs[index].lotes[saldoIndex] = {
                                    ...newRefs[index].lotes[saldoIndex],
                                    cantidad: "",
                                  };
                                } else if (
                                  cantidadIngresada <= saldoDisponible &&
                                  cantidadIngresada >= 0
                                ) {
                                  // Actualizar la cantidad si es válida y no excede el saldo disponible
                                  newRefs[index].lotes[saldoIndex] = {
                                    ...newRefs[index].lotes[saldoIndex],
                                    cantidad: cantidadIngresada,
                                    lote: saldo.lote,
                                    fecha_vencimiento: saldo.fecha_vencimiento,
                                  };
                                } else if (
                                  cantidadIngresada > saldoDisponible
                                ) {
                                  // Mostrar error si la cantidad excede el saldo disponible
                                  Toast({
                                    tipo: "error",
                                    mensaje: `La cantidad ingresada excede el saldo disponible (${saldoDisponible}).`,
                                  });
                                }

                                // Actualizar el estado con los nuevos datos
                                setFormData({
                                  ...formData,
                                  salidareferencia_set: newRefs,
                                });
                              }}
                              max={saldo.saldo || 0}
                              required
                              className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            />

                            <span className="text-sm text-gray-600 dark:text-gray-300">
                              Saldo disponible: {saldo.saldo}
                            </span>
                          </div>
                        )
                      )
                    ) : (
                      <div className="text-gray-500 dark:text-gray-300">
                        No hay saldos disponibles para esta referencia.
                      </div>
                    )}

                    <div className="flex flex-col items-center">
                      <label
                        htmlFor="eliminar"
                        className="label text-gray-900 dark:text-gray-300"
                      >
                        <span className="label-text">Eliminar</span>
                      </label>
                      <button
                        type="button"
                        onClick={() => {
                          const newRefs = [...formData.salidareferencia_set];
                          newRefs.splice(index, 1);
                          setFormData({
                            ...formData,
                            salidareferencia_set: newRefs,
                          });
                        }}
                        className="p-2 bg-red-500 text-white rounded-md"
                      >
                        <IconTrash size={20} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            {refer && refer.length > formData.salidareferencia_set.length && (
              <button
                type="button"
                onClick={addReferencia}
                className="btn border bg-blue-500 text-white dark:bg-blue-600"
              >
                Añadir Referencia
              </button>
            )}
          </div>
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Averias de Salida
          </div>

          <div className="border border-gray-300 dark:border-gray-600 rounded-lg p-3">
            {formData.averiasproducto_set.map((prod, index) => {
              // Obtenemos el producto seleccionado
              const productoSeleccionado = productos.find(
                (p) => p.id === prod.producto
              );

              const filteredProductos = productos
                ? productos.filter(
                    (producto) =>
                      !formData.averiasproducto_set
                        .map((prod) => prod.producto)
                        .includes(producto.id) &&
                      producto.inventario_data?.inventario > 0
                  )
                : [];

              return (
                <div key={index} className="mb-3">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                    <div className="form-control">
                      <label htmlFor="producto" className="label">
                        <span className="label-text dark:text-white">
                          Producto
                        </span>
                      </label>
                      <div
                        className="relative"
                        ref={(el) => (productoDropdownRefs.current[index] = el)}
                      >
                        <div className="inline-flex items-center justify-between w-full">
                          <button
                            type="button"
                            onClick={() => handleProductoDropdownToggle(index)}
                            className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            <span className="mr-2">
                              {prod.producto
                                ? `${productoSeleccionado?.nombre} - ${productoSeleccionado?.referencia}`
                                : "Seleccionar Producto"}
                            </span>
                            <IconEye className="ml-2" size={20} />
                          </button>
                        </div>
                        {productoDropdownOpen[index] && (
                          <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                            <input
                              id="search-input"
                              className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
                              type="text"
                              placeholder="Buscar productos"
                              value={productoQuery}
                              onChange={(e) => setProductoQuery(e.target.value)}
                            />
                            <div className="max-h-60 overflow-y-auto">
                              {filteredProductos.length === 0 ? (
                                <div className="px-4 py-2 text-gray-700 dark:text-white">
                                  No se encontraron productos
                                </div>
                              ) : (
                                filteredProductos.map((prodItem) => (
                                  <div
                                    key={prodItem.id}
                                    onClick={() =>
                                      handleProductoSelect(index, prodItem)
                                    }
                                    className="cursor-pointer px-4 py-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600"
                                  >
                                    {prodItem.nombre} - {prodItem.referencia} -{" "}
                                    {prodItem.descripcion}
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-control">
                      <label htmlFor="cantidad" className="label">
                        <span className="label-text dark:text-white">
                          Cantidad
                        </span>
                      </label>
                      <input
                        type="number"
                        name="cantidad"
                        value={prod.cantidad === 0 ? "" : prod.cantidad}
                        onChange={(e) => {
                          const newProds = [...formData.averiasproducto_set];
                          const cantidadIngresada =
                            e.target.value === ""
                              ? ""
                              : parseInt(e.target.value, 10);
                          const inventarioDisponible =
                            productoSeleccionado?.inventario_data?.inventario ||
                            0;

                          if (e.target.value === "") {
                            // Permitir eliminar el valor dejando el campo vacío
                            newProds[index].cantidad = "";
                          } else if (
                            cantidadIngresada <= inventarioDisponible &&
                            cantidadIngresada >= 0
                          ) {
                            // Actualizar la cantidad si es válida y no excede el inventario
                            newProds[index].cantidad = cantidadIngresada;
                          } else if (cantidadIngresada > inventarioDisponible) {
                            // Mostrar error si la cantidad excede el inventario disponible
                            Toast({
                              tipo: "error",
                              mensaje: `La cantidad ingresada excede el inventario disponible (${inventarioDisponible}).`,
                            });
                          }

                          setFormData({
                            ...formData,
                            averiasproducto_set: newProds,
                          });
                        }}
                        max={
                          productoSeleccionado?.inventario_data?.inventario || 0
                        }
                        required
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />

                      {productoSeleccionado?.inventario_data && (
                        <span className="text-sm text-gray-600 dark:text-gray-300">
                          Cantidad en inventario:{" "}
                          {productoSeleccionado.inventario_data.inventario}
                        </span>
                      )}
                    </div>

                    <div className="flex items-center justify-start space-x-2">
                      <div className="flex flex-col items-center">
                        <label htmlFor="eliminar" className="label">
                          <span className="label-text dark:text-white">
                            Eliminar
                          </span>
                        </label>
                        <button
                          type="button"
                          onClick={() => {
                            const newProds = [...formData.averiasproducto_set];
                            newProds.splice(index, 1);
                            setFormData({
                              ...formData,
                              averiasproducto_set: newProds,
                            });
                          }}
                          className="p-2 bg-red-500 text-white rounded-md"
                        >
                          <IconTrash size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <button
              type="button"
              onClick={addProducto}
              className="btn border bg-blue-500 text-white dark:bg-blue-700"
            >
              Añadir Averia
            </button>
          </div>
          <div className="flex justify-center col-span-1 md:col-span-2 lg:grid-cols-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white"
              >
                Guardar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  salida: state.salidas.salida,
  refer: state.referencia.referencias,
  productos: state.productos.productos || [],
});

export default connect(mapStateToProps, {
  get_salida,
  get_referencia_list,
  get_productos_list,
})(SalidasP03Edit);
