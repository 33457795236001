import React from 'react';
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import logo from '../../../../assets/img/logo-removebg-preview.png';

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 40,
    paddingHorizontal: 40,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "space-between",
    padding: 5,
    backgroundColor: "#f2f2f2",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
  },
  title: {
    fontSize: 22,
    fontFamily: "Oswald",
    width: "75%",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Oswald',
    marginBottom: 10,
  },
  text: {
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    marginBottom: 10,
  },
  image: {
    width: 40,
    height: 40,
    textAlign: "center",
  },
  detailsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
    borderTopWidth: 0,
    marginBottom: 10,
  },
  detailsCol: {
    width: "25%",
    fontSize: 10,
    fontFamily: "Times-Bold",
    textAlign: "center",
    padding: 10,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  sectionTitle: {
    fontSize: 18,
    fontFamily: 'Oswald',
    marginBottom: 10,
  },
  sectionContent: {
    fontSize: 14,
    fontFamily: 'Times-Roman',
    marginBottom: 5,
  },
  wrapper: {
    flexDirection: 'column',
    border: '1px solid #676767',
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  contentRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  contentKey: {
    fontSize: 14,
    fontFamily: 'Oswald',
  },
  contentValue: {
    fontSize: 14,
    fontFamily: 'Times-Roman',
  },
  fullWidthRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    width: '100%',
  },
  fullWidthCell: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#676767',
    padding: 5,
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#676767',
    borderRadius: 5,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#676767',
    backgroundColor: '#e0e0e0',
  },
  tableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#676767',
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  tableCell: {
    margin: 5,
    fontSize: 12,
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 35,
    right: 35,
    textAlign: 'center',
    color: 'grey',
  },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const formatDateTime = (date) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return new Intl.DateTimeFormat('es-ES', options).format(date);
};

function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  return (
    <>
      {data ? (
        <Document title={`Reporte de Producción - ${data.fecha}`}>
          <Page style={styles.body} wrap>
          <View style={styles.headerRow}>
            <Text style={styles.title}>Reporte de Produccion</Text>
            <Image style={styles.image} src={logo} />
          </View>
          <View style={styles.detailsRow}>
            <Text style={styles.detailsCol}>Código: </Text>
            <Text style={styles.detailsCol}>Versión: </Text>
            <Text style={styles.detailsCol}>Fecha: </Text>
            <Text style={styles.detailsCol}>
              Página: 1 de 1
            </Text>
          </View>
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Detalles de la Producción</Text>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Fecha:</Text>
                <Text style={styles.contentValue}>{data.fecha}</Text>
              </View>
              <View style={styles.contentRow}>
                <Text style={styles.contentKey}>Turno:</Text>
                <Text style={styles.contentValue}>
                  {data.turno === 'A' ? 'Diurno' : 'Nocturno'}
                </Text>
              </View>

            </View>
            
            {/* Referencias table */}
            <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Referencias</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Código</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Etiqueta</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Cantidad</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Lote</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>
                      Fecha Vencimiento
                    </Text>
                  </View>
                </View>
                {data.produccionreferencia_set &&
                  data.produccionreferencia_set.map((ref, index) => (
                    <View style={styles.tableRow} key={index}>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {ref.referencia.codigo}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {ref.referencia.etiqueta}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{ref.cantidad}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{ref.lote}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {ref.fecha_vencimiento}
                        </Text>
                      </View>
                    </View>
                  ))}
              </View>
            </View>
            
              {/* Lotes de Aceite table */}
              <View style={styles.wrapper}>
              <Text style={styles.sectionTitle}>Lotes de Aceite</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Tipo de Aceite</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Lote</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>
                      Fecha de Vencimiento
                    </Text>
                  </View>
                </View>
                {data.lotes_aceite.map((lote, index) => (
                  <View style={styles.tableRow} key={index}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{lote.tipo}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{lote.lote}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {lote.fecha_vencimiento}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <Text style={styles.footer}>
              Reporte generado el {formattedDate}
            </Text>
          </Page>
        </Document>
      ) : (
        'No se pudieron obtener los datos del reporte'
      )}
    </>
  );
}

export default MyDocument;
