import React, { useState, useRef } from "react";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import Toast from "../../components/Alert";
import { useNavigate } from "react-router-dom";
import { CircleLoader } from "react-spinners";


function ModificacionForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fecha: "",
    tipo: "",
    producto: "",
    cantidad: "",
    descripcion: "",
    firma: "", // Campo para almacenar la firma
  });
  const { fecha, tipo, producto, cantidad, descripcion } = formData;
  const signatureCanvas = useRef(null);
  const [imageData, setImageData] = useState(""); // Estado para la firma
  const validateForm = () => {
    // Ejemplo básico de validación
    if (
      !formData.fecha ||
      !formData.tipo ||
      !formData.producto ||
      !formData.cantidad
    ) {
      return false;
    }
    return true;
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const saveSignature = () => {
    const signature = signatureCanvas.current.toDataURL();
    setImageData(signature); // Guarda la firma en formato base64
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    // Crear form data para enviar incluyendo la firma
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(key, value);
    }

    // Decodificar la imagen base64 de la firma
    const base64ImageContent = imageData.replace(
      /^data:image\/(png|jpeg);base64,/,
      ""
    );
    const byteCharacters = atob(base64ImageContent);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });
    const file = new File([blob], "firma.png", { type: "image/png" });

    formDataToSend.append("firma", file); // Agregar la firma al FormData

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/modificaciones/create/`,
        formDataToSend,
        config
      );

      if (res.status === 201) {
        Toast({
          tipo: "success",
          mensaje: "Modificacion registrada correctamente!",
        });
        navigate("/modificaciones");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      Toast({
        tipo: "error",
        mensaje: "Ocurrió un error al registrar la modificacion.",
      });
    }
  };

  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Registro de Modificaciones.
        </h1>

        <form onSubmit={onSubmit} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Información de la entrada.
          </div>

          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="fecha" className="label">
                <span className="label-text dark:text-white">Fecha</span>
              </label>
              <input
                type="date"
                name="fecha"
                value={fecha}
                onChange={(e) => onChange(e)}
                required
                max={new Date().toISOString().split("T")[0]}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="tipo" className="label">
                <span className="label-text dark:text-white">Tipo</span>
              </label>
              <select
                name="tipo"
                value={tipo}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Selecciona un tipo</option>
                <option value="ENTRADAS">ENTRADAS</option>
                <option value="SALIDAS">SALIDAS</option>
                <option value="PRODUCCION">PRODUCCION</option>
                <option value="MEZCLA">MEZCLA</option>
              </select>
            </div>

            <div className="form-control">
              <label htmlFor="producto" className="label">
                <span className="label-text dark:text-white">Producto</span>
              </label>
              <input
                type="text"
                name="producto"
                value={producto}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="cantidad" className="label">
                <span className="label-text dark:text-white">Cantidad</span>
              </label>
              <input
                type="number"
                name="cantidad"
                value={cantidad}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>

            <div className="form-control">
              <label htmlFor="descripcion" className="label">
                <span className="label-text dark:text-white">Descripción</span>
              </label>
              <textarea
                name="descripcion"
                value={descripcion}
                onChange={(e) => onChange(e)}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
          </div>

          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Firma.
          </div>
          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">

            
          <div className="mt-4">
            <div className="flex justify-center w-full">
              <SignatureCanvas
                ref={signatureCanvas}
                penColor="black"
                canvasProps={{
                  width: 320,
                  height: 200,
                  className:
                    "bg-white border rounded justify-center items-center my-2",
                }}
                onEnd={saveSignature}
              />
            </div>
            <button
              type="button"
              onClick={() => signatureCanvas.current.clear()}
              className="mt-2 px-4 py-2 text-sm font-semibold text-blue-500 border border-blue-500 rounded-lg shadow-md"
            >
              Limpiar firma
            </button>
          </div>
        </div>
        <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white dark:bg-red-700">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white dark:bg-red-700"
              >
                Guardar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

export default ModificacionForm;
