import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const SalesOverviewChart = ({ labels, dataValues }) => {
  const data = {
    labels: labels || ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    datasets: [
      {
        label: 'Sonelo 900ML',
        data: dataValues || [65, 59, 80, 81, 56, 55, 40, 60, 70, 80, 90, 100],
        fill: true,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        tension: 0.4,
        pointBackgroundColor: 'rgba(255, 99, 132, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
      },
      {
        label: 'Sonelo 2000ML',
        data: [28, 48, 40, 19, 86, 27, 90, 50, 70, 80, 90, 100],
        fill: true,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        tension: 0.4,
        pointBackgroundColor: 'rgba(54, 162, 235, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(54, 162, 235, 1)',
      },
      {
        label: 'Sonelo 3000ML',
        data: [38, 78, 50, 29, 96, 37, 80, 60, 70, 80, 90, 100],
        fill: true,
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgba(255, 206, 86, 1)',
        tension: 0.4,
        pointBackgroundColor: 'rgba(255, 206, 86, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255, 206, 86, 1)',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.parsed.y} - Cajas`,
        },
      },
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#9CA3AF',
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: '#9CA3AF',
          font: {
            size: window.innerWidth < 768 ? 10 : 12,
          },
        },
      },
      x: {
        ticks: {
          color: '#9CA3AF',
          font: {
            size: window.innerWidth < 768 ? 10 : 12,
          },
        },
      },
    },
    layout: {
      padding: {
        left: window.innerWidth < 768 ? 5 : 20,
        right: window.innerWidth < 768 ? 5 : 20,
        top: window.innerWidth < 768 ? 5 : 20,
        bottom: window.innerWidth < 768 ? 5 : 20,
      },
    },
  };

  return (
    <div className="w-full max-w-[600px] h-[200px] md:h-[300px] lg:h-[400px] mx-auto">
      <Line data={data} options={options} />
    </div>
  );
};

export default SalesOverviewChart;
