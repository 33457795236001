import {
  GET_MODIFICACIONES_LIST_SUCCESS,
  GET_MODIFICACIONES_LIST_FAIL,
  GET_MODIFICACIONES_SUCCESS,
  GET_MODIFICACIONES_FAIL,
  GET_MODIFICACIONES_SEARCH_SUCCESS,
  GET_MODIFICACIONES_SEARCH_FAIL,
} from "./types";
import axios from "axios";

export const get_modicaciones_list =
  (planta = null) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    let url = `${process.env.REACT_APP_API_URL}/modificaciones/`;
    let params = new URLSearchParams();
    if (planta !== null && planta !== "") {
      params.append("planta", planta);
    }

    url += `?${params.toString()}`;

    try {
      const res = await axios.get(url, config);

      dispatch({
        type: GET_MODIFICACIONES_LIST_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_MODIFICACIONES_LIST_FAIL,
      });
    }
  };

export const get_modificaciones_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/modificaciones/?p=${p}`,
      config
    );

    dispatch({
      type: GET_MODIFICACIONES_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MODIFICACIONES_LIST_FAIL,
    });
  }
};

export const get_modificaciones = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/modificaciones/${id}/`,
      config
    );

    dispatch({
      type: GET_MODIFICACIONES_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MODIFICACIONES_FAIL,
    });
  }
};

export const get_modificaciones_search = (search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/modificaciones/search/${search}/`,
      config
    );

    dispatch({
      type: GET_MODIFICACIONES_SEARCH_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MODIFICACIONES_SEARCH_FAIL,
    });
  }
};

export const get_modificaciones_search_page = (p,search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/modificaciones/search/${search}/?p=${p}`,
      config
    );
    dispatch({
      type: GET_MODIFICACIONES_SEARCH_SUCCESS,
      payload: res.data,
    });
  }
  catch (err) {
    dispatch({
      type: GET_MODIFICACIONES_SEARCH_FAIL,
    });
  }
}

