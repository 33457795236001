import { useState, useEffect } from "react";
import { get_productos_list } from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import Toast from "../../Alert";

function ReferenciaModal({
  show,
  handleClose,
  isEditMode,
  editData,
  onSave,
  get_productos_list,
  productos,
}) {
  const [referencia, setReferencia] = useState({
    caja: "",
    codigo: "",
    envase: "",
    etiqueta: "",
    id: "",
    mezcla: "",
    peso: "",
    tapa: "",
  });

  // Estado para manejar los productos y sus cantidades
  const [selectedProductos, setSelectedProductos] = useState([
    { producto: "", cantidad: "" },
  ]);

  useEffect(() => {
    get_productos_list(); // Obtener lista de productos
  }, [get_productos_list]);

  useEffect(() => {
    if (isEditMode && editData) {
      setReferencia({
        ...editData.referencia,
      });
      setSelectedProductos(
        editData.recepcionproducto_set || [{ producto: "", cantidad: "" }]
      );
    } else {
      setReferencia({
        caja: "",
        codigo: "",
        envase: "",
        etiqueta: "",
        id: "",
        mezcla: "",
        peso: "",
        tapa: "",
      });
      setSelectedProductos([{ producto: "", cantidad: "" }]);
    }
  }, [isEditMode, editData]);

  // Manejar cambios en los campos de referencia
  const onChange = (e) => {
    const { name, value } = e.target;
    setReferencia((prev) => ({ ...prev, [name]: value }));
  };

  // Manejar cambios en los productos y cantidades seleccionados
  const onChangeProducto = (index, e) => {
    const { name, value } = e.target;
    const updatedProductos = selectedProductos.map((producto, idx) =>
      idx === index ? { ...producto, [name]: value } : producto
    );
    setSelectedProductos(updatedProductos);
  };

  // Añadir un nuevo producto a la lista
  const addProducto = () => {
    setSelectedProductos((prev) => [...prev, { producto: "", cantidad: "" }]);
  };

  // Enviar los datos al backend
  const onSubmit = (e) => {
    e.preventDefault();

    // Validar si se seleccionó al menos un producto con su cantidad
    const validProductos = selectedProductos.filter(
      (item) => item.producto && item.cantidad > 0
    );
    if (validProductos.length === 0) {

      Toast({
        tipo: "error",
        mensaje: "Debe seleccionar al menos un producto con su cantidad.",
      })
      return;
    }

    // Construir el payload con referencia y modificaciones
    const payload = {
      ...referencia,
      modificaciones: validProductos, // Enviar los productos seleccionados y sus cantidades
    };

    // Guardar la referencia con los productos
    onSave(payload);
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
      <div className="bg-white dark:bg-gray-900 p-5 rounded shadow-lg w-full max-w-lg md:max-w-2xl h-3/4 overflow-y-auto">
        <h2 className="text-xl font-bold mb-4 dark:text-white">
          {isEditMode ? "Editar Referencia" : "Añadir Referencia"}
        </h2>
        <form onSubmit={onSubmit}>
          {/* Campos de referencia */}
          <div className="form-control mb-4">
            <label htmlFor="codigo" className="label">
              <span className="label-text dark:text-gray-300">Código</span>
            </label>
            <input
              type="text"
              name="codigo"
              value={referencia.codigo}
              onChange={onChange}
              required
              className="input input-bordered w-full dark:bg-gray-800 dark:text-white"
              disabled={isEditMode}
            />
          </div>

          <div className="form-control mb-4">
            <label htmlFor="caja" className="label">
              <span className="label-text dark:text-gray-300">Caja</span>
            </label>
            <input
              type="text"
              name="caja"
              value={referencia.caja}
              onChange={onChange}
              required
              disabled={isEditMode}
              className="input input-bordered w-full dark:bg-gray-800 dark:text-white"
            />
          </div>

          <div className="form-control mb-4">
            <label htmlFor="envase" className="label">
              <span className="label-text dark:text-gray-300">Envase</span>
            </label>
            <input
              type="text"
              name="envase"
              value={referencia.envase}
              onChange={onChange}
              disabled={isEditMode}
              className="input input-bordered w-full dark:bg-gray-800 dark:text-white"
            />
          </div>

          <div className="form-control mb-4">
            <label htmlFor="etiqueta" className="label">
              <span className="label-text dark:text-gray-300">Etiqueta</span>
            </label>
            <input
              type="text"
              name="etiqueta"
              value={referencia.etiqueta}
              onChange={onChange}
              disabled={isEditMode}
              className="input input-bordered w-full dark:bg-gray-800 dark:text-white"
            />
          </div>

          <div className="form-control mb-4">
            <label htmlFor="mezcla" className="label">
              <span className="label-text dark:text-gray-300">
                Tipo de Aceite
              </span>
            </label>
            <input
              type="text"
              name="mezcla"
              value={referencia.tipo_aceite}
              onChange={onChange}
              disabled={isEditMode}
              className="input input-bordered w-full dark:bg-gray-800 dark:text-white"
            />
          </div>

          <div className="form-control mb-4">
            <label htmlFor="peso" className="label">
              <span className="label-text dark:text-gray-300">Peso</span>
            </label>
            <input
              type="text"
              name="peso"
              value={referencia.peso}
              onChange={onChange}
              disabled={isEditMode}
              className="input input-bordered w-full dark:bg-gray-800 dark:text-white"
            />
          </div>

          <div className="form-control mb-4">
            <label htmlFor="tapa" className="label">
              <span className="label-text dark:text-gray-300">Tapa</span>
            </label>
            <input
              type="text"
              name="tapa"
              value={referencia.tapa}
              onChange={onChange}
              disabled={isEditMode}
              className="input input-bordered w-full dark:bg-gray-800 dark:text-white"
            />
          </div>

          <div className="mb-4">
            {selectedProductos.map((item, index) => {
              const productoSeleccionado = productos.find(
                (prod) => prod.id === item.producto
              );

              const inventarioDisponible =
                productoSeleccionado?.inventario_data?.inventario || 0;

              return (
                <div key={index} className="form-control mb-4">
                  <label htmlFor={`producto-${index}`} className="label">
                    <span className="label-text dark:text-gray-300">
                      Producto a Modificar {index + 1}
                    </span>
                  </label>
                  <select
                    name="producto"
                    value={item.producto}
                    onChange={(e) => onChangeProducto(index, e)}
                    required
                    className="input input-bordered w-full dark:bg-gray-800 dark:text-white"
                  >
                    <option value="">Seleccione un producto</option>
                    {productos
                      .filter((prod) => prod.inventario_data?.inventario > 0)
                      .map((prod) => (
                        <option key={prod.id} value={prod.id}>
                          {prod.nombre} - {prod.referencia}
                        </option>
                      ))}
                  </select>

                  {/* Mostrar inventario disponible del producto seleccionado */}
                  {productoSeleccionado && (
                    <span className="text-sm text-gray-600 dark:text-gray-300">
                      Cantidad disponible: {inventarioDisponible}
                    </span>
                  )}

                  <label htmlFor={`cantidad-${index}`} className="label">
                    <span className="label-text dark:text-gray-300">
                      Cantidad
                    </span>
                  </label>
                  <input
                    type="number"
                    name="cantidad"
                    value={item.cantidad}
                    onChange={(e) => {
                      const cantidadIngresada = parseInt(e.target.value, 10);

                      // Validar que la cantidad no sea mayor al inventario disponible
                      if (cantidadIngresada > inventarioDisponible) {
                        Toast({
                          tipo: "error",
                          mensaje: `La cantidad ingresada no puede ser mayor al inventario disponible (${inventarioDisponible}).`,
                        })
                        return;
                      }

                      onChangeProducto(index, e); // Actualizar la cantidad si es válida
                    }}
                    required
                    className="input input-bordered w-full dark:bg-gray-800 dark:text-white"
                  />
                </div>
              );
            })}

            <button
              type="button"
              className="btn btn-success mb-4 text-white dark:bg-gray-700 dark:text-white"
              onClick={addProducto}
            >
              Añadir Producto
            </button>
          </div>

          {/* Botones para guardar y cancelar */}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-error mr-2 text-white dark:bg-gray-700 dark:text-white"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-accent text-white dark:bg-blue-700 dark:text-white"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  productos: state.productos.productos || [],
});

export default connect(mapStateToProps, { get_productos_list })(
  ReferenciaModal
);
