import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import logo from "../../../../assets/img/logo-removebg-preview.png";

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 80,
    paddingHorizontal: 40,
  },
  title: {
    fontSize: 22,
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 10,
  },
  image: {
    marginVertical: 10,
    marginHorizontal: "auto",
    width: 80,
  },
  table: {
    display: "table",
    width: "100%",
    marginTop: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
  },
  tableRow: {
    flexDirection: "row",
    width: "100%",
  },
  tableColNombre: {
    width: "14%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    padding: 5,
  },
  tableColReferencia: {
    width: "14%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    padding: 5,
  },
  tableColDescripcion: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    padding: 5,
  },
  tableColEntradas: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    padding: 5,
  },
  tableColSalidas: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    padding: 5,
  },
  tableColInventario: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    padding: 5,
  },
  tableColCostoTotal: {
    width: "22%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    padding: 4,
    // Eliminamos 'backgroundColor' para quitar la sombra
  },
  tableCell: {
    fontSize: 9,
    textAlign: "center",
    padding: 3,
    wordBreak: 'break-word',
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 35,
    right: 35,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const formatDateTime = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(date);
};

function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  const renderTableHeader = () => (
    <View style={styles.tableRow}>
      <View style={styles.tableColNombre}>
        <Text style={styles.tableCellHeader}>Nombre</Text>
      </View>
      <View style={styles.tableColReferencia}>
        <Text style={styles.tableCellHeader}>Referencia</Text>
      </View>
      <View style={styles.tableColDescripcion}>
        <Text style={styles.tableCellHeader}>Descripción</Text>
      </View>
      <View style={styles.tableColEntradas}>
        <Text style={styles.tableCellHeader}>Entradas</Text>
      </View>
      <View style={styles.tableColSalidas}>
        <Text style={styles.tableCellHeader}>Salidas</Text>
      </View>
      <View style={styles.tableColInventario}>
        <Text style={styles.tableCellHeader}>Inventario</Text>
      </View>
      <View style={styles.tableColCostoTotal}>
        <Text style={styles.tableCellHeader}>Costo Total</Text>
      </View>
    </View>
  );

  const renderTableRows = (items) =>
    items.map((item, index) => (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tableColNombre}>
          <Text style={styles.tableCell}>
            {item?.producto?.nombre || "N/A"}
          </Text>
        </View>
        <View style={styles.tableColReferencia}>
          <Text style={styles.tableCell}>
            {item?.producto?.referencia || "N/A"}
          </Text>
        </View>
        <View style={styles.tableColDescripcion}>
          <Text style={styles.tableCell}>
            {item?.producto?.descripcion || "N/A"}
          </Text>
        </View>
        <View style={styles.tableColEntradas}>
          <Text style={styles.tableCell}>{item?.entradas || "0"}</Text>
        </View>
        <View style={styles.tableColSalidas}>
          <Text style={styles.tableCell}>{item?.salidas || "0"}</Text>
        </View>
        <View style={styles.tableColInventario}>
          <Text style={styles.tableCell}>{item?.inventario || "0"}</Text>
        </View>
        <View style={styles.tableColCostoTotal}>
          <Text style={styles.tableCell}>
            {item?.costos_total ? `$ ${item.costos_total}` : "0"}
          </Text>
        </View>
      </View>
    ));

  const rowsPerPageFirst = 8;
  const rowsPerPageOther = 10;

  const totalPages =
    Math.ceil((data.length - rowsPerPageFirst) / rowsPerPageOther) + 1;

  const renderPages = () => {
    const pages = [];

    pages.push(
      <Page
        size="A4"
        orientation="landscape"
        style={styles.body}
        wrap
        key="first"
      >
        <Image style={styles.image} src={logo} />
        <Text style={styles.title}>Reporte de Inventario</Text>
        <View style={styles.table}>{renderTableHeader()}</View>
        <View style={styles.table}>
          {renderTableRows(data.slice(0, rowsPerPageFirst))}
        </View>
        <Text style={styles.footer}>
          Reporte generado el {formattedDate}
        </Text>
        <Text style={styles.pageNumber}>
          {`Página 1 de ${totalPages}`}
        </Text>
      </Page>
    );

    let pageNumber = 1;
    for (let i = rowsPerPageFirst; i < data.length; i += rowsPerPageOther) {
      pageNumber++;
      pages.push(
        <Page
          size="A4"
          orientation="landscape"
          style={styles.body}
          wrap
          key={`page-${pageNumber}`}
        >
          <Image style={styles.image} src={logo} />
          <Text style={styles.title}>Reporte de Inventario</Text>
          <View style={styles.table}>
            {renderTableRows(data.slice(i, i + rowsPerPageOther))}
          </View>
          <Text style={styles.footer}>
            Reporte generado el {formattedDate}
          </Text>
          <Text style={styles.pageNumber}>
            {`Página ${pageNumber} de ${totalPages}`}
          </Text>
        </Page>
      );
    }

    return pages;
  };

  return (
    <Document title={`Reporte de fecha ${formattedDate}`}>
      {renderPages()}
    </Document>
  );
}

export default MyDocument;
