import {
  GET_PROVEEDORES_LIST_SUCCESS,
  GET_PROVEEDORES_LIST_FAIL,
  GET_PROVEEDORES_SUCCESS,
  GET_PROVEEDORES_FAIL,
  GET_PROVEEDORES_SEARCH_SUCCESS,
  GET_PROVEEDORES_SEARCH_FAIL,
} from "../actions/proveedores/types"

const initialState = {
  proveedor: null,
  proveedores: null,
  proveedor_filtrado: null,
  count: null,
  next: null,
  previous: null,
};

export default function proveedores(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROVEEDORES_LIST_SUCCESS:
      return {
        ...state,
        proveedores: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_PROVEEDORES_LIST_FAIL:
      return {
        ...state,
        proveedores: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_PROVEEDORES_SUCCESS:
      return {
        ...state,
        proveedor: payload.proveedor,
      };
    case GET_PROVEEDORES_FAIL:
      return {
        ...state,
        proveedor: null,
      };
    case GET_PROVEEDORES_SEARCH_SUCCESS:
      return {
        ...state,
        proveedor_filtrado: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_PROVEEDORES_SEARCH_FAIL:
      return {
        ...state,
        proveedor_filtrado: null,
        count: null,
        next: null,
        previous: null,
      };
    default:
      return state;
  }
}
