import SetPagination from "../../pagination/SetPagination";
import ProduccionCardHorizontal from "./ProduccionCardHorizontal";

function ProduccionList({
  produccion,
  get_produccion_list_page,
  count,
  refreshData,
  user
}) {
  return (
    <div className="overflow-hidden">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500 w-full">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white w-1/12">
                #
              </th>
              <th scope="col" className="p-2 dark:text-white w-2/12">
                Fecha
              </th>
              <th scope="col" className="p-2 dark:text-white w-2/12">
                Turno
              </th>
              <th scope="col" className="p-2 dark:text-white w-3/12">
                Lote Aceite
              </th>
              <th scope="col" className="p-2 dark:text-white w-3/12">
                Referencia
              </th>
              <th scope="col" className="p-2 dark:text-white w-1/12">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {produccion && produccion.length > 0 ? (
              produccion.map((produccion, index) => (
                <ProduccionCardHorizontal
                  data={produccion}
                  key={index}
                  index={index}
                  refreshData={refreshData}
                  user={user}
                />
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center p-4">
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_produccion_list_page}
        list={produccion}
        count={count}
        type="medium"
      />
    </div>
  );
}
export default ProduccionList;
