import SetPagination from "../pagination/SetPagination";
import AveriasCardHorizontal from "./AveriasCardHorizontal";

function AveriasList({
  averias,
  count,
  refreshData,
  get_averias_list,
  get_averias_list_page,
}) {
  return (
    <div className="overflow-hidden bg-white dark:bg-gray-800">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white"></th>
              <th scope="col" className="p-2 dark:text-white">Fecha</th>
              <th scope="col" className="p-2 dark:text-white">Tipo</th>
              <th scope="col" className="p-2 dark:text-white">Remision</th>
              <th scope="col" className="p-2 dark:text-white">Productos</th>
              <th scope="col" className="p-2 dark:text-white">Descontada</th>
              <th scope="col" className="p-2 dark:text-white">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {averias && averias.length > 0 ? (
              averias.map((registroAverias, idx) => (
                <AveriasCardHorizontal
                  key={idx}
                  data={registroAverias}
                  index={idx}
                />
              ))
            ) : (
              <tr>
                <td className="p-2 text-center" colSpan="7">
                  No hay registros de averías disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_averias_list_page}
        list={get_averias_list}
        count={count}
        type={"large"}
      />
    </div>
  );
}

export default AveriasList;
