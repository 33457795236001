import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/img/logo-removebg-preview.png";

// Registrar la fuente Oswald
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v17/TK3iWkUHHAIjg75cFRf3bXL8LICs1x0CEmU.ttf",
});

// No es necesario registrar Times-Roman y Times-Bold, ya que son fuentes estándar incluidas en @react-pdf

// Estilos para el PDF
const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 40,
    paddingHorizontal: 50,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 18,
    fontFamily: "Oswald",
    marginBottom: 10,
    textAlign: "left",
    borderBottomWidth: 1,
    borderBottomColor: "#CCCCCC",
    paddingBottom: 5,
  },
  text: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    marginBottom: 10,
    lineHeight: 1.5,
  },
  boldText: {
    fontSize: 12,
    fontFamily: "Times-Bold",
    color: "#333333",
  },
  image: {
    marginVertical: 10,
    marginHorizontal: "auto",
    width: 80,
  },
  signatureImage: {
    marginVertical: 15,
    marginHorizontal: "auto",
    width: 150,
    height: 50,
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 50,
    right: 50,
    textAlign: "center",
    color: "grey",
  },
  contentContainer: {
    marginBottom: 15,
  },
  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  fieldContainer: {
    flexDirection: "row",
    width: "48%",
    marginBottom: 5,
  },
  fieldLabel: {
    fontSize: 12,
    fontFamily: "Times-Bold",
    color: "#333333",
  },
  fieldValue: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    color: "#555555",
  },
  divider: {
    marginVertical: 10,
    borderBottomWidth: 1,
    borderColor: "#CCCCCC",
  },
});

// Función para formatear fechas
const formatDateTime = (date) => {
  if (!date) return "Fecha no disponible";
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(new Date(date));
};

// Componente principal para generar el documento PDF
function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  // Construir la URL completa de la firma
  const firmaUrl = data.firma
    ? `${process.env.REACT_APP_API_URL}${data.firma}`
    : null;

  // Verificar el estado y asignar el texto correcto
  const statusText = data.status ? "Completado" : "Pendiente";

  return (
    <>
      {data ? (
        <Document title={`Reporte de Cambio - ${data.producto}`}>
          <Page style={styles.body} wrap>
            {/* Título principal */}
            <Image style={styles.image} src={logo} />
            <Text style={styles.title}>Reporte de Cambio</Text>

            {/* Sección de detalles del cambio */}
            <View style={styles.contentContainer}>
              <Text style={styles.subtitle}>Detalles del Cambio</Text>
              <View style={styles.divider} />
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Producto: </Text>
                  <Text style={styles.fieldValue}>{data.producto}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Tipo de Cambio: </Text>
                  <Text style={styles.fieldValue}>{data.tipo}</Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Cantidad: </Text>
                  <Text style={styles.fieldValue}>{data.cantidad}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Planta: </Text>
                  <Text style={styles.fieldValue}>{data.user.plant}</Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Usuario: </Text>
                  <Text style={styles.fieldValue}>
                    {data.user.first_name} {data.user.last_name}
                  </Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Fecha de Modificación: </Text>
                  <Text style={styles.fieldValue}>
                    {formatDateTime(data.fecha)}
                  </Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Estado: </Text>
                  <Text style={styles.fieldValue}>{statusText}</Text>
                </View>
              </View>
            </View>

            {/* Sección de descripción */}
            <View style={styles.contentContainer}>
              <Text style={styles.subtitle}>Descripción</Text>
              <View style={styles.divider} />
              <Text style={styles.text}>{data.descripcion}</Text>
            </View>

            {/* Sección para la firma */}
            {data.firma && (
              <View style={styles.contentContainer}>
                <Text style={styles.subtitle}>Firma</Text>
                <View style={styles.divider} />
                <Image style={styles.signatureImage} src={firmaUrl} />
              </View>
            )}

            {/* Pie de página */}
            <Text style={styles.footer}>
              Reporte generado el {formattedDate}
            </Text>
          </Page>
        </Document>
      ) : (
        <Text>No se pudieron obtener los datos</Text>
      )}
    </>
  );
}

export default MyDocument;
