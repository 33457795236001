import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const ProduccionAra = ({ produccionAraData }) => {
  const getColor = (label) => {
    const colors = {
      "900 ML MA": '#E62514',
      "2000 ML MA": '#007BFF',
      "3000 ML MA": '#FFC107',
      "900 ML MB": '#28A745',
      "2000 ML MB": '#6610f2',
      "3000 ML MB": '#6C757D',
      "900 ML MC": '#17A2B8',
      "2000 ML MC": '#DC3545',
      "3000 ML MC": '#FFC107',
    };
    return colors[label];
  };

  // Initialize empty arrays for each possible container and oil type
  const dataValues = {};
  produccionAraData.forEach(({ etiqueta, cantidad, month }) => {
    // Adjust parsing logic to extract the correct size and oil type
    const [containerSize, , oilType] = etiqueta.split("-").map(part => part.trim());
    const key = `${containerSize} ${oilType}`;

    if (!dataValues[key]) dataValues[key] = Array(12).fill(0);
    dataValues[key][month - 1] += cantidad;  // Adjust for month index
  });

  const datasets = Object.entries(dataValues)
    .filter(([, values]) => values.some((value) => value > 0))
    .map(([label, values]) => ({
      label,
      data: values,
      backgroundColor: getColor(label),
      borderColor: getColor(label),
      borderWidth: 1,
      barThickness: 'flex',
    }));

  const data = {
    labels: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    datasets: datasets,  // Only datasets with values will be included
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: '#9CA3AF',
          font: { size: window.innerWidth < 768 ? 10 : 12 },
        },
      },
      x: {
        ticks: {
          color: '#9CA3AF',
          font: { size: window.innerWidth < 768 ? 10 : 12 },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#9CA3AF',
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw} - Cajas`,
        },
      },
    },
  };

  return (
    <div className="w-full max-w-[600px] h-[200px] md:h-[300px] lg:h-[400px] mx-auto">
      <Bar data={data} options={options} />
    </div>
  );
};

export default ProduccionAra;
