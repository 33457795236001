import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/img/logo-removebg-preview.png";

// Registrar la fuente Oswald
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v17/TK3iWkUHHAIjg75cFRf3bXL8LICs1x0CEmU.ttf",
});

// Estilos para el PDF
const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 40,
    paddingHorizontal: 50,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 18,
    fontFamily: "Oswald",
    marginBottom: 10,
    textAlign: "left",
    borderBottomWidth: 1,
    borderBottomColor: "#CCCCCC",
    paddingBottom: 5,
  },
  text: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    marginBottom: 10,
    lineHeight: 1.5,
  },
  boldText: {
    fontSize: 12,
    fontFamily: "Times-Bold",
    color: "#333333",
  },
  image: {
    marginVertical: 10,
    marginHorizontal: "auto",
    width: 80,
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 50,
    right: 50,
    textAlign: "center",
    color: "grey",
  },
  contentContainer: {
    marginBottom: 15,
  },
  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  fieldContainer: {
    flexDirection: "row",
    width: "48%",
    marginBottom: 5,
  },
  fieldLabel: {
    fontSize: 12,
    fontFamily: "Times-Bold",
    color: "#333333",
  },
  fieldValue: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    color: "#555555",
  },
  divider: {
    marginVertical: 10,
    borderBottomWidth: 1,
    borderColor: "#CCCCCC",
  },
});

// Función para formatear fechas
const formatDateTime = (date) => {
  if (!date) return "Fecha no disponible";
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(new Date(date));
};

// Componente principal para generar el documento PDF
function MyDocument({ data }) {
  const formattedDate = formatDateTime(data.created_at);

  return (
    <>
      {data ? (
        <Document title={`Reporte de Cliente - ${data.nombre}`}>
          <Page style={styles.body} wrap>
            {/* Logo */}
            <Image style={styles.image} src={logo} />
            {/* Título principal */}
            <Text style={styles.title}>Reporte de Proveedor</Text>

            {/* Sección de detalles del cliente */}
            <View style={styles.contentContainer}>
              <Text style={styles.subtitle}>Información del Proveedor</Text>
              <View style={styles.divider} />
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Nombre: </Text>
                  <Text style={styles.fieldValue}>{data.nombre}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>NIT: </Text>
                  <Text style={styles.fieldValue}>{data.nit}</Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Dirección: </Text>
                  <Text style={styles.fieldValue}>{data.direccion}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Encargado: </Text>
                  <Text style={styles.fieldValue}>{data.encargado}</Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Teléfono: </Text>
                  <Text style={styles.fieldValue}>{data.telefono}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Email: </Text>
                  <Text style={styles.fieldValue}>{data.email}</Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Fecha de Registro: </Text>
                  <Text style={styles.fieldValue}>{formattedDate}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Última Actualización: </Text>
                  <Text style={styles.fieldValue}>
                    {formatDateTime(data.updated_at)}
                  </Text>
                </View>
              </View>
            </View>

            {/* Sección de observaciones */}
            <View style={styles.contentContainer}>
              <Text style={styles.subtitle}>Observaciones</Text>
              <View style={styles.divider} />
              <Text style={styles.text}>{data.observaciones}</Text>
            </View>

            {/* Pie de página */}
            <Text style={styles.footer}>
              Reporte generado el {formatDateTime(new Date())}
            </Text>
          </Page>
        </Document>
      ) : (
        <Text>No se pudieron obtener los datos</Text>
      )}
    </>
  );
}

export default MyDocument;
