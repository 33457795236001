import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { Link } from "react-router-dom";
import { IconFilter, IconSearch } from "@tabler/icons-react";
import { get_entrada_list, get_entrada_list_page } from "../../../redux/actions/P03/p03";
import { connect, useSelector } from "react-redux";
import EntradasList from "../../../components/P03/entradas/EntradasList";
import Toast from "../../../components/Alert";

function EntradasP03({
  get_entrada_list,
  get_entrada_list_page,
  entradas,
  count,
}) {
  const user = useSelector((state) => state.auth.user);
  const [planta, setPlanta] = useState("");
  const [plantamensual, setPlantaMensual] = useState("");
  const [mes, setMes] = useState("");
  const [año, setAño] = useState("");
  const [mostrarReporte, setMostrarReporte] = useState(false);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

  useEffect(() => {
    if (planta !== "") {
      get_entrada_list(planta);
    } else {
      get_entrada_list();
    }
  }, [planta]);

  const handleChangePlanta = (e) => {
    setPlanta(e.target.value);
  };

  const handleChangePlantaMensual = (e) => {
    setPlantaMensual(e.target.value);
  };

  const handleChangeMes = (e) => {
    setMes(e.target.value);
  };

  const handleChangeAño = (e) => {
    setAño(e.target.value);
  };

  const handleReporteMensual = () => {
    if (plantamensual && mes && año) {
      alert(plantamensual, mes, año);
      setTimeout(
        () =>
          (window.location.href =
            "/entradas-p03/ver-mensual/" +
            plantamensual +
            "/" +
            mes +
            "/" +
            año),
        0.2
      );
    } else {
      Toast({
        tipo: "error",
        mensaje: "Debes seleccionar una planta, un mes y un año.",
      });
    }
  };

  const [term, setTerm] = useState("");
  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => (window.location.href = "/entradas-p03/" + term), 0.2);
    setTerm("");
  };

  // Definir la función refreshData
  const refreshData = () => {
    get_entrada_list();
  };

  return (
    <DashboardLayout>
      <div className="border-b border-gray-200 dark:border-gray-700 py-5 sm:px-6">
        <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
          <div>
            <h3 className="text-2xl font-medium leading-6 text-gray-900 dark:text-white">
              Lista de entradas de materiales e insumos Planta 03.
            </h3>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
            {
              user&&user.role==="COORDINADOR_PRODUCCION_ENVASADO" || user&&user.role==="COORDINADOR_PLANTA_ENVASADO" ? (
                <div className="flex justify-between gap-4">
              <Link to="/entradas-p03/form">
                <button className="bg-[#DC2917] text-white px-4 py-1.5 rounded hover:bg-red-800">
                  Agregar entrada
                </button>
              </Link>
            </div>
              ) : null
            }
            <div>
              <div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
                <form onSubmit={(e) => onSubmit(e)} className="w-full">
                  <label htmlFor="search" className="sr-only">
                    Buscar
                  </label>
                  <div className="relative">
                    <button
                      type="submit"
                      className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
                    >
                      <IconSearch stroke={"#0096C4"} />
                    </button>
                    <input
                      id="search"
                      name="search"
                      required
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className="block w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:text-gray-900 dark:focus:text-gray-100 focus:ring-1 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-indigo-500 dark:focus:border-indigo-400 sm:text-sm"
                      placeholder="Buscar"
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="dropdown dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="p-1.5 bg-white dark:bg-gray-800 border border-[#0096C4] dark:border-gray-600 rounded"
              >
                <IconFilter stroke={"#0096C4"} />
              </div>
              <div
                tabIndex={0}
                className="dropdown-content z-[1] menu p-4 shadow bg-base-100 dark:bg-gray-700 rounded-box w-52"
              >
                <div className="flex flex-col gap-y-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label">
                      <span className="label-text dark:text-white">Planta</span>
                    </div>
                    <select
                      onChange={handleChangePlanta}
                      value={planta}
                      className="select select-bordered w-full dark:bg-gray-800 dark:text-white dark:border-gray-600"
                    >
                      <option value="">Elige una opción</option>
                      <option value="01">Planta Principal</option>
                      <option value="02">Planta Patios</option>
                      <option value="03">Planta Envasado</option>
                      <option value="04">Planta Soplado</option>
                      <option value="05">Bodega</option>
                      <option value="Proveedores">Proveedor</option>
                    </select>
                  </label>
                  <label className="form-control w-full max-w-xs">
                    <div className="label">
                      <span className="label-text dark:text-white">
                        Reporte Mensual
                      </span>
                    </div>
                    <button
                      onClick={() => setMostrarReporte(!mostrarReporte)}
                      className="bg-[#0096C4] w-full text-white px-4 py-1.5 rounded hover:bg-[#007ca1] self-end"
                    >
                      {mostrarReporte ? "Ocultar Reporte" : "Reporte Mensual"}
                    </button>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {mostrarReporte && (
          <div className="mt-4 flex gap-4">
            <div className="form-control">
              <label className="label">
                <span className="label-text dark:text-white">Planta</span>
              </label>
              <select
                onChange={handleChangePlantaMensual}
                value={plantamensual}
                className="select select-bordered dark:bg-gray-800 dark:text-white dark:border-gray-600"
              >
                <option value="">Elige una planta</option>
                <option value="1">Planta 01</option>
                <option value="2">Planta 02</option>
                <option value="3">Planta 03</option>
                <option value="4">Planta 04</option>
                <option value="5">Planta 05</option>
                <option value="Proveedores">Proveedor</option>
              </select>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text dark:text-white">Mes</span>
              </label>
              <select
                onChange={handleChangeMes}
                value={mes}
                className="select select-bordered dark:bg-gray-800 dark:text-white dark:border-gray-600"
              >
                <option value="">Elige un mes</option>
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text dark:text-white">Año</span>
              </label>
              <select
                onChange={handleChangeAño}
                value={año}
                className="select select-bordered dark:bg-gray-800 dark:text-white dark:border-gray-600"
              >
                <option value="">Elige un año</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={handleReporteMensual}
              className={`bg-[#0096C4] text-white px-4 py-1.5 rounded hover:bg-[#007ca1] self-end ${
                !plantamensual || !mes || !año
                  ? "cursor-not-allowed opacity-50"
                  : ""
              }`}
              disabled={!plantamensual || !mes || !año}
            >
              Generar Reporte
            </button>
          </div>
        )}
      </div>
      <EntradasList
        entradas={entradas}
        get_entrada_list={get_entrada_list}
        get_entrada_list_page={get_entrada_list_page}
        count={count}
        refreshData={refreshData}
        user={user}
      />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  entradas: state.entradas.entradas,
  count: state.entradas.count,
});

export default connect(mapStateToProps, {
  get_entrada_list,
  get_entrada_list_page,
})(EntradasP03);
