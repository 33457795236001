import SetPagination from "../../pagination/SetPagination";
import { IconEye, IconPencil } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function InventarioFullList({ inventario, get_inventario_full_list_page, count }) {
  return (
    <div className="overflow-hidden">
      <div className="relative overflow-x-auto">
        <table className="table-auto text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white"></th>
              <th scope="col" className="p-2 dark:text-white min-w-[200px] whitespace-nowrap">
                Material o Insumo
              </th>
              <th scope="col" className="p-2 dark:text-white">Cantidad Antes</th>
              <th scope="col" className="p-2 dark:text-white">Cantidad Cambiada</th>
              <th scope="col" className="p-2 dark:text-white">Cantidad Después</th>
              <th scope="col" className="p-2 dark:text-white">Justificación</th>
              <th scope="col" className="p-2 dark:text-white">Fecha</th>
              <th scope="col" className="p-2 dark:text-white">Responsable</th>
            </tr>
          </thead>
          <tbody>
            {inventario && inventario.length > 0 ? (
              inventario.map((data, index) => (
                <tr key={index} className="border-b border-red-600">
                  <td className="p-2 dark:text-white"></td>
                  <td className="p-2 dark:text-white min-w-[200px] whitespace-nowrap">
                    <div className="flex text-xs whitespace-nowrap">
                      <span className="font-bold">Insumo: </span>
                      <span className="ml-1">{data.producto}</span>
                    </div>
                    <div className="flex text-xs whitespace-nowrap">
                      <span className="font-bold">Tipo de Cambio: </span>
                      <span className="ml-1">{data.tipo_cambio}</span>
                    </div>
                  </td>
                  <td className="p-2 dark:text-white">
                    {data.cantidad_antes ? data.cantidad_antes : 0}
                  </td>
                  <td className="p-2 dark:text-white">
                    {data.cantidad_cambiada ? data.cantidad_cambiada : 0}
                  </td>
                  <td className="p-2 dark:text-white">
                    {data.cantidad_despues ? data.cantidad_despues : 0}
                  </td>
                  <td className="p-2 dark:text-white">
                    {data.justificacion ? data.justificacion : "N/A"}
                  </td>
                  <td className="p-2 dark:text-white">
                    {new Date(data.fecha).toLocaleString()}
                  </td>
                  <td className="p-2 dark:text-white">
                    {data.user ? data.user : "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center p-4">
                  No hay registros disponibles.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_inventario_full_list_page}
        list={inventario}
        count={count}
        type="large"
      />
    </div>
  );
}

export default InventarioFullList;
