import { combineReducers } from 'redux';
import auth from './auth.js';
import alert from './alert.js';
import modificaciones from './modificaciones.js';
import historial from './historial.js';
import entradas from './p03.js';
import proveedores from './proveedores.js';
import averias from './averias.js';
import { productos, salidas, salidaInsumos,referencia, produccion , inventario, inventarioFull,saldoPlanta, combinacionMezclas} from './p03.js';
export default combineReducers({
//  Autenticacion
    auth,
    alert,
// Historial
    historial,
// Modificaciones
    modificaciones,
// Proveedores
    proveedores,
//  P03
    productos,
    entradas,
    salidas,
    salidaInsumos,
    referencia,
    produccion,
    inventario,
    inventarioFull,
    saldoPlanta,
    combinacionMezclas,
// Averias
    averias

})  