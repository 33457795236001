import { IconEye } from "@tabler/icons-react";
import { Link } from "react-router-dom";

function AveriasCardHorizontal({ data, index }) {
  const esProduccion = data.produccion !== null;
  const esSalidaEnvasado = data.salida_envasado !== null;

  // Create list of products with their quantities
  const productosYCantidades = data.averias.map((averia) => (
    <li key={averia.id}>
      {averia.producto.nombre} - {averia.producto.referencia} - {averia.producto.descripcion} 
      &nbsp;(<strong>Cantidad:</strong> {averia.cantidad})
    </li>
  ));

  const descontadas =
    data.averias.every((averia) => averia.descontada) ? (
      <div className="badge badge-success">Descontada</div>
    ) : (
      <div className="badge badge-error">Sin Descontar</div>
    );

  return (
    <tr className="bg-white border-b dark:bg-gray-800">
      <td className="p-2 dark:text-white">{index + 1}</td>

      <td className="p-2 dark:text-white">
        {esProduccion
          ? data.produccion.fecha
          : esSalidaEnvasado
          ? data.salida_envasado.fecha
          : "N/A"}
      </td>

      <td className="p-2 dark:text-white">
        {esProduccion ? "Producción" : esSalidaEnvasado ? "Salida Producto Terminado" : "N/A"}
      </td>
      <td className="p-2 dark:text-white">
        {esProduccion
          ? data.produccion.remision
          : esSalidaEnvasado
          ? data.salida_envasado.remision
          : "N/A"}
      </td>
      {/* List each product with its quantity in a bullet point format */}
      <td className="p-2 dark:text-white">
        <ul className="list-disc list-inside">{productosYCantidades}</ul>
      </td>

      {/* Show descontada status */}
      <td className="p-2 dark:text-white">{descontadas}</td>

      {/* Acciones */}
      <td className="p-2 dark:text-white">
        <div className="flex items-center gap-2">
          <div className="flex tooltip" data-tip="Ver Pdf">
            <Link
              to={`/averias-p03/ver/${data.id}`}
              className="p-1 text-white bg-blue-500 rounded-full"
            >
              <IconEye />
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default AveriasCardHorizontal;
