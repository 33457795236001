import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import logo from "../../../../assets/img/logo-removebg-preview.png";

// Registrar fuentes
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

Font.register({
  family: "Times-Bold",
  src: "https://fonts.gstatic.com/s/timesnewroman/v1/NiX7eS-8kFObHLkSXE4QGo6lWzYJpivMTA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 40,
    paddingHorizontal: 40,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
  },
  headerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 5,
    backgroundColor: "#f2f2f2",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
  },
  title: {
    fontSize: 22,
    fontFamily: "Oswald",
    width: "75%",
    textAlign: "center",
  },
  image: {
    width: 40,
    height: 40,
    textAlign: "center",
  },
  detailsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
    borderTopWidth: 0,
    marginBottom: 10,
  },
  detailsCol: {
    width: "25%",
    fontSize: 10,
    fontFamily: "Times-Bold",
    textAlign: "center",
    padding: 10,
  },
  contentContainer: {
    marginBottom: 10,
  },
  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  fieldContainer: {
    flexDirection: "row",
    width: "48%",
  },
  fieldLabel: {
    fontSize: 12,
    fontFamily: "Times-Bold",
  },
  fieldValue: {
    fontSize: 12,
    fontFamily: "Times-Roman",
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
  },
  tableRow: {
    flexDirection: "row",
    width: "100%",
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
    padding: 5,
    backgroundColor: "#f2f2f2",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 10,
    fontFamily: "Times-Bold",
    textAlign: "center",
  },
  tableCell: {
    fontSize: 9,
    textAlign: "center",
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 40,
    right: 40,
    textAlign: "center",
    color: "grey",
  },
  badgeSuccess: {
    color: "green",
    fontSize: 14,
    fontFamily: "Oswald",
  },
  badgeError: {
    color: "red",
    fontSize: 14,
    fontFamily: "Oswald",
  },
});

const formatDateTime = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(date);
};

function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  const averiasArray = Array.isArray(data.averias) ? data.averias : [];

  const descontadas =
    averiasArray.length > 0 && averiasArray.every((averia) => averia.descontada) ? (
      <Text style={styles.badgeSuccess}>Descontada</Text>
    ) : (
      <Text style={styles.badgeError}>Sin Descontar</Text>
    );

  const renderTableHeader = () => (
    <View style={styles.tableRow}>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Producto</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Referencia</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Descripción</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Cantidad</Text>
      </View>
    </View>
  );

  const renderTableRows = (items) => {
    return items.map((item, index) => (
      <View style={styles.tableRow} key={index}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.producto.nombre}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.producto.referencia}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.producto.descripcion}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{item.cantidad}</Text>
        </View>
      </View>
    ));
  };

  return (
    <>
      {data ? (
        <Document title={`Reporte de fecha ${data.fecha}`}>
          <Page style={styles.body} wrap>
            {/* Membrete */}
            <View style={styles.headerRow}>
              <Text style={styles.title}>Reporte Averias</Text>
              <Image style={styles.image} src={logo} />
            </View>

            {/* Detalles */}
            <View style={styles.detailsRow}>
              <Text style={styles.detailsCol}>Código: </Text>
              <Text style={styles.detailsCol}>Versión: </Text>
              <Text style={styles.detailsCol}>Fecha: {data.fecha}</Text>
              <Text style={styles.detailsCol}>Página: 1</Text>
            </View>

            {/* Información principal */}
            <View style={styles.contentContainer}>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Fecha: </Text>
                  <Text style={styles.fieldValue}>{data.fecha}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Tipo: </Text>
                  <Text style={styles.fieldValue}>
                    {data.produccion ? "Producción" : "Salida Producto Terminado"}
                  </Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Remisión: </Text>
                  <Text style={styles.fieldValue}>
                    {data.produccion
                      ? data.produccion.remision
                      : data.salida_envasado
                      ? data.salida_envasado.remision
                      : "N/A"}
                  </Text>
                </View>
              </View>
            </View>

            {/* Tabla de Averías */}
            <View style={styles.table}>
              {renderTableHeader()}
              {averiasArray.length > 0 ? (
                renderTableRows(averiasArray)
              ) : (
                <View style={styles.tableRow}>
                  <Text style={styles.tableCell}>No hay averías registradas.</Text>
                </View>
              )}
            </View>

            {/* Estado de Descuento */}
            <View style={styles.contentContainer}>
              <View style={styles.contentRow}>
                <Text style={styles.fieldLabel}>Estado: </Text>
                {descontadas}
              </View>
            </View>

            {/* Pie de página */}
            <Text style={styles.footer}>
              Reporte generado el {formattedDate}
            </Text>
          </Page>
        </Document>
      ) : (
        "No se pudieron obtener los datos del cliente"
      )}
    </>
  );
}

export default MyDocument;
