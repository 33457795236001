import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { IconFilter, IconSearch } from "@tabler/icons-react";
import {
  get_inventario_full_list,
  get_inventario_full_list_page,
} from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import InventarioFullList from "../../../components/P03/inventariofull/InventarioFullList";

function InventarioFullP03({
  get_inventario_full_list,
  get_inventario_full_list_page,
  inventario,
  count,
}) {
  const [fecha, setFecha] = useState("");

  useEffect(() => {
    if (fecha !== "" && fecha !== null) {
      get_inventario_full_list(fecha);
    } else {
      get_inventario_full_list();
    }
  }, [fecha, get_inventario_full_list]);

  const handleChangeFecha = (e) => {
    setFecha(e.target.value);
  };

  const limpiarFecha = () => {
    fecha && setFecha("");
  };

  const [term, setTerm] = useState("");
  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(
      () => (window.location.href = "/inventario-full-p03/" + term),
      0.2
    );
    setTerm("");
  };
  return (
    <DashboardLayout>
      <div className="border-b border-gray-200 py-5 sm:px-6 dark:border-gray-700">
        <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-2xl font-medium leading-6 text-gray-900 dark:text-white">
              Cambios en el Inventario
            </h3>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
            <div>
              <div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
                <form onSubmit={(e) => onSubmit(e)} className="w-full">
                  <label htmlFor="search" className="sr-only">
                    Buscar
                  </label>
                  <div className="relative">
                    <button
                      type="submit"
                      className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
                    >
                      <IconSearch stroke={"#0096C4"} />
                    </button>
                    <input
                      id="search"
                      name="search"
                      required
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className="block w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:text-gray-900 dark:focus:text-gray-100 focus:ring-1 focus:ring-indigo-500 dark:focus:ring-indigo-400 focus:border-indigo-500 dark:focus:border-indigo-400 sm:text-sm"
                      placeholder="Buscar"
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="dropdown dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="p-1.5 bg-white border border-[#0096C4] rounded dark:bg-gray-700 dark:border-gray-600"
              >
                <IconFilter stroke="#0096C4" />
              </div>
              <div
                tabIndex={0}
                className="dropdown-content z-[1] menu p-4 shadow bg-base-100 rounded-box w-52 dark:bg-gray-800 dark:text-white"
              >
                <div className="flex flex-col gap-y-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label">
                      <span className="label-text dark:text-gray-300">
                        Filtrar por Fecha
                      </span>
                    </div>
                    <input
                      type="date"
                      className="input input-bordered dark:bg-gray-700"
                      placeholder="Fecha"
                      value={fecha}
                      onChange={handleChangeFecha}
                    />
                  </label>
                  <button
                    onClick={limpiarFecha}
                    className="btn btn-primary mt-2"
                  >
                    Limpiar Filtro
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InventarioFullList
        inventario={inventario}
        get_inventario_full_list_page={get_inventario_full_list_page}
        count={count}
      />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  inventario: state.inventarioFull.inventarios_full,
  count: state.inventarioFull.count,
});

export default connect(mapStateToProps, {
  get_inventario_full_list,
  get_inventario_full_list_page,
})(InventarioFullP03);
