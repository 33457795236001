import { useParams } from "react-router-dom";
import { get_salida_insumos_reporte_mensual } from "../../../redux/actions/P03/p03";
import { PDFViewer } from "@react-pdf/renderer";
import { connect } from "react-redux";
import { useEffect } from "react";
import MyDocument from "../../../components/pdf/P03/entradas/ReportEntradaMensual";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";

function SalidaInsumosReportePdfMensual({get_salida_insumos_reporte_mensual, salida_insumos}){
  const { term, term2, term3 } = useParams();

  useEffect(() => {
		get_salida_insumos_reporte_mensual(term, term2, term3);
	}
	, [get_salida_insumos_reporte_mensual, term, term2, term3]);

  return (
    <DashboardLayout>
      <div className="container mx-auto max-w-5xl">
				{/* {reporte && reporte ? (
					<>
						<div className="">
							<PDFViewer className="w-full h-[75vh] md:h-[85vh]">
								<MyDocument data={reporte} />
							</PDFViewer>
						</div>
					</>
				) : (
					<div className="flex items-center justify-center h-96">
						<div className="w-10 h-10 border-t-2 border-b-2 border-[#0096C4] rounded-full animate-spin"></div>
					</div>
				)} */}
			</div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
	salida_insumos: state.salidaInsumos.reporteMensual,
});

export default connect(mapStateToProps, { get_salida_insumos_reporte_mensual })(SalidaInsumosReportePdfMensual);