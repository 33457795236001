import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { get_referencia } from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { CircleLoader } from "react-spinners";
import axios from "axios";
import Toast from "../../../components/Alert";

function ReferenciasP03Edit({ get_referencia, referencia }) {
  const params = useParams();
  const id = params.uid;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    caja: "",
    envase: "",
    tapa: "",
    etiqueta: "",
    peso: "",
    tipo_aceite: "",
    tipo_referencia: "",
    codigo: "",
  });

  useEffect(() => {
    get_referencia(id);
  }, [get_referencia, id]);

  useEffect(() => {
    if (referencia) {
      setFormData({
        caja: referencia.caja,
        envase: referencia.envase,
        tapa: referencia.tapa,
        etiqueta: referencia.etiqueta,
        peso: referencia.peso,
        tipo_aceite: referencia.tipo_aceite,
        tipo_referencia: referencia.tipo_referencia,
        codigo: referencia.codigo,
      });
    }
  }, [referencia]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateForm = () => {
    const {
      caja,
      envase,
      tapa,
      etiqueta,
      peso,
      tipo_aceite,
      tipo_referencia,
      codigo,
    } = formData;
    if (
      !caja ||
      !envase ||
      !tapa ||
      !etiqueta ||
      !peso ||
      !tipo_aceite ||
      !tipo_referencia ||
      !codigo
    ) {
      Toast({
        tipo: "error",
        mensaje: "Por favor, completa todos los campos antes de enviar.",
      });
      return false;
    }
    return true;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const url = `${process.env.REACT_APP_API_URL}/referencias/update/${id}/`;

      const res = await axios.put(url, formData, config);
      if (res.status === 200) {
        setLoading(false);
        Toast({
          tipo: "success",
          mensaje: "¡Referencia actualizada correctamente!",
        });
        navigate("/referencias-p03");
      }
    } catch (error) {
      setLoading(false);
      Toast({ tipo: "error", mensaje: "Error al actualizar la referencia" });
    }
  };

  const cajas = [
    "Sin Código-200 ML",
    "250311-250 ML",
    "250312-430/450 ML",
    "250313-500 ML",
    "250314-710 ML",
    "Sin Código-800 ML",
    "250315-850 ML",
    "250316-900/1000 ML EQ",
    "250317-900/1000 ML FK",
    "Sin Código-900/1000 ML",
    "250318-2800/3000 ML",
    "250319-3000 ML",
    "250320-5000 ML FK",
    "Sin Código-5000 ML EQ",
    "250321-2000 ML",
    "250322-3000 ML",
    "250323-900/1000 ML Lisa",
    "250324-2000 ML Lisa",
    "250325-5000 ML Lisa",
    "250326-430/450 ML Lisa",
    "124637-1-900 ML Sonelo",
    "124619-1-2000 ML Sonelo",
    "124639-1-3000 ML Sonelo",
  ];

  const envases = [
    "200 ML-Emplanorte",
    "250 ML-Patios/Chocolate",
    "300 ML-AGUA",
    "420 ML-Pescado",
    "430/450 ML-Chocolate",
    "500 ML-Chocolate",
    "600 ML-AGUA",
    "700 GR-Chocolate",
    "710 ML-Chocolate",
    "800 ML-Emplanorte",
    "800 ML-Emplanorte Anillado",
    "850 ML-EQ",
    "850 ML-FK",
    "900 ML-Emplanorte Anillado",
    "900 ML-FK",
    "900 ML-Pescado",
    "900 ML-Chocolate",
    "1000 ML-FK",
    "1000 ML-Pescado",
    "1000 ML-Chocolate",
    "1000 ML-Emplanorte Anillado",
    "2000 ML-Chocolate",
    "2800 ML-Chocolate EQ",
    "2800 ML-Chocolate FK",
    "3000 ML-Chocolate EQ",
    "3000 ML-Chocolate FK",
    "3000 ML-Emplanorte",
    "5000 ML-Chocolate EQ",
    "5000 ML-Chocolate FK",
    "20 litros-Bidones",
  ];

  const tapas = [
    "28 MM-Cuello Corto Blanca",
    "65 MM-Bidones",
    "38 MM-5 Litros",
  ];

  const etiquetas = [
    "500 ML-Arduvi Soya",
    "710 ML-Arduvi Soya",
    "800 ML-Arduvi Soya",
    "850 ML-Arduvi Soya",
    "900 ML-Arduvi Soya",
    "1000 ML-Arduvi Soya",
    "2000 ML-Arduvi Soya",
    "3000 ML-Arduvi Soya",
    "5000 ML-Arduvi Soya",
    "250 ML-Arduvi Palma",
    "450 ML-Arduvi Palma",
    "500 ML-Arduvi Palma",
    "710 ML-Arduvi Palma",
    "850 ML-Arduvi Palma",
    "900 ML-Arduvi Palma",
    "1000 ML-Arduvi Palma",
    "2000 ML-Arduvi Palma",
    "2800 ML-Arduvi Palma",
    "3000 ML-Arduvi Palma",
    "5000 ML-Arduvi Palma",
    "20 litros-Arduvi Palma",
    "3000 ML-Santandereana Palma",
    "1000 ML-Imperial Palma",
    "900 ML-Imperial Soya",
    "1000 ML-Imperial Soya",
    "200 ML-Oliverde Soya",
    "250 ML-Oliverde Soya",
    "430 ML-Oliverde Soya",
    "500 ML-Oliverde Soya",
    "710 ML-Oliverde Soya",
    "760 ML-Oliverde Soya",
    "800 ML-Oliverde Soya",
    "825 ML-Oliverde Soya",
    "850 ML-Oliverde Soya",
    "900 ML-Oliverde Soya",
    "1000 ML-Oliverde Soya",
    "2800 ML-Oliverde Soya",
    "3000 ML-Oliverde Soya",
    "5000 ML-Oliverde Soya",
    "20 litros-Oliverde Soya",
    "430 ML-Arduvi Mezcla",
    "900 ML-Arduvi Mezcla",
    "3000 ML-Arduvi Mezcla",
    "2700 ML-Del Catatumbo Mezcla",
    "900 ML-Molinera Mezcla",
    "3000 ML-Molinera Mezcla",
    "20 litros-El Molinero",
    "710 ML-Oliverde Mezcla",
    "900 ML-Sonelo CC-MA",
    "2000 ML-Sonelo CC-MA",
    "3000 ML-Sonelo CC-MA",
    "900 ML-Sonelo CM-MB",
    "2000 ML-Sonelo CM-MB",
    "3000 ML-Sonelo CM-MB",
    "900 ML-Sonelo CF-MC",
    "2000 ML-Sonelo CF-MC",
    "3000 ML-Sonelo CF-MC",
    "150 ML-Oliverde Soya",
    "2000 ML-Oliverde Soya",
    "830 ML-SabroNorte Soya",
    "500 ML-Norsan Soya",
    "3000 ML-Norsan Soya",
    "250 ML-La Huerta Soya",
    "500 ML-La Huerta Soya",
    "430 ML-PE Mezcla de Aceite Vegetales",
    "900 ML-PE Mezcla de Aceite Vegetales",
    "1000 ML-PE Mezcla de Aceite Vegetales",
    "2800 ML-PE Mezcla de Aceite Vegetales",
    "3000 ML-PE Mezcla de Aceite Vegetales",
    "5000 ML-PE Mezcla de Aceite Vegetales",
    "900 ML-Villa Olivera 900 ML",
    "2000 ML-Villa Olivera 2000 ML",
  ];

  const tapones = ["58 MM-Bidones"];

  if (formData.tapa === "58 MM-Bidones") {
    formData.tipoTapa = "tapon";
  } else {
    formData.tipoTapa = "tapa";
  }
  const onChangeTipoTapa = (e) => {
    setFormData({ ...formData, tipoTapa: e.target.value });
  };
  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg p-6">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white mb-5">
          Editar Referencia
        </h1>
        <form onSubmit={onSubmit} className="space-y-5">
          <div className="font-bold text-sm text-[#00496B] dark:text-gray-300">
            Información de la referencia a editar
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="flex flex-col">
              <label htmlFor="caja" className="label-text dark:text-gray-300">
                Caja
              </label>
              <select
                name="caja"
                id="caja"
                value={formData.caja}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
              >
                <option value="">Selecciona una caja</option>
                {cajas.map((caja, index) => (
                  <option key={index} value={caja}>
                    {caja}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="envase" className="label-text dark:text-gray-300">
                Envase
              </label>
              <select
                name="envase"
                id="envase"
                value={formData.envase}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
              >
                <option value="">Selecciona un envase</option>
                {envases.map((envase, index) => (
                  <option key={index} value={envase}>
                    {envase}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col">
              <label className="label-text dark:text-gray-300">Tipo</label>
              <div className="flex items-center space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="tipoTapa"
                    value="tapa"
                    checked={formData.tipoTapa === "tapa"}
                    onChange={onChangeTipoTapa}
                    required
                    className="mr-2"
                  />
                  <span className="dark:text-gray-300">Tapa</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="tipoTapa"
                    value="tapon"
                    checked={formData.tipoTapa === "tapon"}
                    onChange={onChangeTipoTapa}
                    required
                    className="mr-2"
                  />
                  <span className="dark:text-gray-300">Tapón</span>
                </label>
              </div>
            </div>
            {formData.tipoTapa && (
              <div className="flex flex-col">
                <label htmlFor="tapa" className="label-text dark:text-gray-300">
                  {formData.tipoTapa === "tapa" ? "Tapas" : "Tapones"}
                </label>
                <select
                  name="tapa"
                  id="tapa"
                  value={formData.tapa}
                  onChange={onChange}
                  className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
                >
                  <option value="">
                    {formData.tipoTapa === "tapa"
                      ? "Selecciona una tapa"
                      : "Selecciona un tapón"}
                  </option>
                  {formData.tipoTapa === "tapa"
                    ? tapas.map((tapa, index) => (
                        <option key={index} value={tapa}>
                          {tapa}
                        </option>
                      ))
                    : tapones.map((tapon, index) => (
                        <option key={index} value={tapon}>
                          {tapon}
                        </option>
                      ))}
                </select>
              </div>
            )}

            <div className="flex flex-col">
              <label
                htmlFor="etiqueta"
                className="label-text dark:text-gray-300"
              >
                Etiqueta
              </label>
              <select
                name="etiqueta"
                id="etiqueta"
                value={formData.etiqueta}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
              >
                <option value="">Selecciona una etiqueta</option>
                {etiquetas.map((etiqueta, index) => (
                  <option key={index} value={etiqueta}>
                    {etiqueta}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="peso" className="label-text dark:text-gray-300">
                Peso
              </label>
              <input
                type="number"
                name="peso"
                id="peso"
                value={formData.peso}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="tipo_aceite"
                className="label-text dark:text-gray-300"
              >
                Tipo de Aceite
              </label>
              <select
                name="tipo_aceite"
                id="tipo_aceite"
                value={formData.tipo_aceite}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
              >
                <option value="">Selecciona un tipo de aceite</option>
                <option value="Soya">Soya</option>
                <option value="Palma">Palma</option>
                <option value="Mezcla 70/30 MC">Mezcla 70/30 "MC"</option>
                <option value="Mezcla 60/40 MB">Mezcla 60/40 "MB"</option>
                <option value="Mezcla 95/05 MA">Mezcla 95/05 "MA"</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="tipo_referencia"
                className="label-text dark:text-gray-300"
              >
                Tipo de Referencia
              </label>
              <select
                name="tipo_referencia"
                id="tipo_referencia"
                value={formData.tipo_referencia}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
              >
                <option value="">Selecciona un tipo de referencia</option>
                <option value="FK">FK</option>
                <option value="EQ">EQ</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="codigo" className="label-text dark:text-gray-300">
                Código
              </label>
              <input
                type="text"
                name="codigo"
                id="codigo"
                value={formData.codigo}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>
          </div>
          <div className="flex justify-center col-span-1 md:col-span-2 lg:col-span-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white"
              >
                Actualizar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  referencia: state.referencia.referencia,
});

export default connect(mapStateToProps, { get_referencia })(ReferenciasP03Edit);
