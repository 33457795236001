
import SetPagination from "../../pagination/SetPagination";
import EntradasCardHorizontal from "./EntradasCardHorizontal";

function EntradasListSearch({
  entradas,
  search_entrada_page,
  count,
  search_term,
}) {
  return (
    <div className="overflow-hidden bg-white dark:bg-gray-800">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
          <tr>
              <th scope="col" className="p-2">
                
              </th>
							<th scope="col" className="p-2">
                Fecha
              </th>
              <th scope="col" className="p-2">
              Remision
              </th>
              <th scope="col" className="p-2">
              Descricpión
              </th>
              <th scope="col" className="p-2">
                Producto/Cantidad
              </th>
              <th scope="col" className="p-2">
                  Planta/Proveedor
                </th>
              <th scope="col" className="p-2">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {entradas &&
              entradas.map((entrada, index) => (
                <EntradasCardHorizontal
                  data={entrada}
                  key={index}
                  index={index}
                />
              ))}
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={search_entrada_page}
        list={entradas}
        count={count}
        type={"medium"}
        search_term={search_term}  
      />
    </div>
  );
}
export default EntradasListSearch;
