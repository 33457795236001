import {
  GET_AVERIAS_LIST_SUCCESS,
  GET_AVERIAS_LIST_FAIL,
  GET_AVERIAS_SUCCESS,
  GET_AVERIAS_FAIL
} from "./types"
import axios from "axios"

export const get_averias_list = () => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/averias/list/`,
      config
    );
    dispatch({
      type: GET_AVERIAS_LIST_SUCCESS,
      payload: res.data,
    });
  }
  catch (err) {
    dispatch({
      type: GET_AVERIAS_LIST_FAIL,
    });
  }
}

export const get_averias_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/averias/?p=${p}`,
      config
    );

    dispatch({
      type: GET_AVERIAS_LIST_SUCCESS,
      payload: res.data,
    });
  }
  catch (err) {
    dispatch({
      type: GET_AVERIAS_LIST_FAIL,
    });
  }
}

export const get_averias = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/averias/${id}`,
      config
    );
    dispatch({
      type: GET_AVERIAS_SUCCESS,
      payload: res.data,
    });
  }
  catch (err) {
    dispatch({
      type: GET_AVERIAS_FAIL,
    });
  }
}