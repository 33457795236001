import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider, useSelector } from "react-redux";
import AnimatedRoutes from "./hocs/route/Routes";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import { toast, ToastContainer } from "react-toastify";
import Message from "./components/Message";
import "react-toastify/dist/ReactToastify.css";
import SaveToken from "./components/SaveToken";

function AppContent() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      onMessage(messaging, (payload) => {
        toast(<Message notification={payload.notification} />);
      });
    }
  }, [isAuthenticated]);

  return (
    <>
      {isAuthenticated && <SaveToken />}
      <Router>
        <AnimatedRoutes />
        <ToastContainer />
      </Router>
    </>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Empanorte App</title>
      </Helmet>
      <Provider store={store}>
        <AppContent />
      </Provider>
    </HelmetProvider>
  );
}

export default App;
