import { useParams, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import CircleLoader from "react-spinners/CircleLoader";
import Toast from "../../../components/Alert";
import {
  get_referencia_list,
  get_produccion,
  get_productos_list, // Acción para obtener productos
} from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import {
  IconChevronDown,
  IconEdit,
  IconEye,
  IconTrash,
} from "@tabler/icons-react";

function ProduccionP03Edit({
  get_referencia_list,
  get_produccion,
  get_productos_list, // Añadir get_productos_list
  refer,
  produccion,
  productos, // Añadir productos
}) {
  const params = useParams();
  const id = params.uid;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fecha: "",
    turno: "",
    lotes_aceite: [],
    produccionreferencia_set: [],
    averiasproducto_set: [],
  });

  useEffect(() => {
    get_referencia_list("crear");
    get_produccion(id);
    get_productos_list();
  }, [
    ,
    get_referencia_list,
    get_productos_list,
    get_produccion,
    id,
  ]);

  useEffect(() => {
    if (produccion) {
      const updatedReferences = produccion.produccionreferencia_set.map(
        (ref) => ({
          referencia: ref.referencia.id,
          cantidad: ref.cantidad,
          lote: ref.lote,
          fecha_vencimiento: ref.fecha_vencimiento,
        })
      );

      const updatedAverias = produccion.averiasproducto_set.map((averia) => ({
        producto: averia.producto,
        cantidad: averia.cantidad,
      }));

      setFormData({
        fecha: produccion.fecha,
        turno: produccion.turno,
        lotes_aceite: produccion.lotes_aceite,
        produccionreferencia_set: updatedReferences,
        averiasproducto_set: updatedAverias,
      });
    }
  }, [produccion]);

  const [referencia, setReferencia] = useState({
    referencia: "",
    cantidad: "",
  });

  const [producto, setProducto] = useState({
    producto: "",
    cantidad: "",
  });

  const [query, setQuery] = useState("");
  const [productoQuery, setProductoQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const [productoDropdownOpen, setProductoDropdownOpen] = useState([]);
  const dropdownRefs = useRef([]); // Para referencias
  const productoDropdownRefs = useRef([]); // Para productos

  const handleDropdownToggle = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = !newDropdownOpen[index];
      return newDropdownOpen;
    });
  };

  const handleProductoDropdownToggle = (index) => {
    setProductoDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = !newDropdownOpen[index];
      return newDropdownOpen;
    });
  };

  const handleDropdownClose = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = false;
      return newDropdownOpen;
    });
  };

  const handleProductoDropdownClose = (index) => {
    setProductoDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = false;
      return newDropdownOpen;
    });
  };

  const filteredReferencias = refer
    ? query === ""
      ? refer.filter(
          (refItem) =>
            !formData.produccionreferencia_set
              .map((ref) => ref.referencia)
              .includes(refItem.id)
        )
      : refer.filter(
          (refItem) =>
            !formData.produccionreferencia_set
              .map((ref) => ref.referencia)
              .includes(refItem.id) &&
            `${refItem.codigo} - ${refItem.etiqueta}`
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
    : [];

  const addReferencia = () => {
    setFormData({
      ...formData,
      produccionreferencia_set: [
        ...formData.produccionreferencia_set,
        referencia,
      ],
    });
    setReferencia({
      referencia: "",
      cantidad: "",
      lote: "",
      fecha_vencimiento: "",
    });
    setQuery("");
  };

  const addProducto = () => {
    setFormData({
      ...formData,
      averiasproducto_set: [...formData.averiasproducto_set, producto],
    });
    setProducto({
      producto: "",
      cantidad: "",
    });
    setProductoQuery("");
  };

  const handleReferenciaSelect = (index, refItem) => {
    const newRefs = [...formData.produccionreferencia_set];
    newRefs[index].referencia = refItem.id;
    setFormData({ ...formData, produccionreferencia_set: newRefs });
    handleDropdownClose(index);
  };

  const handleProductoSelect = (index, prodItem) => {
    const newProds = [...formData.averiasproducto_set];
    newProds[index].producto = prodItem.id;
    setFormData({ ...formData, averiasproducto_set: newProds });
    handleProductoDropdownClose(index);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    if (!formData.fecha || !formData.turno) {
      Toast({
        tipo: "error",
        mensaje: "Por favor, complete todos los campos obligatorios.",
      });
      return false;
    }

    if (formData.produccionreferencia_set.length === 0) {
      Toast({
        tipo: "error",
        mensaje: "Debe agregar al menos una referencia con su cantidad.",
      });
      return false;
    }

    for (let i = 0; i < formData.produccionreferencia_set.length; i++) {
      const ref = formData.produccionreferencia_set[i];
      if (
        !ref.referencia ||
        !ref.cantidad ||
        !ref.lote ||
        !ref.fecha_vencimiento
      ) {
        Toast({
          tipo: "error",
          mensaje:
            "Cada referencia debe tener una cantidad, lote y fecha de vencimiento.",
        });
        return false;
      }
    }

    for (let i = 0; i < formData.averiasproducto_set.length; i++) {
      const prod = formData.averiasproducto_set[i];
      if (!prod.producto || !prod.cantidad) {
        Toast({
          tipo: "error",
          mensaje: "Cada avería debe tener una cantidad.",
        });
        return false;
      }
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/produccion/update/${id}/`,
        formData,
        config
      );

      if (res.status === 200) {
        Toast({
          tipo: "success",
          mensaje: "¡Producción actualizada correctamente!",
        });
        navigate("/produccion-p03");
      }
    } catch (error) {
      console.error(error);
      Toast({
        tipo: "error",
        mensaje: "Ocurrió un error al actualizar la producción.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Asegurarse de que dropdownRefs.current es un array antes de usar forEach
      if (Array.isArray(dropdownRefs.current)) {
        dropdownRefs.current.forEach((ref, index) => {
          if (ref && !ref.contains(event.target)) {
            handleDropdownClose(index);
          }
        });
      }

      // Asegurarse de que productoDropdownRefs.current es un array antes de usar forEach
      if (Array.isArray(productoDropdownRefs.current)) {
        productoDropdownRefs.current.forEach((ref, index) => {
          if (ref && !ref.contains(event.target)) {
            handleProductoDropdownClose(index);
          }
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addLoteAceite = () => {
    const lotesAceiteActualizados = Array.isArray(formData.lotes_aceite)
      ? formData.lotes_aceite
      : [];

    setFormData({
      ...formData,
      lotes_aceite: [
        ...lotesAceiteActualizados,
        { tipo: "", lote: "", fecha_vencimiento: "" }, // Añadir un nuevo lote vacío
      ],
    });
  };

  const handleLoteAceiteChange = (index, field, value) => {
    const updatedLotes = [...formData.lotes_aceite];
    updatedLotes[index][field] = value;
    setFormData({ ...formData, lotes_aceite: updatedLotes });
  };
  const removeLoteAceite = (index) => {
    const updatedLotes = formData.lotes_aceite.filter((_, i) => i !== index);
    setFormData({ ...formData, lotes_aceite: updatedLotes });
  };
  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Editar Producción.
        </h1>

        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Información de la Producción.
          </div>

          <div className="border border-gray-300 dark:border-gray-600 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="fecha" className="label">
                <span className="label-text dark:text-white">Fecha</span>
              </label>
              <input
                type="date"
                name="fecha"
                value={formData.fecha}
                onChange={onChange}
                required
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                disabled
              />
            </div>

            <div className="form-control">
              <label htmlFor="turno" className="label">
                <span className="label-text dark:text-white">Turno</span>
              </label>
              <select
                name="turno"
                value={formData.turno}
                onChange={onChange}
                required
                className="select select-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Seleccionar Turno</option>
                <option value="A">Día</option>
                <option value="B">Noche</option>
              </select>
            </div>

            
          </div>

          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Referencias
          </div>

          <div className="border border-gray-300 dark:border-gray-700 rounded-lg p-3">
            {formData.produccionreferencia_set.map((ref, index) => {
              const referenciaSeleccionada =
                refer && refer.find((r) => r.id === ref.referencia);
              const productosDisponibles =
                referenciaSeleccionada?.productos || [];
              let productoCorrugado,
                productoEnvase,
                productoTapas,
                productoEtiqueta;
              let unidades_por_caja = 0;
              productosDisponibles.forEach((producto) => {
                if (producto.nombre === "Corrugado") {
                  productoCorrugado = producto;
                } else if (producto.nombre === "Envase") {
                  productoEnvase = producto;
                } else if (producto.nombre === "Tapas") {
                  productoTapas = producto;
                } else if (producto.nombre === "Etiqueta") {
                  productoEtiqueta = producto;
                }
              });

              if (referenciaSeleccionada && referenciaSeleccionada.envase) {
                const envase_size = parseInt(
                  referenciaSeleccionada.envase.split()[0],
                  10
                );
                if ([200, 250, 430, 450, 500].includes(envase_size)) {
                  unidades_por_caja = 24;
                } else if (
                  [700, 710, 800, 850, 900, 1000].includes(envase_size)
                ) {
                  unidades_por_caja = 12;
                } else if ([2000, 2800, 3000].includes(envase_size)) {
                  unidades_por_caja = 6;
                } else if (envase_size === 5000) {
                  unidades_por_caja = 4;
                } else {
                  console.error("Tamaño de envase no reconocido:", envase_size);
                  return (
                    <div key={index} className="text-red-500">
                      Error: Tamaño de envase no reconocido.
                    </div>
                  );
                }
              }
              const maxCantidadCorrugado =
                productoCorrugado?.inventario_data?.inventario || 0;
              const maxCantidadEnvase = Math.floor(
                (productoEnvase?.inventario_data?.inventario || 0) /
                  unidades_por_caja
              );
              const maxCantidadTapas = Math.floor(
                (productoTapas?.inventario_data?.inventario || 0) /
                  unidades_por_caja
              );
              const maxCantidadEtiqueta = Math.floor(
                (productoEtiqueta?.inventario_data?.inventario || 0) /
                  unidades_por_caja
              );
              const maxCantidadTotalCajas = Math.min(
                maxCantidadCorrugado,
                maxCantidadEnvase,
                maxCantidadTapas,
                maxCantidadEtiqueta
              );

              if (maxCantidadTotalCajas === 0) {
                console.warn(
                  "No se pueden crear cajas, el inventario de algún producto es insuficiente."
                );
              }

              return (
                <div key={index} className="mb-3">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                    <div className="form-control">
                      <label htmlFor="referencia" className="label">
                        <span className="label-text dark:text-gray-300">
                          Referencia
                        </span>
                      </label>
                      <div
                        className="relative"
                        ref={(el) => (dropdownRefs.current[index] = el)}
                      >
                        <button
                          type="button"
                          onClick={() => handleDropdownToggle(index)}
                          className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 dark:text-white bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <span className="mr-2">
                            {ref.referencia && refer
                              ? `${referenciaSeleccionada?.codigo} - ${referenciaSeleccionada?.etiqueta}`
                              : "Seleccionar Referencia"}
                          </span>
                          <IconChevronDown />
                        </button>
                        {dropdownOpen[index] && (
                          <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                            <input
                              id="search-input"
                              className="input input-bordered w-full dark:bg-gray-700 dark:text-white"
                              type="text"
                              placeholder="Buscar referencias"
                              value={query}
                              onChange={(e) => setQuery(e.target.value)}
                            />
                            <div className="max-h-60 overflow-y-auto">
                              {filteredReferencias.length === 0 ? (
                                <div className="px-4 py-2 text-gray-700 dark:text-gray-300">
                                  No se encontraron referencias
                                </div>
                              ) : (
                                filteredReferencias
                                  .slice(0, 10)
                                  .map((refItem) => (
                                    <div
                                      key={refItem.id}
                                      onClick={() =>
                                        handleReferenciaSelect(index, refItem)
                                      }
                                      className="cursor-pointer px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                                    >
                                      {`${refItem.codigo} - ${refItem.etiqueta}`}
                                    </div>
                                  ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-control">
                      <label htmlFor="cantidad" className="label">
                        <span className="label-text dark:text-gray-300">
                          Cantidad de Cajas
                        </span>
                      </label>
                      <input
  type="number"
  name="cantidad"
  min="0"
  max={maxCantidadTotalCajas}
  value={ref.cantidad === 0 ? "" : ref.cantidad}
  onChange={(e) => {
    const newRefs = [...formData.produccionreferencia_set];
    const cantidadCajasIngresadas = 
      e.target.value === "" ? "" : parseInt(e.target.value, 10);

    if (e.target.value === "") {
      newRefs[index].cantidad = "";
    } else if (cantidadCajasIngresadas <= maxCantidadTotalCajas && cantidadCajasIngresadas >= 0) {
      newRefs[index].cantidad = cantidadCajasIngresadas;
    } else {
      Toast({
        tipo: "error",
        mensaje: `La cantidad ingresada excede el máximo posible (${maxCantidadTotalCajas} cajas).`,
      });
    }
    setFormData({
      ...formData,
      produccionreferencia_set: newRefs,
    });
  }}
  className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
/>

                    </div>

                    <div className="form-control">
                      <label htmlFor="lote" className="label">
                        <span className="label-text dark:text-gray-300">
                          Lote
                        </span>
                      </label>
                      <input
                        type="text"
                        name="lote"
                        value={ref.lote}
                        onChange={(e) => {
                          const newRefs = [
                            ...formData.produccionreferencia_set,
                          ];
                          newRefs[index].lote = e.target.value;
                          setFormData({
                            ...formData,
                            produccionreferencia_set: newRefs,
                          });
                        }}
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />
                    </div>
                    <div className="form-control">
                      <label htmlFor="fecha_vencimiento" className="label">
                        <span className="label-text dark:text-gray-300">
                          Fecha de Vencimiento
                        </span>
                      </label>
                      <input
                        type="date"
                        name="fecha_vencimiento"
                        value={ref.fecha_vencimiento}
                        onChange={(e) => {
                          const newRefs = [
                            ...formData.produccionreferencia_set,
                          ];
                          newRefs[index].fecha_vencimiento = e.target.value;
                          setFormData({
                            ...formData,
                            produccionreferencia_set: newRefs,
                          });
                        }}
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />
                    </div>

                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                      {productosDisponibles.length > 0 ? (
                        productosDisponibles.map((producto) => (
                          <div key={producto.id} className="mb-1">
                            <strong>{producto.nombre}:</strong> Inventario
                            disponible:{" "}
                            {producto.inventario_data?.inventario || 0}
                          </div>
                        ))
                      ) : (
                        <span className="text-gray-500 dark:text-gray-400">
                          Selecciona una referencia
                        </span>
                      )}
                      {productosDisponibles.length > 0 && (
                        <div>
                          <strong>
                            Máximo de cajas posibles para esta referencia:
                          </strong>{" "}
                          {maxCantidadTotalCajas}
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col items-center">
                      <label htmlFor="eliminar" className="label">
                        <span className="label-text dark:text-gray-300">
                          Eliminar
                        </span>
                      </label>
                      <button
                        type="button"
                        onClick={() => {
                          const newRefs = [
                            ...formData.produccionreferencia_set,
                          ];
                          newRefs.splice(index, 1);
                          setFormData({
                            ...formData,
                            produccionreferencia_set: newRefs,
                          });
                        }}
                        className="p-1 text-white bg-red-500 rounded-full"
                      >
                        <IconTrash />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            {refer &&
              refer.length > formData.produccionreferencia_set.length && (
                <button
                  type="button"
                  onClick={addReferencia}
                  className="btn border bg-blue-500 text-white dark:bg-blue-600"
                >
                  Añadir Referencia
                </button>
              )}
          </div>
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Lotes de Aceite
            <div className="border border-gray-300 dark:border-gray-700 rounded-lg p-3">
              <button
                type="button"
                onClick={addLoteAceite}
                className="btn border bg-blue-500 text-white dark:bg-blue-700 mb-4"
              >
                Añadir Lote de Aceite
              </button>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {Array.isArray(formData.lotes_aceite) ? (
                  formData.lotes_aceite.map((lote, index) => (
                    <div key={index} className="col-span-1 form-control">
                      <label
                        htmlFor={`tipo_aceite_${index + 1}`}
                        className="label"
                      >
                        <span className="label-text dark:text-white">
                          Tipo de Aceite #{index + 1}
                        </span>
                      </label>
                      <select
                        value={lote.tipo}
                        name={`tipo_aceite_${index + 1}`}
                        onChange={(e) =>
                          handleLoteAceiteChange(index, "tipo", e.target.value)
                        }
                        className="select select-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      >
                        <option value="">Seleccionar Tipo de Aceite</option>
                        <option value="soya">Soya</option>
                        <option value="palma">Palma</option>
                        <option value="mezcla">Mezcla</option>
                      </select>
                      <label
                        htmlFor={`lote_aceite_${index + 1}`}
                        className="label"
                      >
                        <span className="label-text text-gray-700 dark:text-gray-300">
                          Lote de Aceite
                        </span>
                      </label>
                      <input
                        type="text"
                        value={lote.lote}
                        name={`lote_aceite_${index + 1}`}
                        onChange={(e) =>
                          handleLoteAceiteChange(index, "lote", e.target.value)
                        }
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />

                      <label
                        htmlFor={`fecha_aceite_${index + 1}`}
                        className="label"
                      >
                        <span className="label-text text-gray-700 dark:text-gray-300">
                          Fecha de Vencimiento
                        </span>
                      </label>
                      <input
                        type="date"
                        value={lote.fecha_vencimiento}
                        name={`fecha_aceite_${index + 1}`}
                        onChange={(e) =>
                          handleLoteAceiteChange(
                            index,
                            "fecha_vencimiento",
                            e.target.value
                          )
                        }
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />

                      <button
                        type="button"
                        className="btn bg-red-500 text-white mt-2"
                        onClick={() => removeLoteAceite(index)}
                      >
                        Eliminar Lote #{index + 1}
                      </button>
                    </div>
                  ))
                ) : (
                  <div>No hay lotes de aceite</div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-[#4DD0E1]">
            Averías
          </div>
          <div className="border border-gray-300 dark:border-gray-600 rounded-lg p-3">
            {formData.averiasproducto_set &&
              formData.averiasproducto_set.map((prod, index) => {
                const productoSeleccionado = productos
                  ? productos.find((p) => p.id === prod.producto)
                  : null;

                const filteredProductos = productos
                  ? productos.filter(
                      (producto) =>
                        !formData.averiasproducto_set
                          .map((prod) => prod.producto)
                          .includes(producto.id) &&
                        producto.inventario_data?.inventario > 0
                    )
                  : [];
                return (
                  <div key={index} className="mb-3">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                      <div className="form-control">
                        <label htmlFor="producto" className="label">
                          <span className="label-text dark:text-white">
                            Producto
                          </span>
                        </label>
                        <div
                          className="relative"
                          ref={(el) =>
                            (productoDropdownRefs.current[index] = el)
                          }
                        >
                          <div className="inline-flex items-center justify-between w-full">
                            <button
                              type="button"
                              onClick={() =>
                                handleProductoDropdownToggle(index)
                              }
                              className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                              <span className="mr-2">
                                {prod.producto
                                  ? `${productoSeleccionado?.nombre} - ${productoSeleccionado?.referencia}`
                                  : "Seleccionar Producto"}
                              </span>
                              <IconEye className="ml-2" size={20} />
                            </button>
                          </div>
                          {productoDropdownOpen[index] && (
                            <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                              <input
                                id="search-input"
                                className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
                                type="text"
                                placeholder="Buscar productos"
                                value={productoQuery}
                                onChange={(e) =>
                                  setProductoQuery(e.target.value)
                                }
                              />
                              <div className="max-h-60 overflow-y-auto">
                                {filteredProductos.length === 0 ? (
                                  <div className="px-4 py-2 text-gray-700 dark:text-gray-300">
                                    No se encontraron productos
                                  </div>
                                ) : (
                                  filteredProductos.map((prodItem) => (
                                    <div
                                      key={prodItem.id}
                                      onClick={() =>
                                        handleProductoSelect(index, prodItem)
                                      }
                                      className="cursor-pointer px-4 py-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600"
                                    >
                                      {prodItem.nombre} - {prodItem.referencia}{" "}
                                      - {prodItem.descripcion}
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-control">
                        <label htmlFor="cantidad" className="label">
                          <span className="label-text dark:text-white">
                            Cantidad
                          </span>
                        </label>
                        <input
  type="number"
  name="cantidad"
  value={prod.cantidad === 0 ? "" : prod.cantidad}
  onChange={(e) => {
    const newProds = [...formData.averiasproducto_set];
    const cantidadIngresada = 
      e.target.value === "" ? "" : parseInt(e.target.value, 10);
    const inventarioDisponible = 
      productoSeleccionado?.inventario_data?.inventario || 0;

    if (e.target.value === "") {
      newProds[index].cantidad = "";
    } else if (cantidadIngresada <= inventarioDisponible && cantidadIngresada >= 0) {
      newProds[index].cantidad = cantidadIngresada;
    } else {
      Toast({
        tipo: "error",
        mensaje: `La cantidad ingresada excede el inventario disponible (${inventarioDisponible}).`,
      });
    }
    setFormData({
      ...formData,
      averiasproducto_set: newProds,
    });
  }}
  max={productoSeleccionado?.inventario_data?.inventario || 0}
  required
  className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
/>


                        {productoSeleccionado?.inventario_data && (
                          <span className="text-sm text-gray-600 dark:text-gray-300">
                            Cantidad en inventario:{" "}
                            {productoSeleccionado.inventario_data.inventario}
                          </span>
                        )}
                      </div>

                      <div className="flex items-center justify-start space-x-2">
                        <div className="flex flex-col items-center">
                          <label htmlFor="eliminar" className="label">
                            <span className="label-text dark:text-white">
                              Eliminar
                            </span>
                          </label>
                          <button
                            type="button"
                            onClick={() => {
                              const newProds = [
                                ...formData.averiasproducto_set,
                              ];
                              newProds.splice(index, 1);
                              setFormData({
                                ...formData,
                                averiasproducto_set: newProds,
                              });
                            }}
                            className="p-2 bg-red-500 text-white rounded-md"
                          >
                            <IconTrash size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <button
              type="button"
              onClick={addProducto}
              className="btn border bg-blue-500 text-white dark:bg-blue-700"
            >
              Añadir Averia
            </button>
          </div>

          <div className="flex justify-center col-span-1 md:col-span-2 lg:grid-cols-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white dark:bg-red-700">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white dark:bg-red-700"
              >
                Guardar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  refer: state.referencia.referencias,
  produccion: state.produccion.produccion,
  productos: state.productos.productos,
});

export default connect(mapStateToProps, {
  get_referencia_list,
  get_productos_list,
  get_produccion,
})(ProduccionP03Edit);
