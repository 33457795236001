import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { get_saldo_planta_list, get_saldo_planta_list_page } from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { IconFilter } from "@tabler/icons-react";
import SaldoPlantaList from "../../../components/P03/saldoPlanta/SaldoPlantaList";
function SaldoPlantaP03({get_saldo_planta_list,saldos,count}) {

  useEffect(() => {
    get_saldo_planta_list();
  }
  , [get_saldo_planta_list]);

  const [term, setTerm] = useState("");
  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => (window.location.href = "/saldos-planta-p03/" + term), 0.2);
    setTerm("");
  };


  return (
<DashboardLayout>
  <div className="border-b border-gray-200 py-5 sm:px-6 dark:border-gray-700">
    <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
      <div className="">
        <h3 className="text-2xl font-medium leading-6 text-gray-900 dark:text-white">
          Saldos de Producción Disponibles Planta 03.
        </h3>
      </div>
      <div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
        <div className="">
          <div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
            <form onSubmit={(e) => onSubmit(e)} className="w-full">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <button
                  type="submit"
                  className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-500 dark:text-gray-300"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </button>
                <input
                  id="search"
                  name="search"
                  required
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Buscar"
                  type="search"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="dropdown dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="p-1.5 bg-white border border-[#0096C4] rounded dark:bg-gray-700 dark:border-gray-600"
          >
            <IconFilter stroke="#0096C4" />
          </div>
          <div
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 dark:bg-gray-800 dark:text-white"
          >
            <div className="flex flex-col gap-y-2">
              <label className="form-control w-full max-w-xs">
                <div className="label">
                  <span className="label-text dark:text-gray-300">Planta</span>
                </div>
              </label>
              <label className="form-control w-full max-w-xs">
                <div className="label">
                  <span className="label-text dark:text-gray-300">Reporte Mensual</span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <SaldoPlantaList
      saldos={saldos}
      get_saldo_planta_list_page={get_saldo_planta_list_page}
      count={count}
    />
</DashboardLayout>

  );
}

const mapStateToProps = (state) => ({
  saldos: state.saldoPlanta.saldos_planta,
  count : state.saldoPlanta.count
});

export default connect(mapStateToProps, { get_saldo_planta_list })(SaldoPlantaP03);
