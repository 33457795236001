import SetPagination from "../pagination/SetPagination";
import HistorialCardHorizontal from "./HistorialCardHorizontal";


function HistorialList({
  historial,
  count,
  refreshData,
  get_historial_list,
  get_historial_list_page,
}) {
  
  return (
    <div className="overflow-hidden bg-white dark:bg-gray-800">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2 dark:text-white"></th>
              <th scope="col" className="p-2 dark:text-white">Fecha</th>
              <th scope="col" className="p-2 dark:text-white">Tipo</th>
              <th scope="col" className="p-2 dark:text-white">Producto</th>
              <th scope="col" className="p-2 dark:text-white">Cantidad</th>
              <th scope="col" className="p-2 dark:text-white">Encargado</th>
              <th scope="col" className="p-2 dark:text-white">Planta</th>
              <th scope="col" className="p-2 dark:text-white">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              historial && historial.length > 0 ? (
                historial.map((historial, index) => (
                  <HistorialCardHorizontal
                    data={historial}
                    key={index}
                    index={index}
                    refreshData={refreshData}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center p-4">
                    No hay registros disponibles.
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
      <SetPagination
        list_page={get_historial_list_page}
        list={get_historial_list}
        count={count}
        type={"medium"}
      />
    </div>
  );
}

export default HistorialList;
