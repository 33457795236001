import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { IconFilter, IconSearch } from "@tabler/icons-react";
import {
  get_combinacion_mezclas_search,
  get_combinacion_mezclas_search_page,
} from "../../../redux/actions/P03/p03";
import { connect, useSelector } from "react-redux";

import MezclasList from "../../../components/P03/mezclas/MezclasList";
import { Link, useParams } from "react-router-dom";

function MezclasSearchP03({
  get_combinacion_mezclas_search,
  get_combinacion_mezclas_search_page,
  mezclas,
  count,
}) {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [tipoaceite, setTipoaceite] = useState("");
  const params = useParams();
  const term_params = params.term;
  const handleChangeAceite = (e) => {
    setTipoaceite(e.target.value);
  };
  useEffect(() => {
    setLoading(true);
    get_combinacion_mezclas_search(term_params).finally(() => setLoading(false));
  }, [term_params, get_combinacion_mezclas_search]);

  const [term, setTerm] = useState("");
  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(
      () => (window.location.href = "/combinaciones-mezclas-p03/" + term),
      0.2
    );
    setTerm("");
  };
  const refreshData = () => {
    get_combinacion_mezclas_search();
  };
  return (
    <DashboardLayout>
      <div className="border-b border-gray-200 py-5 sm:px-6 dark:border-gray-700">
        <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-2xl font-medium leading-6 text-gray-900 dark:text-white">
              Mezclas de Aceite Planta 03.
            </h3>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
            {(user && user.role === "COORDINADOR_PRODUCCION_ENVASADO") ||
            (user && user.role === "COORDINADOR_PLANTA_ENVASADO") ? (
              <div className="flex justify-between gap-4">
                <Link to="/combinaciones-mezclas-p03/form">
                  <button className="bg-[#DC2917] text-white px-4 py-1.5 rounded hover:bg-red-800">
                    Agregar Mezcla
                  </button>
                </Link>
              </div>
            ) : null}
            <div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
              <form onSubmit={(e) => onSubmit(e)} className="w-full">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <button
                    type="submit"
                    className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
                  >
                    <IconSearch stroke={"#0096C4"} />
                  </button>
                  <input
                    id="search"
                    name="search"
                    required
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Buscar"
                    type="search"
                  />
                </div>
              </form>
            </div>
            <div className="dropdown dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="p-1.5 bg-white border border-[#0096C4] rounded dark:bg-gray-700 dark:border-gray-600"
              >
                <IconFilter stroke="#0096C4" />
              </div>
              <div
                tabIndex={0}
                className="dropdown-content z-[1] menu p-4 shadow bg-base-100 rounded-box w-52 dark:bg-gray-800 dark:text-white"
              >
                <div className="flex flex-col gap-y-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label">
                      <span className="label-text dark:text-white">Mezcla</span>
                    </div>
                    <select
                      onChange={handleChangeAceite}
                      value={tipoaceite}
                      className="select select-bordered w-full dark:bg-gray-800 dark:text-white dark:border-gray-600"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Mezcla 70/30 MC">Mezcla 70/30 MC</option>
                      <option value="Mezcla 60/40 MB">Mezcla 60/40 MB</option>
                      <option value="Mezcla 95/05 MA">Mezcla 95/05 MA</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MezclasList
        mezclas={mezclas}
        get_combinacion_mezclas_search_page={get_combinacion_mezclas_search_page}
        count={count}
        refreshData={refreshData}
        user={user}
      />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  mezclas: state.combinacionMezclas.combinacion_mezclas_filtrada,
  count: state.combinacionMezclas.count,
});

export default connect(mapStateToProps, {
  get_combinacion_mezclas_search,
  get_combinacion_mezclas_search_page,
})(MezclasSearchP03);
