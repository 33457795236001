import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircleLoader from "react-spinners/CircleLoader";
import Toast from "../../../components/Alert";
import {
  get_referencia_list,
  get_productos_list,
} from "../../../redux/actions/P03/p03";
import { connect } from "react-redux";
import { IconChevronDown, IconEye, IconTrash } from "@tabler/icons-react";

function ProduccionP03Form({
  get_referencia_list,
  refer,
  get_productos_list,
  productos,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    fecha: "",
    turno: "",
    lotes_aceite: [],
    fecha_vencimiento: "",
    produccionreferencia_set: [],
    averiasproducto_set: [],
  });

  const [query, setQuery] = useState("");
  const [productoQuery, setProductoQuery] = useState("");

  const [dropdownOpen, setDropdownOpen] = useState([]);
  const [productoDropdownOpen, setProductoDropdownOpen] = useState([]);

  const dropdownRefs = useRef([]);
  const productoDropdownRefs = useRef([]);

  useEffect(() => {
    get_referencia_list("crear");
    get_productos_list();
  }, [get_referencia_list, get_productos_list]);

  const handleDropdownToggle = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = !newDropdownOpen[index];
      return newDropdownOpen;
    });
  };

  const handleDropdownClose = (index) => {
    setDropdownOpen((prevState) => {
      const newDropdownOpen = [...prevState];
      newDropdownOpen[index] = false;
      return newDropdownOpen;
    });
  };

  const handleProductoDropdownToggle = (index) => {
    setProductoDropdownOpen((prevState) => {
      const newProductoDropdownOpen = [...prevState];
      newProductoDropdownOpen[index] = !newProductoDropdownOpen[index];
      return newProductoDropdownOpen;
    });
  };

  const handleProductoDropdownClose = (index) => {
    setProductoDropdownOpen((prevState) => {
      const newProductoDropdownOpen = [...prevState];
      newProductoDropdownOpen[index] = false;
      return newProductoDropdownOpen;
    });
  };

  const handleClickOutside = (event) => {
    dropdownRefs.current.forEach((ref, index) => {
      if (ref && !ref.contains(event.target)) {
        handleDropdownClose(index);
      }
    });

    productoDropdownRefs.current.forEach((ref, index) => {
      if (ref && !ref.contains(event.target)) {
        handleProductoDropdownClose(index);
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredReferencias = refer
    ? query === ""
      ? refer.filter(
          (refItem) =>
            !formData.produccionreferencia_set
              .map((ref) => ref.referencia)
              .includes(refItem.id)
        )
      : refer.filter(
          (refItem) =>
            !formData.produccionreferencia_set
              .map((ref) => ref.referencia)
              .includes(refItem.id) &&
            `${refItem.codigo} - ${refItem.etiqueta}`
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
    : [];

  const addReferencia = () => {
    setFormData({
      ...formData,
      produccionreferencia_set: [
        ...formData.produccionreferencia_set,
        { referencia: "", cantidad: "", lote: "", fecha_vencimiento: "" },
      ],
    });
    setQuery("");
  };

  const addProducto = () => {
    setFormData({
      ...formData,
      averiasproducto_set: [
        ...formData.averiasproducto_set,
        { producto: "", cantidad: "" },
      ],
    });
    setProductoQuery("");
  };

  const handleReferenciaSelect = (index, refItem) => {
    const newRefs = [...formData.produccionreferencia_set];
    newRefs[index].referencia = refItem.id;
    setFormData({ ...formData, produccionreferencia_set: newRefs });
    handleDropdownClose(index);
  };

  const handleProductoSelect = (index, prodItem) => {
    const newProds = [...formData.averiasproducto_set];
    const productoYaSeleccionado = formData.averiasproducto_set.some(
      (prod) => prod.producto === prodItem.id
    );

    if (!productoYaSeleccionado) {
      newProds[index].producto = prodItem.id;

      setFormData({
        ...formData,
        averiasproducto_set: newProds,
      });

      handleProductoDropdownClose(index);
      setProductoQuery("");
    } else {
      Toast({
        tipo: "error",
        mensaje: "Este producto ya ha sido seleccionado.",
      });
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    if (!formData.fecha || !formData.turno) {
      Toast({
        tipo: "error",
        mensaje: "Por favor, complete todos los campos obligatorios.",
      });
      return false;
    }

    if (formData.produccionreferencia_set.length === 0) {
      Toast({
        tipo: "error",
        mensaje: "Debe agregar al menos una referencia con su cantidad.",
      });
      return false;
    }

    for (let i = 0; i < formData.produccionreferencia_set.length; i++) {
      const ref = formData.produccionreferencia_set[i];
      if (
        !ref.referencia ||
        !ref.cantidad ||
        !ref.lote ||
        !ref.fecha_vencimiento
      ) {
        Toast({
          tipo: "error",
          mensaje:
            "Cada referencia debe tener una cantidad, lote y fecha de vencimiento.",
        });
        return false;
      }
    }

    if (formData.lote_soya && !formData.fv_soya) {
      Toast({
        tipo: "error",
        mensaje: "Debe ingresar la fecha de vencimiento para el lote de soya.",
      });
      return false;
    }

    if (formData.lote_palma && !formData.fv_palma) {
      Toast({
        tipo: "error",
        mensaje: "Debe ingresar la fecha de vencimiento para el lote de palma.",
      });
      return false;
    }

    if (formData.lote_mezcla && !formData.fv_mezcla) {
      Toast({
        tipo: "error",
        mensaje:
          "Debe ingresar la fecha de vencimiento para el lote de mezcla.",
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    setLoading(true);

    let esFormularioValido = true;

    formData.produccionreferencia_set.forEach((ref, index) => {
      const referenciaSeleccionada = refer.find((r) => r.id === ref.referencia);
      const productosDisponibles = referenciaSeleccionada?.productos || [];

      let maxCantidadCorrugado = Number.MAX_SAFE_INTEGER;
      let maxCantidadEnvase = Number.MAX_SAFE_INTEGER;
      let maxCantidadTapas = Number.MAX_SAFE_INTEGER;
      let maxCantidadEtiqueta = Number.MAX_SAFE_INTEGER;
      let unidades_por_caja = 0;

      if (referenciaSeleccionada) {
        const envase_size = parseInt(
          referenciaSeleccionada.envase.split()[0],
          10
        );
        if ([200, 250, 430, 450, 500].includes(envase_size)) {
          unidades_por_caja = 24;
        } else if ([710, 800, 850, 900, 1000].includes(envase_size)) {
          unidades_por_caja = 12;
        } else if ([2000, 2800, 3000].includes(envase_size)) {
          unidades_por_caja = 6;
        } else if (envase_size === 5000) {
          unidades_por_caja = 4;
        } else {
          unidades_por_caja = 0;
        }
      }

      productosDisponibles.forEach((producto) => {
        if (producto.nombre === "Corrugado") {
          maxCantidadCorrugado = producto.inventario_data?.inventario || 0;
        }

        if (producto.nombre === "Envase") {
          maxCantidadEnvase = Math.floor(
            (producto.inventario_data?.inventario || 0) / unidades_por_caja
          );
        }

        if (producto.nombre === "Tapas") {
          maxCantidadTapas = Math.floor(
            (producto.inventario_data?.inventario || 0) / unidades_por_caja
          );
        }

        if (producto.nombre === "Etiqueta") {
          maxCantidadEtiqueta = Math.floor(
            (producto.inventario_data?.inventario || 0) / unidades_por_caja
          );
        }
      });

      const maxCantidadTotal = Math.min(
        maxCantidadCorrugado,
        maxCantidadEnvase,
        maxCantidadTapas,
        maxCantidadEtiqueta
      );

      if (ref.cantidad_cajas > maxCantidadTotal || ref.cantidad_cajas < 1) {
        esFormularioValido = false;
        Toast({
          tipo: "error",
          mensaje: `La cantidad para la referencia ${referenciaSeleccionada.codigo} es inválida. Debe estar entre 1 y ${maxCantidadTotal}.`,
        });
      }
    });

    if (!esFormularioValido) {
      setLoading(false);
      return;
    }
    const modifiedFormData = {
      ...formData,
      produccionreferencia_set: formData.produccionreferencia_set.map(
        (ref) => ({
          ...ref,
          cantidad: ref.cantidad_cajas,
        })
      ),
    };

    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/produccion/create/`,
        modifiedFormData,
        config
      );

      if (res.status === 201) {
        Toast({
          tipo: "success",
          mensaje: "¡Producción registrada correctamente!",
        });
        navigate("/produccion-p03");
      }
    } catch (error) {
      Toast({
        tipo: "error",
        mensaje: "Ocurrió un error al registrar la producción.",
      });
    } finally {
      setLoading(false);
    }
  };
  const today = new Date().toISOString().split("T")[0];

  const addLoteAceite = () => {
    setFormData({
      ...formData,
      lotes_aceite: [
        ...formData.lotes_aceite,
        { tipo: "", lote: "", fecha_vencimiento: "" },
      ],
    });
  };
  const handleLoteAceiteChange = (index, field, value) => {
    const updatedLotes = [...formData.lotes_aceite];
    updatedLotes[index][field] = value;
    setFormData({ ...formData, lotes_aceite: updatedLotes });
  };
  const removeLoteAceite = (index) => {
    const updatedLotes = formData.lotes_aceite.filter((_, i) => i !== index);
    setFormData({ ...formData, lotes_aceite: updatedLotes });
  };

  return (
    <DashboardLayout>
      <div className="w-full shadow-card rounded-lg dark:text-white">
        <h1 className="font-bold text-3xl text-gray-900 dark:text-white">
          Registro de Producción
        </h1>

        <form onSubmit={(e) => onSubmit(e)} className="">
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Información de la Producción
          </div>

          <div className="border border-gray-300 dark:border-gray-700 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
            <div className="form-control">
              <label htmlFor="fecha" className="label">
                <span className="label-text text-gray-700 dark:text-gray-300">
                  Fecha
                </span>
              </label>
              <input
                type="date"
                name="fecha"
                value={formData.fecha}
                onChange={onChange}
                className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                min={today}
                max={today}
                required
              />
            </div>

            <div className="form-control">
              <label htmlFor="turno" className="label">
                <span className="label-text text-gray-700 dark:text-gray-300">
                  Turno
                </span>
              </label>
              <select
                name="turno"
                value={formData.turno}
                onChange={onChange}
                required
                className="select select-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                <option value="">Seleccionar Turno</option>
                <option value="A">Día</option>
                <option value="B">Noche</option>
              </select>
            </div>
          </div>

          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Referencias
          </div>

          <div className="border border-gray-300 dark:border-gray-700 rounded-lg p-3">
            {formData.produccionreferencia_set.map((ref, index) => {
              const referenciaSeleccionada = refer.find(
                (r) => r.id === ref.referencia
              );
              const productosDisponibles =
                referenciaSeleccionada?.productos || [];

              let productoCorrugado,
                productoEnvase,
                productoTapas,
                productoEtiqueta,
                productoAceite;
              let unidades_por_caja = 0;
              let aceiteCorrecto = null;
              if (productoAceite) {
                if (Array.isArray(productoAceite)) {
                  aceiteCorrecto = productoAceite.reduce((prev, current) => {
                    if (
                      current.porcentaje_utilizado &&
                      prev.porcentaje_utilizado
                    ) {
                      return current.porcentaje_utilizado >
                        prev.porcentaje_utilizado
                        ? current
                        : prev;
                    }
                    return current;
                  });
                } else {
                  aceiteCorrecto = productoAceite;
                }
              }
              productosDisponibles.forEach((producto) => {
                if (producto.nombre === "Corrugado") {
                  productoCorrugado = producto;
                } else if (producto.nombre === "Envase") {
                  productoEnvase = producto;
                } else if (producto.nombre === "Tapas") {
                  productoTapas = producto;
                } else if (producto.nombre === "Etiqueta") {
                  productoEtiqueta = producto;
                } else if (producto.nombre === "Aceite") {
                  aceiteCorrecto = producto;
                }
              });

              if (referenciaSeleccionada && referenciaSeleccionada.envase) {
                const envase_size = parseInt(
                  referenciaSeleccionada.envase.split()[0],
                  10
                );
                console.log(envase_size);
                if ([200, 250, 430, 450, 500].includes(envase_size)) {
                  unidades_por_caja = 24;
                } else if (
                  [700, 710, 800, 850, 900, 1000].includes(envase_size)
                ) {
                  unidades_por_caja = 12;
                } else if ([2000, 2800, 3000].includes(envase_size)) {
                  unidades_por_caja = 6;
                } else if (envase_size === 5000) {
                  unidades_por_caja = 4;
                } else {
                  return (
                    <div key={index} className="text-red-500">
                      Error: Tamaño de envase no reconocido.
                    </div>
                  );
                }
                console.log(unidades_por_caja);
              }

              const maxCantidadCorrugado =
                productoCorrugado?.inventario_data?.inventario || 0;
              const maxCantidadEnvase = Math.floor(
                (productoEnvase?.inventario_data?.inventario || 0) /
                  unidades_por_caja
              );
              const maxCantidadTapas = Math.floor(
                (productoTapas?.inventario_data?.inventario || 0) /
                  unidades_por_caja
              );
              const maxCantidadEtiqueta = Math.floor(
                (productoEtiqueta?.inventario_data?.inventario || 0) /
                  unidades_por_caja
              );

              const maxCantidadAceite = Math.floor(
                (aceiteCorrecto?.inventario_data?.inventario || 0) /
                  unidades_por_caja
              );
              const maxCantidadTotalCajas = Math.min(
                maxCantidadCorrugado,
                maxCantidadEnvase,
                maxCantidadTapas,
                maxCantidadEtiqueta,
                maxCantidadAceite
              );
              if (maxCantidadTotalCajas === 0) {
                console.warn(
                  "No se pueden crear cajas, el inventario de algún producto es insuficiente."
                );
              }

              return (
                <div key={index} className="mb-3">
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                    <div className="form-control">
                      <label htmlFor="referencia" className="label">
                        <span className="label-text dark:text-gray-300">
                          Referencia
                        </span>
                      </label>
                      <div
                        className="relative"
                        ref={(el) => (dropdownRefs.current[index] = el)}
                      >
                        <button
                          type="button"
                          onClick={() => handleDropdownToggle(index)}
                          className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 dark:text-white bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <span className="mr-2">
                            {ref.referencia && refer
                              ? `${referenciaSeleccionada?.codigo} - ${referenciaSeleccionada?.etiqueta} - ${referenciaSeleccionada?.tipo_aceite}`
                              : "Seleccionar Referencia"}
                          </span>
                          <IconChevronDown />
                        </button>
                        {dropdownOpen[index] && (
                          <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                            <input
                              id="search-input"
                              className="input input-bordered w-full dark:bg-gray-700 dark:text-white"
                              type="text"
                              placeholder="Buscar referencias"
                              value={query}
                              onChange={(e) => setQuery(e.target.value)}
                            />
                            <div className="max-h-60 overflow-y-auto">
                              {filteredReferencias.length === 0 ? (
                                <div className="px-4 py-2 text-gray-700 dark:text-gray-300">
                                  No se encontraron referencias
                                </div>
                              ) : (
                                filteredReferencias
                                  .slice(0, 10)
                                  .map((refItem) => (
                                    <div
                                      key={refItem.id}
                                      onClick={() =>
                                        handleReferenciaSelect(index, refItem)
                                      }
                                      className="cursor-pointer px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
                                    >
                                      {`${refItem.codigo} - ${refItem.etiqueta}`}
                                    </div>
                                  ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-control">
                      <label htmlFor="cantidad_cajas" className="label">
                        <span className="label-text dark:text-gray-300">
                          Cantidad de Cajas
                        </span>
                      </label>
                      <input
                        type="number"
                        name="cantidad_cajas"
                        min="0"
                        max={maxCantidadTotalCajas}
                        value={
                          ref.cantidad_cajas === 0 ? "" : ref.cantidad_cajas
                        }
                        onChange={(e) => {
                          const newRefs = [
                            ...formData.produccionreferencia_set,
                          ];
                          const cantidadCajasIngresadas =
                            e.target.value === ""
                              ? ""
                              : parseInt(e.target.value, 10);

                          if (e.target.value === "") {
                            newRefs[index].cantidad = "";
                            newRefs[index].cantidad_cajas = "";
                          } else if (
                            cantidadCajasIngresadas <= maxCantidadTotalCajas
                          ) {
                            newRefs[index].cantidad =
                              cantidadCajasIngresadas * unidades_por_caja;
                            newRefs[index].cantidad_cajas =
                              cantidadCajasIngresadas;
                          } else {
                            Toast({
                              tipo: "error",
                              mensaje: `La cantidad ingresada excede el máximo posible (${maxCantidadTotalCajas} cajas).`,
                            });
                          }
                          setFormData({
                            ...formData,
                            produccionreferencia_set: newRefs,
                          });
                        }}
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />
                    </div>

                    <div className="form-control">
                      <label htmlFor="lote" className="label">
                        <span className="label-text dark:text-gray-300">
                          Lote
                        </span>
                      </label>
                      <input
                        type="text"
                        name="lote"
                        value={ref.lote}
                        onChange={(e) => {
                          const newRefs = [
                            ...formData.produccionreferencia_set,
                          ];
                          newRefs[index].lote = e.target.value;
                          setFormData({
                            ...formData,
                            produccionreferencia_set: newRefs,
                          });
                        }}
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />
                    </div>

                    <div className="form-control">
                      <label htmlFor="fecha_vencimiento" className="label">
                        <span className="label-text dark:text-gray-300">
                          Fecha de Vencimiento
                        </span>
                      </label>
                      <input
                        type="date"
                        name="fecha_vencimiento"
                        value={ref.fecha_vencimiento}
                        onChange={(e) => {
                          const newRefs = [
                            ...formData.produccionreferencia_set,
                          ];
                          newRefs[index].fecha_vencimiento = e.target.value;
                          setFormData({
                            ...formData,
                            produccionreferencia_set: newRefs,
                          });
                        }}
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />
                    </div>

                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                      {productosDisponibles.length > 0 ? (
                        productosDisponibles.map((producto) => (
                          <div key={producto.id} className="mb-1">
                            <strong>
                              {producto.nombre}{" "}
                              {producto.nombre === "Aceite" &&
                              producto.referencia
                                ? `(${producto.referencia})`
                                : ""}
                              :
                            </strong>{" "}
                            Inventario disponible:{" "}
                            {producto.inventario_data?.inventario || 0}
                          </div>
                        ))
                      ) : (
                        <span className="text-gray-500 dark:text-gray-400">
                          Selecciona una referencia
                        </span>
                      )}
                      {productosDisponibles.length > 0 && (
                        <div>
                          <strong>
                            Máximo de cajas posibles para esta referencia:
                          </strong>{" "}
                          {maxCantidadTotalCajas}
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col items-center">
                      <label htmlFor="eliminar" className="label">
                        <span className="label-text dark:text-gray-300">
                          Eliminar
                        </span>
                      </label>
                      <button
                        type="button"
                        onClick={() => {
                          const newRefs = [
                            ...formData.produccionreferencia_set,
                          ];
                          newRefs.splice(index, 1);
                          setFormData({
                            ...formData,
                            produccionreferencia_set: newRefs,
                          });
                        }}
                        className="p-1 text-white bg-red-500 rounded-full"
                      >
                        <IconTrash />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            {refer &&
              refer.length > formData.produccionreferencia_set.length && (
                <button
                  type="button"
                  onClick={addReferencia}
                  className="btn border bg-blue-500 text-white dark:bg-blue-600"
                >
                  Añadir Referencia
                </button>
              )}
          </div>
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Lotes de Aceite
            <div className="border border-gray-300 dark:border-gray-700 rounded-lg p-3">
              <button
                type="button"
                onClick={addLoteAceite}
                className="btn border bg-blue-500 text-white dark:bg-blue-700 mb-4"
              >
                Añadir Lote de Aceite
              </button>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {formData.lotes_aceite.map((lote, index) => (
                  <div key={index} className="col-span-1 form-control">
                    <label
                      htmlFor={`tipo_aceite_${index + 1}`}
                      className="label"
                    >
                      <span className="label-text text-gray-700 dark:text-gray-300">
                        Tipo de Aceite #{index + 1}
                      </span>
                    </label>
                    <select
                      value={lote.tipo}
                      onChange={(e) =>
                        handleLoteAceiteChange(index, "tipo", e.target.value)
                      }
                      name={`tipo_aceite_${index + 1}`}
                      className="select select-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                      <option value="">Selecciona un tipo de aceite</option>
                      <option value="Soya">Soya</option>
                      <option value="Palma">Palma</option>
                      <option value="Mezcla 70/30 MC">Mezcla 70/30 "MC"</option>
                      <option value="Mezcla 60/40 MB">Mezcla 60/40 "MB"</option>
                      <option value="Mezcla 95/05 MA">Mezcla 95/05 "MA"</option>
                    </select>

                    <label
                      htmlFor={`lote_aceite_${index + 1}`}
                      className="label"
                    >
                      <span className="label-text text-gray-700 dark:text-gray-300">
                        Lote de Aceite
                      </span>
                    </label>
                    <input
                      type="text"
                      value={lote.lote}
                      onChange={(e) =>
                        handleLoteAceiteChange(index, "lote", e.target.value)
                      }
                      name={`lote_aceite_${index + 1}`}
                      className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <label
                      htmlFor={`fecha_aceite_${index + 1}`}
                      className="label"
                    >
                      <span className="label-text text-gray-700 dark:text-gray-300">
                        Fecha de Vencimiento
                      </span>
                    </label>
                    <input
                      type="date"
                      value={lote.fecha_vencimiento}
                      name={`fecha_aceite_${index + 1}`}
                      onChange={(e) =>
                        handleLoteAceiteChange(
                          index,
                          "fecha_vencimiento",
                          e.target.value
                        )
                      }
                      className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />

                    <button
                      type="button"
                      className="btn bg-red-500 text-white mt-2"
                      onClick={() => removeLoteAceite(index)}
                    >
                      Eliminar Lote #{index + 1}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-5 mb-2 font-bold text-sm text-[#00496B] dark:text-gray-300">
            Averias
          </div>
          <div className="border border-gray-300 dark:border-gray-600 rounded-lg p-3">
            {formData.averiasproducto_set.map((prod, index) => {
              // Obtenemos el producto seleccionado
              const productoSeleccionado = productos.find(
                (p) => p.id === prod.producto
              );

              const filteredProductos = productos
                ? productos.filter(
                    (producto) =>
                      !formData.averiasproducto_set
                        .map((prod) => prod.producto)
                        .includes(producto.id) &&
                      producto.inventario_data?.inventario > 0
                  )
                : [];

              return (
                <div key={index} className="mb-3">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                    <div className="form-control">
                      <label htmlFor="producto" className="label">
                        <span className="label-text dark:text-white">
                          Producto
                        </span>
                      </label>
                      <div
                        className="relative"
                        ref={(el) => (productoDropdownRefs.current[index] = el)}
                      >
                        <div className="inline-flex items-center justify-between w-full">
                          <button
                            type="button"
                            onClick={() => handleProductoDropdownToggle(index)}
                            className="input input-bordered w-full inline-flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            <span className="mr-2">
                              {prod.producto
                                ? `${productoSeleccionado?.nombre} - ${productoSeleccionado?.referencia} - ${productoSeleccionado?.descripcion}`
                                : "Seleccionar Producto"}
                            </span>
                            <IconChevronDown className="ml-2" size={20} />
                          </button>
                        </div>
                        {productoDropdownOpen[index] && (
                          <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg">
                            <input
                              id="search-input"
                              className="input input-bordered w-full dark:bg-gray-700 dark:text-white dark:border-gray-600"
                              type="text"
                              placeholder="Buscar productos"
                              value={productoQuery}
                              onChange={(e) => setProductoQuery(e.target.value)}
                            />
                            <div className="max-h-60 overflow-y-auto">
                              {filteredProductos.length === 0 ? (
                                <div className="px-4 py-2 text-gray-700 dark:text-gray-300">
                                  No se encontraron productos
                                </div>
                              ) : (
                                filteredProductos.map((prodItem) => (
                                  <div
                                    key={prodItem.id}
                                    onClick={() =>
                                      handleProductoSelect(index, prodItem)
                                    }
                                    className="cursor-pointer px-4 py-2 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600"
                                  >
                                    {prodItem.nombre} - {prodItem.referencia} -{" "}
                                    {prodItem.descripcion}
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-control">
                      <label htmlFor="cantidad" className="label">
                        <span className="label-text dark:text-white">
                          Cantidad
                        </span>
                      </label>
                      <input
                        type="number"
                        name="cantidad"
                        value={prod.cantidad === 0 ? "" : prod.cantidad}
                        onChange={(e) => {
                          const newProds = [...formData.averiasproducto_set];
                          const cantidadIngresada =
                            e.target.value === ""
                              ? ""
                              : parseInt(e.target.value, 10);
                          const inventarioDisponible =
                            productoSeleccionado?.inventario_data?.inventario ||
                            0;

                          if (e.target.value === "") {
                            newProds[index].cantidad = "";
                          } else if (
                            cantidadIngresada <= inventarioDisponible &&
                            cantidadIngresada >= 0
                          ) {
                            newProds[index].cantidad = cantidadIngresada;
                          } else {
                            Toast({
                              tipo: "error",
                              mensaje: `La cantidad ingresada excede el inventario disponible (${inventarioDisponible}).`,
                            });
                          }

                          // Actualizar el estado del formulario
                          setFormData({
                            ...formData,
                            averiasproducto_set: newProds,
                          });
                        }}
                        max={
                          productoSeleccionado?.inventario_data?.inventario || 0
                        }
                        required
                        className="input input-bordered w-full dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      />

                      {productoSeleccionado?.inventario_data && (
                        <span className="text-sm text-gray-600 dark:text-gray-300">
                          Cantidad en inventario:{" "}
                          {productoSeleccionado.inventario_data.inventario}
                        </span>
                      )}
                    </div>

                    <div className="flex items-center justify-start space-x-2">
                      <div className="flex flex-col items-center">
                        <label htmlFor="eliminar" className="label">
                          <span className="label-text dark:text-white">
                            Eliminar
                          </span>
                        </label>
                        <button
                          type="button"
                          onClick={() => {
                            const newProds = [...formData.averiasproducto_set];
                            newProds.splice(index, 1);
                            setFormData({
                              ...formData,
                              averiasproducto_set: newProds,
                            });
                          }}
                          className="p-2 bg-red-500 text-white rounded-md"
                        >
                          <IconTrash size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <button
              type="button"
              onClick={addProducto}
              className="btn border bg-blue-500 text-white dark:bg-blue-700"
            >
              Añadir Averia
            </button>
          </div>

          <div className="flex justify-center col-span-1 md:col-span-2 lg:grid-cols-3 mt-5">
            {loading ? (
              <div className="btn border bg-[#DC2917] text-white">
                <CircleLoader loading={loading} size={25} color="#ffffff" />
              </div>
            ) : (
              <button
                type="submit"
                className="btn border bg-[#DC2917] text-white"
              >
                Guardar
              </button>
            )}
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  refer: state.referencia.referencias,
  productos: state.productos.productos || [],
});

export default connect(mapStateToProps, {
  get_referencia_list,
  get_productos_list,
})(ProduccionP03Form);
