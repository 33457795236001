import { IconEye, IconPencil, IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function InventarioCardHorizontal({ data, index }) {
  const user = useSelector((state) => state.auth.user);
  return (
    <tr className="border-b border-gray-200 dark:border-gray-600">
      <td className="p-2 dark:text-white"></td>
      <td className="p-2 dark:text-white">
        <div className="flex flex-col text-xs">
          <div className="flex">
            <span className="font-bold">Insumo: </span>
            <span className="flex ml-1 w-full">{data.producto.nombre}</span>
          </div>
          <div className="flex">
            <span className="font-bold">Referencia: </span>
            <span className="flex ml-1 w-full">{data.producto.referencia}</span>
          </div>
          <div className="flex">
            <span className="font-bold">Descripción: </span>
            <span className="flex ml-1 w-full">
              {data.producto.descripcion}
            </span>
          </div>
        </div>
      </td>

      <td className="p-2 dark:text-white text-right">
        {data.entradas ? data.entradas : 0}
      </td>
      <td className="p-2 dark:text-white text-right">
        {data.salidas ? data.salidas : 0}
      </td>
      <td className="p-2 dark:text-white text-right">
        {data.gastos_produccion ? data.gastos_produccion : 0}
      </td>
      <td className="p-2 dark:text-white text-right">
        {data.inventario ? data.inventario : 0}
      </td>
      <td className="p-2 dark:text-white text-right">
        {data.costos_unitario ? `$ ${data.costos_unitario}` : 0}
      </td>
      <td className="p-2 dark:text-white text-right">
        {data.costos_total ? `$ ${data.costos_total}` : 0}
      </td>

      <td className="p-2 dark:text-white">
        <div className="flex items-center gap-2">
          {user &&
            (user.role === "COORDINADOR_INVENTARIOS" ||
              user.role === "COORDINADOR_PLANTA_ENVASADO") && (
              <div className="flex tooltip" data-tip="Editar">
                <Link
                  to={`/inventario-p03/editar/${data.id}`}
                  className="p-1 text-white bg-yellow-500 rounded-full"
                >
                  <IconPencil />
                </Link>
              </div>
            )}

          <div className="flex tooltip" data-tip="Ver Pdf">
            <Link
              to={`/inventario-p03/ver/${data.id}`}
              className="p-1 text-white bg-blue-500 rounded-full"
            >
              <IconEye />
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default InventarioCardHorizontal;
