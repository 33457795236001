import { Link } from "react-router-dom";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { IconFilter } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {get_averias_list,get_averias_list_page} from "../../../redux/actions/averias/averias"
import { connect } from "react-redux";
import AveriasList from "../../../components/averias/AveriasList";
// import AveriasProduccionP03List from "../../../components/P03/averias/AveriasProduccionList";
function AveriasP03({
  averias,
  get_averias_list,
  get_averias_list_page,
  count,
}) {
  useEffect(() => {
    get_averias_list();
  }
  , []);

  const [term, setTerm] = useState("");
  const handleChange = (e) => {
    setTerm(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(
      () => (window.location.href = "/averias-produccion-p03/" + term),
      0.2
    );
    setTerm("");
  };

  return (
    <DashboardLayout>
      <div className="border-b border-gray-200 dark:border-gray-700 py-5 sm:px-6">
        <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-2xl font-medium leading-6 text-gray-900 dark:text-white">
              Lista de averías Planta 03.
            </h3>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
            <div className="flex justify-between gap-4"></div>
            <div className="">
              <div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
                <form onSubmit={(e) => onSubmit(e)} className="w-full">
                  <label htmlFor="search" className="sr-only">
                    Buscar
                  </label>
                  <div className="relative">
                    <button
                      type="submit"
                      className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-400 dark:text-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </button>
                    <input
                      id="search"
                      name="search"
                      required
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className="block w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Buscar"
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="dropdown dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="p-1.5 bg-white dark:bg-gray-800 border border-blue-600 rounded"
              >
                <IconFilter stroke="#0096C4" />
              </div>
              <div
                tabIndex={0}
                className="dropdown-content z-[1] menu p-2 shadow bg-white dark:bg-gray-800 rounded-box w-52"
              >
                <div className="flex flex-col gap-y-2">
                  <label className="form-control w-full max-w-xs">
                    <div className="label">
                      <span className="label-text text-gray-900 dark:text-white">
                        Turno
                      </span>
                    </div>
                    <select
                      // onChange={handleChangeTurno}
                      // value={turno}
                      className="select select-bordered w-full bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white"
                    >
                      <option value="">Elige una opción</option>
                      <option value="A">Diurno</option>
                      <option value="B">Nocturno</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AveriasList
        averias={averias}
        count={count}
        get_averias_list={get_averias_list}
        get_averias_list_page={get_averias_list_page}
      />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    averias: state.averias.averias_list,
    count: state.averias.count,
  };
};

export default connect(mapStateToProps, {
  get_averias_list,
  get_averias_list_page,
})(AveriasP03);
