import {
  GET_ENTRADAS_LIST_SUCCESS,
  GET_ENTRADAS_LIST_FAIL,
  GET_ENTRADAS_SUCCESS,
  GET_ENTRADAS_FAIL,
  GET_ENTRADAS_SEARCH_SUCCESS,
  GET_ENTRADAS_SEARCH_FAIL,
  GET_PRODUCTOS_LIST_SUCCESS,
  GET_PRODUCTOS_LIST_FAIL,
  GET_PRODUCTO_SUCCESS,
  GET_PRODUCTO_FAIL,
  GET_REPORTE_MENSUAL_SUCCESS,
  GET_REPORTE_MENSUAL_FAIL,
  GET_SALIDAS_LIST_SUCCESS,
  GET_SALIDAS_LIST_FAIL,
  GET_SALIDAS_SUCCESS,
  GET_SALIDAS_FAIL,
  GET_SALIDAS_SEARCH_SUCCESS,
  GET_SALIDAS_SEARCH_FAIL,
  GET_REFERENCIA_LIST_SUCCESS,
  GET_REFERENCIA_LIST_FAIL,
  GET_REFERENCIA_SUCCESS,
  GET_REFERENCIA_FAIL,
  GET_REFERENCIA_SEARCH_SUCCESS,
  GET_REFERENCIA_SEARCH_FAIL,
  GET_PRODUCCION_LIST_SUCCESS,
  GET_PRODUCCION_LIST_FAIL,
  GET_PRODUCCION_SUCCESS,
  GET_PRODUCCION_FAIL,
  GET_PRODUCCION_SEARCH_SUCCESS,
  GET_PRODUCCION_SEARCH_FAIL,
  GET_INVENTARIO_LIST_SUCCESS,
  GET_INVENTARIO_LIST_FAIL,
  GET_INVENTARIO_SUCCESS,
  GET_INVENTARIO_FAIL,
  GET_INVENTARIO_SEARCH_SUCCESS,
  GET_INVENTARIO_SEARCH_FAIL,
  GET_INVENTARIO_FULL_LIST_SUCCESS,
  GET_INVENTARIO_FULL_LIST_FAIL,
  GET_INVENTARIO_FULL_SUCCESS,
  GET_INVENTARIO_FULL_FAIL,
  GET_INVENTARIO_FULL_SEARCH_SUCCESS,
  GET_INVENTARIO_FULL_SEARCH_FAIL,
  GET_SALDO_PLANTA_LIST_SUCCESS,
  GET_SALDO_PLANTA_LIST_FAIL,
  GET_SALDO_PLANTA_SUCCESS,
  GET_SALDO_PLANTA_FAIL,
  GET_SALDO_PLANTA_SEARCH_SUCCESS,
  GET_SALDO_PLANTA_SEARCH_FAIL,
  GET_SALIDA_INSUMOS_LIST_SUCCESS,
  GET_SALIDA_INSUMOS_LIST_FAIL,
  GET_SALIDA_INSUMOS_SUCCESS,
  GET_SALIDA_INSUMOS_FAIL,
  GET_SALIDA_INSUMOS_SEARCH_SUCCESS,
  GET_SALIDA_INSUMOS_SEARCH_FAIL,
  GET_SALIDA_INSUMOS_REPORTE_MENSUAL_SUCCESS,
  GET_SALIDA_INSUMOS_REPORTE_MENSUAL_FAIL,
  GET_COMBINACION_MEZCLAS_LIST_SUCCESS,
  GET_COMBINACION_MEZCLAS_LIST_FAIL,
  GET_COMBINACION_MEZCLAS_SUCCESS,
  GET_COMBINACION_MEZCLAS_FAIL,
  GET_COMBINACION_MEZCLAS_SEARCH_SUCCESS,
  GET_COMBINACION_MEZCLAS_SEARCH_FAIL
} from "../actions/P03/types";

const initialState = {
  entrada: null,
  entradas: null,
  entrada_filtrada: null,
  count: null,
  next: null,
  previous: null,
  reporteMensual: null,
};

const initialStateProductos = {
  producto: null,
  productos: null,
};

const initialStateSalidas = {
  salida: null,
  salidas: null,
  salida_filtrada: null,
  count: null,
  next: null,
  previous: null,
};

const initialStateReferencia = {
  referencias: null,
  referencia: null,
  referencia_filtrada: null,
  count: null,
  next: null,
  previous: null,
};

const initialStateProduccion = {
  produccion: null,
  producciones: null,
  produccion_filtrada: null,
  count: null,
  next: null,
  previous: null,
};

const initialStateInventario = {
  inventario: null,
  inventarios: null,
  inventario_filtrado: null,
  count: null,
  next: null,
  previous: null,
};

const initialStateInventarioFull = {
  inventario_full: null,
  inventarios_full: null,
  count: null,
  next: null,
  previous: null,
};

const initialStateSaldoPlanta = {
  saldo_planta: null,
  saldos_planta: null,
  saldo_planta_filtrado: null,
  count: null,
  next: null,
  previous: null,
};

const initialStateSalidaInsumos = {
  salida_insumos: null,
  salida_insumo: null,
  salida_insumos_filtrada: null,
  count: null,
  next: null,
  previous: null,
  reporteMensual: null,
};

const initialStateCombinacionMezclas = {
  combinacion_mezcla: null,
  combinaciones_mezclas: null,
  combinacion_mezclas_filtrada: null,
  count: null,
  next: null,
  previous: null,
};

export default function entradas(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ENTRADAS_LIST_SUCCESS:
      return {
        ...state,
        entradas: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_ENTRADAS_LIST_FAIL:
      return {
        ...state,
        entradas: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_ENTRADAS_SUCCESS:
      return {
        ...state,
        entrada: payload.recepcion_materiales,
      };
    case GET_ENTRADAS_FAIL:
      return {
        ...state,
        entrada: null,
      };
    case GET_ENTRADAS_SEARCH_SUCCESS:
      return {
        ...state,
        entrada_filtrada: payload.results,
        count : payload.count,
        next : payload.next,
        previous : payload.previous,
      };
    case GET_ENTRADAS_SEARCH_FAIL:
      return {
        ...state,
        entrada_filtrada: null,
      };
    case GET_REPORTE_MENSUAL_SUCCESS:
      return {
        ...state,
        reporteMensual: payload.reporte_mensual,
      };
    case GET_REPORTE_MENSUAL_FAIL:
      return {
        ...state,
        reporteMensual: null,
      };
    default:
      return state;
  }
}

export function productos(state = initialStateProductos, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTOS_LIST_SUCCESS:
      return {
        ...state,
        productos: payload.results,
      };
    case GET_PRODUCTOS_LIST_FAIL:
      return {
        ...state,
        productos: null,
      };
    case GET_PRODUCTO_SUCCESS:
      return {
        ...state,
        producto: payload,
      };
    case GET_PRODUCTO_FAIL:
      return {
        ...state,
        producto: null,
      };
    default:
      return state;
  }
}

export function salidas(state = initialStateSalidas, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SALIDAS_LIST_SUCCESS:
      return {
        ...state,
        salidas: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_SALIDAS_LIST_FAIL:
      return {
        ...state,
        salidas: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_SALIDAS_SUCCESS:
      return {
        ...state,
        salida: payload.salida_envasado,
      };
    case GET_SALIDAS_FAIL:
      return {
        ...state,
        salida: null,
      };
    case GET_SALIDAS_SEARCH_SUCCESS:
      return {
        ...state,
        salida_filtrada: payload.results,
        count : payload.count,
        next : payload.next,
        previous : payload.previous,
      };
    case GET_SALIDAS_SEARCH_FAIL:
      return {
        ...state,
        salida_filtrada: null,
      };
    default:
      return state;
  }
}

export function salidaInsumos(state = initialStateSalidaInsumos, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SALIDA_INSUMOS_LIST_SUCCESS:
      return {
        ...state,
        salida_insumos: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_SALIDA_INSUMOS_LIST_FAIL:
      return {
        ...state,
        salida_insumos: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_SALIDA_INSUMOS_SUCCESS:
      return {
        ...state,
        salida_insumo: payload.salida_insumo,
      };
    case GET_SALIDA_INSUMOS_FAIL:
      return {
        ...state,
        salida_insumo: null,
      };
    case GET_SALIDA_INSUMOS_SEARCH_SUCCESS:
      return {
        ...state,
        salida_insumos_filtrada: payload.results,
        count : payload.count,
        next : payload.next,
        previous : payload.previous,
      };
    case GET_SALIDA_INSUMOS_SEARCH_FAIL:
      return {
        ...state,
        salida_insumos_filtrada: null,
      };
    case GET_SALIDA_INSUMOS_REPORTE_MENSUAL_SUCCESS:
      return {
        ...state,
        reporteMensual: payload.reporte_mensual,
      };
    case GET_SALIDA_INSUMOS_REPORTE_MENSUAL_FAIL:
      return {
        ...state,
        reporteMensual: null,
      };

    default:
      return state;
  }
}

export function referencia(state = initialStateReferencia, action) {
  const { type, payload, meta } = action;

  switch (type) {
    case GET_REFERENCIA_LIST_SUCCESS:
      if (meta === "min") {
        return {
          ...state,
          referencias: payload.referencias,
        };
      } else {
        return {
          ...state,
          referencias: payload.results, 
          count: payload.count,
          next: payload.next,
          previous: payload.previous,
        };
      }
    case GET_REFERENCIA_LIST_FAIL:
      return {
        ...state,
        referencias: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_REFERENCIA_SUCCESS:
      return {
        ...state,
        referencia: payload.referencia,
      };
    case GET_REFERENCIA_FAIL:
      return {
        ...state,
        referencia: null,
      };
    case GET_REFERENCIA_SEARCH_SUCCESS:
      return {
        ...state,
        referencia_filtrada: payload.results,
        count : payload.count,
        next : payload.next,
        previous : payload.previous,
      };
    case GET_REFERENCIA_SEARCH_FAIL:
      return {
        ...state,
        referencia_filtrada: null,
        count : null,
        next : null,
        previous : null,
      };
    default:
      return state;
  }
}

export function produccion(state = initialStateProduccion, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCCION_LIST_SUCCESS:
      return {
        ...state,
        producciones: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_PRODUCCION_LIST_FAIL:
      return {
        ...state,
        producciones: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_PRODUCCION_SUCCESS:
      return {
        ...state,
        produccion: payload.produccion,
      };
    case GET_PRODUCCION_FAIL:
      return {
        ...state,
        produccion: null,
      };
    case GET_PRODUCCION_SEARCH_SUCCESS:
      return {
        ...state,
        produccion_filtrada: payload.results,
        count : payload.count,
        next : payload.next,
        previous : payload.previous,
      };
    case GET_PRODUCCION_SEARCH_FAIL:
      return {
        ...state,
        produccion_filtrada: null,
        count : null,
        next : null,
        previous : null,
      };
    default:
      return state;
  }
}

export function inventario(state = initialStateInventario, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_INVENTARIO_LIST_SUCCESS:
      return {
        ...state,
        inventarios: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_INVENTARIO_LIST_FAIL:
      return {
        ...state,
        inventarios: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_INVENTARIO_SUCCESS:
      return {
        ...state,
        inventario: payload.inventario,
      };
    case GET_INVENTARIO_FAIL:
      return {
        ...state,
        inventario: null,
      };
    case GET_INVENTARIO_SEARCH_SUCCESS:
      return {
        ...state,
        inventario_filtrado: payload.results,
        count : payload.count,
        next : payload.next,
        previous : payload.previous,
      };
    case GET_INVENTARIO_SEARCH_FAIL:
      return {
        ...state,
        inventario_filtrado: null,
      };
    default:
      return state;
  }
}

export function inventarioFull(state = initialStateInventarioFull, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_INVENTARIO_FULL_LIST_SUCCESS:
      return {
        ...state,
        inventarios_full: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_INVENTARIO_FULL_LIST_FAIL:
      return {
        ...state,
        inventarios_full: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_INVENTARIO_FULL_SUCCESS:
      return {
        ...state,
        inventario_full: payload.inventario_full,
      };
    case GET_INVENTARIO_FULL_FAIL:
      return {
        ...state,
        inventario_full: null,
      };
    case GET_INVENTARIO_FULL_SEARCH_SUCCESS:
      return {
        ...state,
        inventario_full_filtrado: payload.results,
        count : payload.count,
        next : payload.next,
        previous : payload.previous,
      };
    case GET_INVENTARIO_FULL_SEARCH_FAIL:
      return {
        ...state,
        inventario_full_filtrado: null,
        count : null,
        next : null,
        previous : null,
      };
    default:
      return state;
  }
}

export function saldoPlanta(state = initialStateSaldoPlanta, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SALDO_PLANTA_LIST_SUCCESS:
      return {
        ...state,
        saldos_planta: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_SALDO_PLANTA_LIST_FAIL:
      return {
        ...state,
        saldos_planta: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_SALDO_PLANTA_SUCCESS:
      return {
        ...state,
        saldo_planta: payload.saldo_planta,
      };
    case GET_SALDO_PLANTA_FAIL:
      return {
        ...state,
        saldo_planta: null,
      };
    case GET_SALDO_PLANTA_SEARCH_SUCCESS:
      return {
        ...state,
        saldo_planta_filtrado: payload.saldos_planta_filtrados,
      };
    case GET_SALDO_PLANTA_SEARCH_FAIL:
      return {
        ...state,
        saldo_planta_filtrado: null,
      };
    default:
      return state;
  }
}

export function combinacionMezclas(state = initialStateCombinacionMezclas, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COMBINACION_MEZCLAS_LIST_SUCCESS:
      return {
        ...state,
        combinaciones_mezclas: payload.results,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_COMBINACION_MEZCLAS_LIST_FAIL:
      return {
        ...state,
        combinaciones_mezclas: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_COMBINACION_MEZCLAS_SUCCESS:
      return {
        ...state,
        combinacion_mezcla: payload.combinacion_mezclas,
      };
    case GET_COMBINACION_MEZCLAS_FAIL:
      return {
        ...state,
        combinacion_mezcla: null,
      };
    case GET_COMBINACION_MEZCLAS_SEARCH_SUCCESS:
      return {
        ...state,
        combinacion_mezclas_filtrada: payload.results,
        count : payload.count,
        next : payload.next,
        previous : payload.previous,
      };
    case GET_COMBINACION_MEZCLAS_SEARCH_FAIL:
      return {
        ...state,
        combinacion_mezclas_filtrada: null,
      };
    default:
      return state;
  }
}
