import { IconCoin } from "@tabler/icons-react";
import SalesOverviewChart from "./graficos/Lineas";
import ProduccionAra from "./graficos/Barras";
import axios from "axios";
import { useEffect, useState } from "react";
function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboard/P03`)
      .then((response) => {
        setDashboardData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const mapReferenciaToTitle = (referencia) => {
    return referencia.replace("Mezcla ", "");
  };

  const getAceiteValue = (title) => {
    if (dashboardData && dashboardData.aceite) {
      const item = dashboardData.aceite.find(
        (data) => mapReferenciaToTitle(data.referencia) === title
      );
      return item ? item.cantidad : 0;
    }
    return 0;
  };

  return (
    <div className="w-full px-6 py-6 mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-5 gap-6 px-3">
        {[
          { title: "Palma", icon: <IconCoin size={24} /> },
          { title: "Soya", icon: <IconCoin size={24} /> },
          { title: "95/05 MA", icon: <IconCoin size={24} /> },
          { title: "60/40 MB", icon: <IconCoin size={24} /> },
          { title: "70/30 MC", icon: <IconCoin size={24} /> },
        ].map((item, index) => (
          <div
            key={index}
            className="flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border dark:bg-[#282C34] p-4"
          >
            <div className="flex flex-row items-center">
              <div className="flex-grow">
                <p className="mb-0 font-sans font-semibold leading-normal text-sm dark:text-white">
                  {item.title}
                </p>
                <h5 className="mb-0 font-bold dark:text-white">
                  {getAceiteValue(item.title)}
                </h5>
              </div>
              <div className="inline-flex items-center text-white justify-center w-12 h-12 rounded-lg bg-gradient-to-tl from-[#E62514] to-[#AC1A17]">
                {item.icon}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-wrap mt-6 -mx-3">
        <div className="w-full lg:w-1/2 px-3 mb-6">
          <div className="relative z-20 flex flex-col break-words bg-white shadow-soft-xl rounded-2xl border-0 border-solid bg-clip-border dark:bg-[#282C34]">
            <div className="p-6 pb-0">
              <h6 className="text-dark dark:text-white">Producción Ara</h6>
              <p className="leading-normal text-sm  text-gray-600 dark:text-gray-400">
                Sonelo presentaciones por cajas producidas
              </p>
            </div>
            <div className="flex-auto p-4">
            <div className="py-4 pr-1 bg-gradient-to-tl from-gray-900 to-slate-800 rounded-xl">
                {dashboardData && (
                  <ProduccionAra produccionAraData={dashboardData.produccion_ara} />
                )}
              </div>
            </div>
          </div>
        </div>

        
        <div className="w-full lg:w-1/2 px-3 mb-6">
          <div className="relative z-20 flex flex-col break-words bg-white shadow-soft-xl rounded-2xl border-0 border-solid bg-clip-border dark:bg-[#282C34]">
            <div className="p-6 pb-0">
              <h6 className="text-dark dark:text-white">Con Mas Salida</h6>
              <p className="leading-normal text-sm  text-gray-600 dark:text-gray-400">
                <span className="font-semibold">
                  Productos con mas salida de planta
                </span>
              </p>
            </div>
            <div className="flex-auto p-4">
              <div className="py-4 pr-1 bg-gradient-to-tl from-gray-900 to-slate-800 rounded-xl">
                <SalesOverviewChart />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap my-6 -mx-3">
        <div className="w-full max-w-full px-3 mt-0 mb-6 md:mb-0 md:w-1/2 md:flex-none lg:w-2/3 lg:flex-none">
          <div className="border-black/12.5 shadow-soft-xl relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border dark:bg-[#282C34]">
            <div className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid bg-white p-6 pb-0 dark:bg-[#282C34]">
              <div className="flex flex-wrap mt-0 -mx-3">
                <div className="flex-none w-7/12 max-w-full px-3 mt-0 lg:w-1/2 lg:flex-none">
                  <h6 className="text-dark dark:text-white">
                    Productos con más ingresos
                  </h6>
                  <p className="mb-0 leading-normal text-sm dark:text-white">
                    <span className="ml-1 font-semibold">Planta 03</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-auto p-6 px-0 pb-2">
              <div className="overflow-x-auto">
                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                  <thead className="align-bottom">
                    <tr>
                      <th className="px-6 py-3 font-bold tracking-normal text-left uppercase align-middle bg-transparent border-b letter border-b-solid text-xxs whitespace-nowrap border-b-gray-200 text-slate-400 opacity-70  dark:text-white">
                        Producto
                      </th>
                      <th className="px-6 py-3 pl-2 font-bold tracking-normal text-left uppercase align-middle bg-transparent border-b letter border-b-solid text-xxs whitespace-nowrap border-b-gray-200 text-slate-400 opacity-70 dark:text-white">
                        Descripcion
                      </th>
                      <th className="px-6 py-3 font-bold tracking-normal text-center uppercase align-middle bg-transparent border-b letter border-b-solid text-xxs whitespace-nowrap border-b-gray-200 text-slate-400 opacity-70 dark:text-white">
                        Referencia
                      </th>
                      <th className="px-6 py-3 font-bold tracking-normal text-center uppercase align-middle bg-transparent border-b letter border-b-solid text-xxs whitespace-nowrap border-b-gray-200 text-slate-400 opacity-70 dark:text-white">
                        Cantidad
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData?.top_products?.map((product, index) => (
                      <tr key={index}>
                        <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap">
                          <div className="flex px-2 py-1">
                            <div className="flex flex-col justify-center">
                              <h6 className="mb-0 leading-normal text-sm dark:text-white">
                                {product.nombre}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap">
                          <div className="flex px-2 py-1">
                            <div>
                              <h6 className="mb-0 leading-normal text-sm dark:text-white">
                                {product.descripcion}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="p-2 leading-normal text-center align-middle bg-transparent border-b text-sm dark:text-white whitespace-nowrap">
                          <span className="font-semibold leading-tight text-xs">
                            {product.referencia}
                          </span>
                        </td>
                        <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap">
                          <div className="w-3/4 mx-auto">
                            <div className="flex items-center justify-center">
                              <span className="text-sm dark:text-white font-semibold">
                                {product.entradas}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full max-w-full px-3 md:w-1/2 md:flex-none lg:w-1/3 lg:flex-none">
          <div className="border-black/12.5 shadow-soft-xl relative flex h-full min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border dark:bg-[#282C34]">
            <div className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid bg-white p-6 pb-0 dark:bg-[#282C34]">
              <h6 className="text-slate-700 dark:text-white">
                Salidas de Insumos
              </h6>
            </div>
            <div className="flex-auto p-4">
              <div className="relative before:absolute before:top-0 before:left-4 before:border-r-2 before:border-r-slate-100 before:content-[''] before:lg:-ml-px">
                {dashboardData?.top_salidas?.map((salida, index) => (
                  <div
                    key={index}
                    className={`relative mb-4 mt-0 ${
                      index !== dashboardData.top_salidas.length - 1
                        ? "before:h-full"
                        : "before:h-0"
                    } after:clear-both after:table after:content-['']`}
                  >
                    <span className="w-6.5 h-6.5 text-base absolute left-4 z-10 inline-flex -translate-x-1/2 items-center justify-center rounded-full dark:bg-white bg-red-600 text-center font-semibold">
                      <i className="relative z-10 dark:text-white ni leading-none ni-bell-55 leading-pro bg-gradient-to-tl from-green-600 to-lime-400 bg-clip-text fill-transparent"></i>
                    </span>
                    <div className="ml-11.252 pt-1.4 lg:max-w-120 relative -top-1.5 w-auto">
                      <h6 className="mb-0 font-semibold leading-normal text-sm dark:text-white text-slate-700">
                        {salida.nombre} - {salida.referencia}
                      </h6>
                      <p className="mt-1 mb-0 font-semibold leading-tight text-xs text-slate-400">
                        {salida.descripcion} -{" "}
                        {salida.salidas !== null ? salida.salidas : 0} Unidades
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
