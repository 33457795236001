import {
  GET_PROVEEDORES_LIST_SUCCESS,
  GET_PROVEEDORES_LIST_FAIL,
  GET_PROVEEDORES_SUCCESS,
  GET_PROVEEDORES_FAIL,
  GET_PROVEEDORES_SEARCH_SUCCESS,
  GET_PROVEEDORES_SEARCH_FAIL
} from "./types"
import axios from "axios"

export const get_proveedores_list = (tipo=null) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  let url = `${process.env.REACT_APP_API_URL}/proveedores/`
  let params = new URLSearchParams()
  if (tipo !== null && tipo !== "") {
    params.append("tipo", tipo)
  }
  url += `?${params.toString()}`;
  try {
    const res = await axios.get(
      url,
      config
    );

    dispatch({
      type: GET_PROVEEDORES_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROVEEDORES_LIST_FAIL,
    });
  }
}

export const get_proveedores_list_page = (p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/proveedores/?p=${p}`,
      config
    );

    dispatch({
      type: GET_PROVEEDORES_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROVEEDORES_LIST_FAIL,
    });
  }
}

export const get_proveedores = (id) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/proveedores/${id}/`,
      config
    );
    
    dispatch({
      type: GET_PROVEEDORES_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROVEEDORES_FAIL,
    });
  }
}

export const get_proveedores_search = (search) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/proveedores/search/${search}/`,
      config
    );

    dispatch({
      type: GET_PROVEEDORES_SEARCH_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROVEEDORES_SEARCH_FAIL,
    });
  }
}

export const get_proveedores_search_page = (search, p) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/proveedores/search/${search}/?p=${p}`,
      config
    );

    dispatch({
      type: GET_PROVEEDORES_SEARCH_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROVEEDORES_SEARCH_FAIL,
    });
  }
}

