import { IconEye, IconPencil, IconSend } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RecibidoModal from "./Recibido";
import { useState } from "react";
function MezclasCardHorizontal({ data, index, refreshData, user }) {
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
<>
<tr className="border-b border-gray-200 dark:border-gray-600">
      <td className="p-2 dark:text-white"></td>
      <td className="p-2 dark:text-white">{data.mezcla}</td>
      <td className="p-2 dark:text-white ">{data.porcentaje_palma}%</td>
      <td className="p-2 dark:text-white ">{data.porcentaje_soya}%</td>
      <td className="p-2 dark:text-white ">{data.cantidad}</td>
      <td className="p-2 dark:text-white ">{data.tanque}</td>
      <td className="p-2 dark:text-white ">
        {data.aprobada ? "Sí" : "No"}
      </td>

      <td className="p-2 dark:text-white">
          <div className="flex items-center gap-2">
            {data.aprobada === false && user.role === "COORDINADOR_PLANTA_ENVASADO" ? (
              <>
                <div className="flex tooltip" data-tip="Recibido">
                  <button
                    onClick={handleOpenModal}
                    className="p-1 text-white bg-green-500 rounded-full"
                  >
                    <IconSend />
                  </button>
                </div>
                <div className="flex tooltip" data-tip="Editar">
                <Link
                  to={`/combinaciones-mezclas-p03/editar/${data.id}`}
                  className="p-1 text-white bg-yellow-500 rounded-full"
                >
                  <IconPencil />
                </Link>
                </div>
              </>
            ) : null}
            <div className="flex tooltip" data-tip="Ver Pdf">
              <Link
                to={`/entradas-p03/ver/${data.id}`}
                className="p-1 text-white bg-blue-500 rounded-full"
              >
                <IconEye />
              </Link>
            </div>
          </div>
        </td>
    </tr>
    {showModal && (
      <RecibidoModal
        show={showModal}
        handleClose={handleCloseModal}
        entradaId={data.id}
        refreshData={refreshData}
      />
    )}
</>
  );
}

export default MezclasCardHorizontal;
