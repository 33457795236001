import { useSelector } from "react-redux";
import DashboardLayout from "../../hocs/layout/DashboardLayout";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  get_proveedores_list,
  get_proveedores_list_page,
} from "../../redux/actions/proveedores/proveedores";
import { connect } from "react-redux";
import ProveedoresList from "../../components/proveedores/ProveedoresList";

function Proveedores({
  get_proveedores_list,
  get_proveedores_list_page,
  proveedores,
  count
}) {
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    get_proveedores_list();
  }, []);

  const [term, setTerm] = useState("");
  const handleChange = (e) => {
    setTerm(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => (window.location.href = "/proveedores/" + term), 0.2);
    setTerm("");
  };

  const refreshData = () => {
    get_proveedores_list();
  };
  return (
    <DashboardLayout>
      <div className="border-b border-gray-200 dark:border-gray-700 py-5 sm:px-6">
        <div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className="text-2xl font-medium leading-6 text-gray-900 dark:text-white">
              Lista de Proveedores.
            </h3>
          </div>

          <div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
            {user && user.role === "COORDINADOR_PLANTA_ENVASADO" ? (
              <div className="flex justify-between gap-4">
                <Link to="/proveedor/form">
                  <button className="bg-[#DC2917] text-white px-4 py-1.5 rounded hover:bg-red-800">
                    Agregar Proveedor
                  </button>
                </Link>
              </div>
            ) : null}
            <div className="">
              <div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
                <form onSubmit={(e) => onSubmit(e)} className="w-full">
                  <label htmlFor="search" className="sr-only">
                    Buscar
                  </label>
                  <div className="relative">
                    <button
                      type="submit"
                      className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-400 dark:text-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </button>
                    <input
                      id="search"
                      name="search"
                      required
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className="block w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 dark:placeholder-gray-400 text-gray-900 dark:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Buscar"
                      type="search"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProveedoresList
        proveedores={proveedores}
        get_proveedores_list={get_proveedores_list}
        get_proveedores_list_page={get_proveedores_list_page}
        count={count}
        refreshData={refreshData}
      />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  proveedores: state.proveedores.proveedores,

  count: state.proveedores.count,
});

export default connect(mapStateToProps, {
  get_proveedores_list,
  get_proveedores_list_page,
})(Proveedores);
