
import React, { useEffect } from "react";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase/firebaseConfig";
import axios from "axios";


async function sendTokenToBackend(token) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
      Accept: "application/json",
    },
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/fcm-token/`,
      {
        token: token,
      },
      config
    );
  } catch (error) {

  }
}

async function requestPermission() {
  //requesting permission using Notification API
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BAjSS__fkrNDooOdB8duNojUzAQfBzHkFIU_xcEeCyZJBK5LfgpCCE_gQdjJGutVTfX2JAw07sivCPevym613Zg",
    });
    await sendTokenToBackend(token);

  } else if (permission === "denied") {

  }
}

export default function SaveToken() {
  useEffect(() => {
    requestPermission();
  }, []);



  return <></>;
}
