import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import logo from "../../../../assets/img/logo-removebg-preview.png";

// Registrar fuentes
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

Font.register({
  family: "Times-Bold",
  src: "https://fonts.gstatic.com/s/timesnewroman/v1/NiX7eS-8kFObHLkSXE4QGo6lWzYJpivMTA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 40,
    paddingHorizontal: 40,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    backgroundColor: "#f2f2f2",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
  },
  title: {
    fontSize: 22,
    fontFamily: "Oswald",
    width: "75%",
    textAlign: "center",
  },
  image: {
    width: 40,
    height: 40,
    textAlign: "center",
  },
  detailsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
    borderTopWidth: 0,
    marginBottom: 10,
  },
  detailsCol: {
    width: "25%",
    fontSize: 10,
    fontFamily: "Times-Bold",
    textAlign: "center",
    padding: 10,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
  },
  tableRow: {
    flexDirection: "row",
    width: "100%",
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
    padding: 5,
    backgroundColor: "#f2f2f2",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
    padding: 5, 
  },
  tableCellHeader: {
    fontSize: 10,
    fontFamily: "Times-Bold",
    textAlign: "center",
  },
  tableCell: {
    fontSize: 9,
    textAlign: "center",
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 40,
    right: 40,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  contentContainer: {
    marginBottom: 10,
  },
  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  fieldContainer: {
    flexDirection: "row",
    width: "48%",
  },
  fieldLabel: {
    fontSize: 12,
    fontFamily: "Times-Bold",
  },
  fieldValue: {
    fontSize: 12,
    fontFamily: "Times-Roman",
  },
});

// Función para formatear la fecha
const formatDateTime = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(date);
};

function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  const renderTableHeader = () => (
    <View style={styles.tableRow}>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Nombre</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Referencia</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Descripción</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Cantidad</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Lote</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Fecha Vencimiento</Text>
      </View>
    </View>
  );

  const renderTableRows = (items) => {
    const rowsPerPage = 10; 
    const totalPages = Math.ceil(items.length / rowsPerPage);

    return Array.from({ length: totalPages }).map((_, pageIndex) => {
      const startIdx = pageIndex * rowsPerPage;
      const pageItems = items.slice(startIdx, startIdx + rowsPerPage);
      return (
        <Page style={styles.body} wrap key={`page-${pageIndex + 1}`}>
          <View style={styles.headerRow}>
            <Text style={styles.title}>Reporte de Salida de Insumos</Text>
            <Image style={styles.image} src={logo} />
          </View>
          <View style={styles.detailsRow}>
            <Text style={styles.detailsCol}>Código: </Text>
            <Text style={styles.detailsCol}>Versión: </Text>
            <Text style={styles.detailsCol}>Fecha: </Text>
            <Text style={styles.detailsCol}>
              Página: {pageIndex + 1} de {totalPages}
            </Text>
          </View>
          {pageIndex === 0 && (
            <View style={styles.contentContainer}>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Número de Remisión: </Text>
                  <Text style={styles.fieldValue}>{data.remision}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Fecha: </Text>
                  <Text style={styles.fieldValue}>{data.fecha}</Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Cliente: </Text>
                  <Text style={styles.fieldValue}>
                    {data.cliente || "N/A"}
                  </Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Planta: </Text>
                  <Text style={styles.fieldValue}>{data.planta}</Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={{ width: "100%" }}>
                  <Text style={styles.fieldLabel}>Descripción: </Text>
                  <Text style={styles.fieldValue}>{data.descripcion}</Text>
                </View>
              </View>
            </View>
          )}
          <View style={styles.table}>
            {renderTableHeader()}
            {pageItems.map((producto, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {producto.producto.nombre}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {producto.producto.referencia}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {producto.producto.descripcion}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{producto.cantidad}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{producto.lote}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {producto.fecha_vencimiento}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      );
    });
  };

  return (
    <>
      {data ? (
        <Document>{renderTableRows(data.salidainsumoproducto_set)}</Document>
      ) : (
        "No se pudieron obtener los datos de la salida de insumo"
      )}
    </>
  );
}

export default MyDocument;
