import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import logo from "../../../../assets/img/logo-removebg-preview.png";

// Registrar fuentes
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

Font.register({
  family: "Times-Bold",
  src: "https://fonts.gstatic.com/s/timesnewroman/v1/NiX7eS-8kFObHLkSXE4QGo6lWzYJpivMTA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 20,
    paddingBottom: 40,
    paddingHorizontal: 40,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "space-between",
    padding: 5,
    backgroundColor: "#f2f2f2",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
  },
  title: {
    fontSize: 22,
    fontFamily: "Oswald",
    width: "75%",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 18,
    fontFamily: "Oswald",
    marginBottom: 10,
    textAlign: "left",
  },
  text: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    marginBottom: 5,
  },
  boldText: {
    fontSize: 12,
    fontFamily: "Times-Bold",
  },
  image: {
    width: 40,
    height: 40,
    textAlign: "center",
  },
  detailsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#000000",
    borderTopWidth: 0,
    marginBottom: 10,
  },
  detailsCol: {
    width: "25%",
    fontSize: 10,
    fontFamily: "Times-Bold",
    textAlign: "center",
    padding: 10,
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 40,
    right: 40,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
  },
  tableRow: {
    flexDirection: "row",
    width: "100%",
  },
  tableColHeader: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    padding: 3,
    backgroundColor: "#f2f2f2",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#676767",
    padding: 3,
  },
  tableCellHeader: {
    fontSize: 10,
    fontFamily: "Times-Bold",
    textAlign: "center",
    padding: 4,
  },
  tableCell: {
    fontSize: 9,
    textAlign: "center",
    padding: 3,
  },
  contentContainer: {
    marginBottom: 10,
  },
  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  fieldContainer: {
    flexDirection: "row",
    width: "48%",
  },
  fieldLabel: {
    fontSize: 12,
    fontFamily: "Times-Bold",
  },
  fieldValue: {
    fontSize: 12,
    fontFamily: "Times-Roman",
  },
});

const formatDateTime = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Intl.DateTimeFormat("es-ES", options).format(date);
};

function MyDocument({ data }) {
  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate);

  // Función para renderizar el encabezado de la tabla de referencias
  const renderTableHeader = () => (
    <View style={styles.tableRow}>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Código</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Etiqueta</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Cantidad</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Lote</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>Fecha de Vencimiento</Text>
      </View>
    </View>
  );

  // Función para renderizar las filas de referencias con paginación
  const renderTableRows = (items) => {
    const pages = [];
    const rowsPerPage = 7; // 7 referencias por página después del encabezado
    const totalPages = Math.ceil(items.length / rowsPerPage);

    for (let page = 0; page < totalPages; page++) {
      const startIdx = page * rowsPerPage;
      const endIdx = startIdx + rowsPerPage;
      const pageItems = items.slice(startIdx, endIdx);

      pages.push(
        <Page
          style={styles.body}

          wrap
          key={`page-${page + 1}`}
        >
                    <View style={styles.headerRow}>
            <Text style={styles.title}>Reporte de Salida de Envasado</Text>
            <Image style={styles.image} src={logo} />
          </View>
          <View style={styles.detailsRow}>
            <Text style={styles.detailsCol}>Código: </Text>
            <Text style={styles.detailsCol}>Versión: </Text>
            <Text style={styles.detailsCol}>Fecha: </Text>
            <Text style={styles.detailsCol}>
              Página: 1 de 1
            </Text>
          </View>
          {page === 0 && (
            <View style={styles.contentContainer}>
              <Text style={styles.subtitle}>Detalles del Reporte</Text>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Número de Remisión: </Text>
                  <Text style={styles.fieldValue}>{data.remision}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Fecha: </Text>
                  <Text style={styles.fieldValue}>{data.fecha}</Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Cliente: </Text>
                  <Text style={styles.fieldValue}>{data.cliente}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Conductor: </Text>
                  <Text style={styles.fieldValue}>{data.conductor}</Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Placa: </Text>
                  <Text style={styles.fieldValue}>{data.placa}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Lote: </Text>
                  <Text style={styles.fieldValue}>{data.lote}</Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Tipo de Salida: </Text>
                  <Text style={styles.fieldValue}>{data.tipo_salida}</Text>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.fieldLabel}>Turno: </Text>
                  <Text style={styles.fieldValue}>
                    {data.turno === "A" ? "Día" : "Noche"}
                  </Text>
                </View>
              </View>
              <View style={styles.contentRow}>
                <View style={{ width: "100%" }}>
                  <Text style={styles.fieldLabel}>Observaciones: </Text>
                  <Text style={styles.fieldValue}>{data.observaciones}</Text>
                </View>
              </View>
            </View>
          )}

          {/* Referencias */}
          <Text style={styles.subtitle}>Referencias</Text>
          <View style={styles.table}>
            {renderTableHeader()}
            {pageItems.map((ref, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{ref.referencia.codigo}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{ref.referencia.etiqueta}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{ref.cantidad}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{ref.lote}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {ref.fecha_vencimiento}
                  </Text>
                </View>
              </View>
            ))}
          </View>

          <Text style={styles.footer}>
            Reporte generado el {formattedDate}
          </Text>
          <Text style={styles.pageNumber}>Página {page + 1}</Text>
        </Page>
      );
    }

    return pages;
  };

  return (
    <>
      {data ? (
        <Document title={`Reporte de Salida - Remisión ${data.remision}`}>
          {data.salidareferencia_set &&
            renderTableRows(data.salidareferencia_set)}
        </Document>
      ) : (
        "No se pudieron obtener los datos del cliente"
      )}
    </>
  );
}

export default MyDocument;
